import React, { useState } from 'react';
import { AllInOneMenu, Button, InputText, Tooltip } from '@ohif/ui';
import { useTranslation } from 'react-i18next';
import { IoAddCircleOutline } from 'react-icons/io5';
import { FaEdit } from 'react-icons/fa';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import Swal from 'sweetalert2';

const WindowPresets = ({
  presets,
  windowPresets,
  user,
  fetchWindowPresets,
  deleteWindowPresets,
  setWindowPresets,
  createWindowPresets,
  updateWindowPresets,
}) => {
  const { t } = useTranslation('UserPreferencesModal');
  const [isAddWindowPresets, setIsAddWindowPresets] = useState(false);
  const [description, setDescription] = useState('');
  const [selectOrgan, setSelectOrgan] = useState('');
  const [window, setWindow] = useState('');
  const [level, setLevel] = useState('');
  const [error, setError] = useState('');

  const onAddWindowPresets = () => {
    setIsAddWindowPresets(true);
  };

  // custom data get for edit
  const handlePresetsUpdate = preset => {
    setSelectOrgan(preset?.id);
    setDescription(preset?.organ);
    setWindow(preset?.window);
    setLevel(preset?.level);
    setIsAddWindowPresets(true);
  };

  // custome data delete
  const handlePresetsDelete = async id => {
    Swal.fire({
      text: 'Are you sure to delete this organ!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then(async result => {
      if (result.isConfirmed) {
        await deleteWindowPresets(id)
          .then(async res => {
            Swal.fire({
              title: 'Deleted',
              text: 'Organ has been Delete Successfully',
              icon: 'success',
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
            });
            if (res.status === 200) {
              await fetchWindowPresets()
                .then(data => setWindowPresets(data))
                .catch(error => console.error('Error delete window presets:', error));
            }
          })
          .catch(error => {
            console.error('Error deleting organ:', error);
            Swal.fire({
              title: 'Error!',
              text: 'Failed to delete organ. Please try again later.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          text: 'Organ Delete Cancel.',
          icon: 'info',
          confirmButtonText: 'OK',
        });
      }
    });
  };

  // save presets
  const handalsave = async () => {
    if (!description || !window || !level) {
      setError('Please fill out all fields before saving.');
      return;
    }
    const isData = windowPresets?.find(item => item.id === selectOrgan);

    const data = {
      userId: user?.profile?.sub,
      organ: description,
      window: window,
      level: level,
    };

    let result;
    if (isData) {
      result = await updateWindowPresets(data, isData.id);
    } else {
      result = await createWindowPresets(data, setWindowPresets);
    }

    if (result) {
      await fetchWindowPresets()
        .then(data => setWindowPresets(data))
        .catch(error => console.error('Error fetching window presets:', error));

      setWindowPresets(prev => {
        if (isData) {
          // Update existing preset
          return prev.map(preset => (preset.id === isData.id ? { ...preset, ...data } : preset));
        } else {
          // Add new preset
          return [...prev, result];
        }
      });

      setLevel('');
      setWindow('');
      setDescription('');
    }
    setIsAddWindowPresets(false);
  };

  const handalcancel = () => {
    setIsAddWindowPresets(false);
    setLevel('');
    setWindow('');
    setDescription('');
  };

  return (
    <>
      {presets.map((modalityPresets, modalityIndex) => {
        return (
          <React.Fragment key={modalityIndex}>
            {Object.entries(modalityPresets).map(([modality, presetsArray]) => {
              // Merge and filter duplicates
              const mergedData = [
                ...windowPresets?.filter(item => item.userId === user?.profile?.sub), // Filter windowPresets by userId
                ...presetsArray, // Add presetsArray
              ];

              // Remove duplicates based on `description` or `organ`
              const uniqueData = mergedData.reduce((acc, current) => {
                const isDuplicate = acc.some(
                  item =>
                    (item.description || item.organ) === (current.description || current.organ) // Check by `description` or `organ`
                );
                if (!isDuplicate) {
                  acc.push(current);
                }
                return acc;
              }, []);

              return (
                <React.Fragment key={modality}>
                  <div className="text-aqua-pale mx-2 flex h-6 shrink-0 items-center text-lg">
                    {t('Modality Presets', `${modality} Presets`)}
                  </div>

                  {/* Render uniqueData as a table */}
                  <table className="w-full table-auto text-white">
                    <thead className="dark:bg-primary-dark dark:border-primary-main border-secondary-dark border bg-[#d1d1d1]">
                      <tr className="max-sm:text-base">
                        <th className="dark:border-primary-main border-secondary-dark border p-2 text-black dark:text-white">
                          {t('Organ')}
                        </th>
                        <th className="dark:border-primary-main border-secondary-dark border p-2 text-black dark:text-white">
                          {t('Window / Level')}
                        </th>
                        <th className="dark:border-primary-main border-secondary-dark border p-2 text-black dark:text-white">
                          {t('Edit')}
                        </th>
                        <th className="dark:border-primary-main border-secondary-dark border p-2 text-black dark:text-white">
                          {t('Delete')}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="mt-1">
                      {uniqueData.map((preset, index) => (
                        <tr
                          key={`${modality}-${index}`}
                          className="bg-secondary-light dark:bg-secondary-dark dark:border-primary-main border-secondary-dark hover:border-secondary-dark hover:bg-primary-light cursor-pointer border text-center transition duration-300 dark:hover:bg-[#414141] max-sm:text-sm"
                        >
                          <td className="dark:border-primary-main border-secondary-dark hover:border-secondary-dark border p-2 text-black dark:text-white">
                            {preset.description || preset.organ}
                          </td>
                          <td className="dark:border-primary-main border-secondary-dark hover:border-secondary-dark border p-2 text-black dark:text-white">{`${preset.window} / ${preset.level}`}</td>
                          <td className="dark:border-primary-main border-secondary-dark hover:border-secondary-dark border p-2 text-black dark:text-white">
                            {preset.organ ? (
                              <div
                                className="flex cursor-pointer items-center justify-center text-xl transition-all hover:opacity-10"
                                onClick={() => handlePresetsUpdate(preset)}
                              >
                                <FaEdit className="max-sm:text-lg" />
                              </div>
                            ) : (
                              'Default Organ'
                            )}
                          </td>
                          <td className="dark:border-primary-main border-secondary-dark hover:border-secondary-dark border p-2 text-black dark:text-white">
                            {preset.organ ? (
                              <div
                                className="flex cursor-pointer items-center justify-center text-xl text-[#ff0000] transition-all hover:text-red-400"
                                onClick={() => handlePresetsDelete(preset.id)}
                              >
                                <RiDeleteBin6Fill className="max-sm:text-lg" />
                              </div>
                            ) : (
                              'Default Organ'
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </React.Fragment>
              );
            })}
          </React.Fragment>
        );
      })}
      {!isAddWindowPresets && presets.length > 0 && (
        <Button
          onClick={onAddWindowPresets}
          className="mt-2"
        >
          <span className="flex items-center gap-2">
            <IoAddCircleOutline fontSize={'20px'} />
            Add custom presets
          </span>
        </Button>
      )}
      {isAddWindowPresets && (
        <div>
          <div className="border-t-secondary-dark dark:border-t-primary-main mt-2 flex flex-col border-t">
            <div className="mt-2">Add new organ</div>
            <div className="flex">
              <InputText
                id={'window_Description'}
                label={'Organ'}
                value={description}
                onChange={e => setDescription(e)}
              />
              <InputText
                id={'window'}
                label={'Window'}
                value={window}
                onChange={e => setWindow(e)}
              />
              <InputText
                id={'window_level'}
                label={'Level'}
                value={level}
                onChange={e => setLevel(e)}
              />
            </div>
          </div>
          <div className="text-red-500">{error}</div>
          <div className="mt-2 flex items-center justify-between">
            <Button onClick={handalcancel}>{t('Cancel')}</Button>
            <Button onClick={handalsave}>{t('Save')}</Button>
          </div>
        </div>
      )}
    </>
  );
};

export default WindowPresets;
