import React, { useEffect, useState } from 'react';

import { Select, SettingsLeftPanel, Typography, Input, Label, Header } from '@ohif/ui';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useRadiologyGroup, useReportEditor, useSnackbar, useUser } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import './ReportSetting.css'
import { useNavigate } from 'react-router-dom';
import { useAppConfig } from '@state';
import { ToastContainer, toast } from 'react-toastify';

const Section = ({ title, children }) => (
    <>
        <div className="mb-2 border-b-2 border-b-secondary-dark dark:border-b-primary-main ">
            <Typography
                component={'p'}
                variant="inherit"
                color="primaryLight"
                className="flex pb-2 text-[16px] font-semibold !leading-[1.2] dark:text-white text-black"
            >
                {title}
            </Typography>
        </div>
        <div className="mt-4 overflow-y-auto overflow-x-auto mr-2 telerapp-scrollbar"
            style={{
                maxHeight: 'calc(100vh - 146px)',
                // scrollbarColor: '#173239 transparent',
            }}>{children}</div>
    </>
);

const ReportSetting = ({
    servicesManager,
    extensionManager,
    hotkeysManager,
    data: studies,
}) => {

    const { userAuthenticationService } = servicesManager.services;

    const user = userAuthenticationService.getUser();

    const {
        radiologyGroupList,
        uploadImage
    } = useRadiologyGroup();


    const {
        reportSettingDetails,
        setReportSettingDetails,
        createReportSetting,
        updateReportSetting,
    } = useReportEditor();

    const displayDraftsList = [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
    ]

    const [selectedGroupName, setSelectedGroupName] = useState('');
    const [multipleDrafts, setMultipleDrafts] = useState(displayDraftsList[0].value);
    const [selectedFontStyle, setSelectedFontStyle] = useState('');

    const navigate = useNavigate();
    const [appConfig] = useAppConfig();

    const {
        token,
        userToken,
        setToken,
    } = useUser();

    useEffect(() => {
        const getToken = async () => {
            try {
                const data = {
                    token: user.access_token,
                };
                const response = await userToken(data);
                setToken(response);
            } catch (error) {
                console.log(error);
            }
        };
        getToken();
    }, []);

    const onClickReturnButton = () => {
        const { pathname } = location;
        const dataSourceIdx = pathname.indexOf('/', 1);
        const query = new URLSearchParams(window.location.search);
        const configUrl = query.get('configUrl');

        const dataSourceName = pathname.substring(dataSourceIdx + 1);
        const existingDataSource = extensionManager.getDataSources(dataSourceName);

        const searchQuery = new URLSearchParams();
        if (dataSourceIdx !== -1 && existingDataSource) {
            searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
        }

        if (configUrl) {
            searchQuery.append('configUrl', configUrl);
        }

        navigate({
            pathname: '/',
            search: decodeURIComponent(searchQuery.toString()),
        });
    };


    const currentGroupData = reportSettingDetails.length > 0 && reportSettingDetails?.find((item) => item.group_name === selectedGroupName)

    const initialValues = {
        top: currentGroupData?.top || "",
        bottom: currentGroupData?.bottom || "",
        left: currentGroupData?.left || "",
        right: currentGroupData?.right || "",
        font_size: currentGroupData?.font_size || "",
        space_before_paragraph: currentGroupData?.space_before_paragraph || "",
        space_after_paragraph: currentGroupData?.space_after_paragraph || "",
        line_spacing: currentGroupData?.line_spacing || "",
        disclaimer_details: currentGroupData?.disclaimer_details || "",
        height: currentGroupData?.height || "",
    };

    const [formData, setFormData] = useState({
        include_header: false,
        include_footer: false,
        include_watermark: false,
        multiple_header_and_footer: false,
        patient_details_in_header: false,
        remove_defualt_report: false,
        signature: false,
        disclaimer: false,
        top: "",
        bottom: "",
        left: "",
        right: "",
        font_size: "",
        space_before_paragraph: "",
        space_after_paragraph: "",
        line_spacing: "",
        disclaimer_details: "",
        header_image: '',
        footer_image: '',
        watermark_image: '',
        height: ''
    })


    const [headerImage, setHeaderImage] = useState('');
    const [footerImage, setFooterImage] = useState('');
    const [watermarkImage, setWatermarkImage] = useState('');


    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            disclaimer_details: prevState.disclaimer === false ? null : prevState.disclaimer_details
        }));
    }, [formData.disclaimer]);

    useEffect(() => {
        if (currentGroupData !== undefined) {
            const updatedCheckData = {
                include_header: currentGroupData.include_header || false,
                include_footer: currentGroupData.include_footer || false,
                include_watermark: currentGroupData.include_watermark || false,
                multiple_header_and_footer: currentGroupData.multiple_header_and_footer || false,
                patient_details_in_header: currentGroupData.patient_details_in_header || false,
                remove_defualt_report: currentGroupData.remove_defualt_report || false,
                signature: currentGroupData.signature || false,
                disclaimer: currentGroupData.disclaimer_details ? true : false,
                top: currentGroupData.top || "",
                bottom: currentGroupData.bottom || "",
                left: currentGroupData.left || "",
                right: currentGroupData.right || "",
                font_size: currentGroupData.font_size || "",
                space_before_paragraph: currentGroupData.space_before_paragraph || "",
                space_after_paragraph: currentGroupData.space_before_paragraph || "",
                line_spacing: currentGroupData.line_spacing || "",
                disclaimer_details: currentGroupData.disclaimer_details || "",
                header_image: currentGroupData.header_image || "",
                footer_image: currentGroupData.footer_image || '',
                watermark_image: currentGroupData.watermark_image || '',
                height: currentGroupData?.height || ''
            };

            setFormData(updatedCheckData);
            setSelectedGroupName(currentGroupData.group_name)
            setMultipleDrafts(currentGroupData.multiple_drafts)
            setSelectedFontStyle(currentGroupData.font_style)
        } else {
            const updatedCheckData = {
                include_header: false,
                include_footer: false,
                include_watermark: false,
                multiple_header_and_footer: false,
                patient_details_in_header: false,
                remove_defualt_report: false,
                signature: false,
                disclaimer: false,
                top: "",
                bottom: "",
                left: "",
                right: "",
                font_size: "",
                space_before_paragraph: "",
                space_after_paragraph: "",
                line_spacing: "",
                disclaimer_details: "",
                header_image: '',
                footer_image: '',
                watermark_image: '',
                height: ''
            };

            setFormData(updatedCheckData);
            setSelectedGroupName(selectedGroupName);
            setMultipleDrafts(displayDraftsList[0].value);
            setSelectedFontStyle('');
        }
    }, [currentGroupData]);

    // const onchangeHandler = (e) => {
    //     if (e.target.name === 'include_header' || e.target.name === 'include_footer' || e.target.name === 'include_watermark' || e.target.name === 'multiple_header_and_footer' || e.target.name === 'patient_details_in_header' || e.target.name === 'remove_defualt_report' || e.target.name === 'signature' || e.target.name === 'disclaimer') {
    //         setFormData({
    //             ...formData,
    //             [e.target.name]: e.target.checked
    //         })
    //     } else {
    //         setFormData({
    //             ...formData,
    //             [e.target.name]: e.target.value
    //         })
    //     }
    // }

    const onchangeHandler = e => {
        const { name, checked, value, type } = e.target;
        let newFormData = { ...formData };

        if (type === 'checkbox') {
            newFormData[name] = checked;

            if (name === 'patient_details_in_header') {
                newFormData.remove_defualt_report = checked;
            } else if (name === 'remove_defualt_report') {
                newFormData.patient_details_in_header = checked;
            }
        } else {
            newFormData[name] = value;
        }
        setFormData(newFormData);
    };


    const headerUploadHandler = (e) => {
        setHeaderImage(e.target.files[0]);
    }

    const footerUploadHandler = (e) => {
        setFooterImage(e.target.files[0]);
    }

    const watermarkUploadHandler = (e) => {
        setWatermarkImage(e.target.files[0]);
    }

    const handlerImageUpload = async () => {
        try {
            if (headerImage || footerImage || watermarkImage) {
                let imageUpload;
                let footerUpload;
                let watermarkUpload;
                if (headerImage) {
                    if (currentGroupData) {
                        const oldImage = currentGroupData?.header_image;

                        const removeImageName = oldImage?.split('/').pop();
                        imageUpload = await uploadImage(headerImage, removeImageName);
                    }
                }
                if (footerImage) {
                    if (currentGroupData) {
                        const oldImage = currentGroupData?.footer_image;

                        const removeImageName = oldImage?.split('/').pop();
                        footerUpload = await uploadImage(footerImage, removeImageName);
                    }
                }
                if (watermarkImage) {
                    if (currentGroupData) {
                        const oldImage = currentGroupData?.watermark_image;

                        const removeImageName = oldImage?.split('/').pop();
                        watermarkUpload = await uploadImage(watermarkImage, removeImageName);
                    }
                    watermarkUpload = await uploadImage(watermarkImage);
                }

                setFormData(prevState => ({
                    ...prevState,
                    header_image: imageUpload,
                    footer_image: footerUpload,
                    watermark_image: watermarkUpload,
                }));

                toast.success('Images Uploaded Successfully');
            }
        } catch (error) {
            console.error("Error uploading images:", error);
            toast.error('Failed to upload images');
        }
    }


    const onSubmitHandler = async (value,) => {
        try {
            const resData = {

                group_name: selectedGroupName,
                multiple_drafts: multipleDrafts,
                top: formData.top,
                bottom: formData.bottom,
                left: formData.left,
                right: formData.right,
                font_style: selectedFontStyle,
                font_size: formData.font_size,
                space_before_paragraph: formData.space_before_paragraph,
                space_after_paragraph: formData.space_after_paragraph,
                line_spacing: formData.line_spacing,
                include_header: formData.include_header,
                include_footer: formData.include_footer,
                include_watermark: formData.include_watermark,
                multiple_header_and_footer: formData.multiple_header_and_footer,
                patient_details_in_header: formData.patient_details_in_header,
                remove_defualt_report: formData.remove_defualt_report,
                signature: formData.signature,
                disclaimer_details: formData.disclaimer_details,
                created_by: user?.profile?.preferred_username,
                header_image: formData.header_image,
                footer_image: formData.footer_image,
                watermark_image: formData.watermark_image,
                height: formData.height
            }

            const oldData = reportSettingDetails.find((item) => item.group_name === resData.group_name);

            if (!oldData && resData.group_name) {
                await createReportSetting(resData, setReportSettingDetails);
                toast.success('Successfully Report Setting Created');
                // onCancel();
            } else if (oldData) {
                const updateData = {

                    group_name: selectedGroupName,
                    multiple_drafts: multipleDrafts,
                    top: formData.top,
                    bottom: formData.bottom,
                    left: formData.left,
                    right: formData.right,
                    font_style: selectedFontStyle,
                    font_size: formData.font_size,
                    space_before_paragraph: formData.space_before_paragraph,
                    space_after_paragraph: formData.space_after_paragraph,
                    line_spacing: formData.line_spacing,
                    include_header: formData.include_header,
                    include_footer: formData.include_footer,
                    include_watermark: formData.include_watermark,
                    multiple_header_and_footer: formData.multiple_header_and_footer,
                    patient_details_in_header: formData.patient_details_in_header,
                    remove_defualt_report: formData.remove_defualt_report,
                    signature: formData.signature,
                    disclaimer_details: formData.disclaimer_details,
                    created_by: user?.profile?.preferred_username,
                    header_image: formData.header_image,
                    footer_image: formData.footer_image,
                    watermark_image: formData.watermark_image,
                    height: formData.height
                }
                const result = await updateReportSetting(oldData.id, updateData, setReportSettingDetails)
                if (result?.status === 200) {
                    toast.success('Successfully Report Setting Updated');
                }
                // onCancel();
            } else {
                toast.error('Please Select Radiology Group');
            }

        } catch (error) {
            console.log(error, " report setting data submit error.")
        }
    }

    const displaySetList = [
        { label: 'Default', value: 'Default' },
        ...radiologyGroupList.map(group => ({
            label: group.name,
            value: group.name,
        }))
    ];

    // const displaySetList = radiologyGroupList.map(group => ({
    //     label: group.name,
    //     value: group.name
    // }));

    const fontStyleList = [
        { label: 'Default', value: 'Default' },
        { label: 'Arial', value: 'Arial' },
        { label: 'Courier New', value: 'Courier New' },
        { label: 'Georgia', value: 'Georgia' },
        { label: 'Lucida Sans Unicode', value: 'Lucida Sans Unicode' },
        { label: 'Tahoma', value: 'Tahoma' },
        { label: 'Times New Roman', value: 'Times New Roman' },
        { label: 'Trebuchet MS', value: 'Trebuchet MS' },
        { label: 'Verdana', value: 'Verdana' },
    ]


    return (

        <section className="relative w-full dark:text-white text-black">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <Header
                servicesManager={servicesManager}
                hotkeysManager={hotkeysManager}
                sortedStudies={studies}
                isReturnEnabled={!!appConfig.showStudyList}
                onClickReturnButton={onClickReturnButton}
                WhiteLabeling={appConfig.whiteLabeling}
                loggedInUserDetails={user}
                loggedInUserTokenDetails={token}
                // headerOpen={true}
            />

            {/* <div className='absolute top-16 left-60 p-3 w-10/12'> */}
            <div className='max-sm:px-3'>
                <div className='container relative mx-auto flex flex-col pt-5'>
                    <Section title={'Report Settings'}
                    >
                        <Formik
                            initialValues={initialValues}
                            onSubmit={onSubmitHandler}
                        >
                            {({ isSubmitting, handleChange, values }) => (
                                <Form>
                                    <div className='grid sm:grid-cols-2 grid-cols-1 grid-flow-row gap-4 mb-4'>
                                        <div>
                                            <Label className="my-2 block dark:text-white text-black" text="">Radiology Group Name </Label>
                                            <Select
                                                id="group_name"
                                                placeholder={'Select Radiology Group Name'}
                                                isClearable={false}
                                                onChange={(e) => setSelectedGroupName(e.value)}
                                                options={displaySetList}
                                                value={[selectedGroupName]}
                                                className=""
                                            />
                                        </div>
                                        <div>
                                            <Label className="my-2 block dark:text-white text-black" text="">Allow Multiple Drafts </Label>
                                            <Select
                                                id="multiple_drafts"
                                                isClearable={false}
                                                placeholder={'Select Allow Multiple Drafts'}
                                                onChange={(e) => setMultipleDrafts(e.value)}
                                                options={displayDraftsList}
                                                value={[multipleDrafts]}
                                                className=""
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <p className='m-0 leading-tight dark:text-white text-black flex pb-2 text-[16px] font-semibold !leading-[1.2]'>Page Margins (pixels)</p>
                                        <div className='grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 grid-flow-row gap-4'>
                                            <div >
                                                <Label className="my-2 block dark:text-white text-black" text="">Top</Label>
                                                <Field
                                                    type='number'
                                                    className="mr-5"
                                                    name="top"
                                                    placeholder={'Enter Top Page Margins'}
                                                    onChange={onchangeHandler}
                                                    as={Input}
                                                    value={formData.top}
                                                />
                                            </div>
                                            <div >
                                                <Label className="my-2 block dark:text-white text-black" text="">Bottom</Label>
                                                <Field
                                                    type='number'
                                                    className="mr-5"
                                                    name="bottom"
                                                    placeholder={'Enter Bottom Page Margins'}
                                                    onChange={onchangeHandler}
                                                    as={Input}
                                                    value={formData.bottom}
                                                />
                                            </div>
                                            <div >
                                                <Label className="my-2 block dark:text-white text-black" text="">Left</Label>
                                                <Field
                                                    type='number'
                                                    className="mr-5"
                                                    name="left"
                                                    placeholder={'Enter Left Page Margins'}
                                                    onChange={onchangeHandler}
                                                    as={Input}
                                                    value={formData.left}
                                                />
                                            </div>
                                            <div >
                                                <Label className="my-2 block dark:text-white text-black" text="">Right</Label>
                                                <Field
                                                    type='number'
                                                    className="mr-5"
                                                    name="right"
                                                    placeholder={'Enter Rigth Page Margins'}
                                                    onChange={onchangeHandler}
                                                    as={Input}
                                                    value={formData.right}
                                                />
                                            </div>
                                            <div>
                                                <Label className="my-2 block dark:text-white text-black" text="">Font Style </Label>
                                                <Select
                                                    id="font_style"
                                                    isClearable={false}
                                                    placeholder={'Select Font Style'}
                                                    onChange={(e) => setSelectedFontStyle(e.value)}
                                                    options={fontStyleList}
                                                    value={[selectedFontStyle]}
                                                    className=""
                                                />
                                            </div>
                                            <div >
                                                <Label className="my-2 block dark:text-white text-black" text="">Font Size</Label>
                                                <Field
                                                    type='number'
                                                    className="mr-5"
                                                    name="font_size"
                                                    placeholder={'Enter Font Size'}
                                                    onChange={onchangeHandler}
                                                    as={Input}
                                                    value={formData.font_size}
                                                />
                                            </div>
                                            <div >
                                                <Label className="my-2 block dark:text-white text-black" text="">Space Before Paragraph</Label>
                                                <Field
                                                    type='number'
                                                    className="mr-5"
                                                    name="space_before_paragraph"
                                                    placeholder={'Enter Space Before Paragraph'}
                                                    onChange={onchangeHandler}
                                                    as={Input}
                                                    value={formData.space_before_paragraph}
                                                />
                                            </div>
                                            <div >
                                                <Label className="my-2 block dark:text-white text-black" text="">Space After Paragraph</Label>
                                                <Field
                                                    type='number'
                                                    className="mr-5"
                                                    name="space_after_paragraph"
                                                    placeholder={'Enter Space After Paragraph'}
                                                    onChange={onchangeHandler}
                                                    as={Input}
                                                    value={formData.space_after_paragraph}
                                                />
                                            </div>
                                            <div >
                                                <Label className="my-2 block dark:text-white text-black" text="">Line Spacing</Label>
                                                <Field
                                                    type='number'
                                                    className="mr-5"
                                                    name="line_spacing"
                                                    placeholder={'Enter Line Spacing'}
                                                    onChange={onchangeHandler}
                                                    as={Input}
                                                    value={formData.line_spacing}
                                                />
                                            </div>
                                            <div >
                                                <Label className="my-2 block dark:text-white text-black" text="">Header Footer Height</Label>
                                                <Field
                                                    type='number'
                                                    className="mr-5"
                                                    name="height"
                                                    min={50}
                                                    max={200}
                                                    placeholder={'Enter Header Footer Height'}
                                                    onChange={onchangeHandler}
                                                    as={Input}
                                                    value={formData.height}
                                                />
                                            </div>
                                        </div>
                                        <div className=' grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 grid-flow-row gap-4 mt-4'>
                                            <div className=' flex items-center gap-2'>
                                                <input
                                                    type="checkbox"
                                                    name="include_header"
                                                    id='include_header'
                                                    onChange={onchangeHandler}
                                                    checked={formData.include_header}
                                                    className=' cursor-pointer'
                                                />
                                                <Label htmlFor='include_header' className="my-2 block dark:text-white text-black" text="">Include Header</Label>
                                            </div>
                                            <div className=' flex items-center gap-2'>
                                                <input
                                                    type="checkbox"
                                                    name="include_footer"
                                                    id='include_footer'
                                                    onChange={onchangeHandler}
                                                    checked={formData.include_footer}
                                                    className=' cursor-pointer'
                                                />
                                                <Label htmlFor='include_footer' className="my-2 block dark:text-white text-black" text="">Include Footer</Label>
                                            </div>
                                            <div className=' flex items-center gap-2'>
                                                <input
                                                    type="checkbox"
                                                    name="include_watermark"
                                                    id='include_watermark'
                                                    onChange={onchangeHandler}
                                                    checked={formData.include_watermark}
                                                    className=' cursor-pointer'
                                                />
                                                <Label htmlFor='include_watermark' className="my-2 block dark:text-white text-black" text="">Include Watermark</Label>
                                            </div>
                                            <div className=' flex items-center gap-2'>
                                                <input
                                                    type="checkbox"
                                                    name="multiple_header_and_footer"
                                                    id='multiple_header_and_footer'
                                                    onChange={onchangeHandler}
                                                    checked={formData.multiple_header_and_footer}
                                                    className=' cursor-pointer'
                                                />
                                                <Label htmlFor='multiple_header_and_footer' className="my-2 block dark:text-white text-black" text="">Create multiple report with header and footer</Label>
                                            </div>
                                            <div className=' flex items-center gap-2'>
                                                <input
                                                    type="checkbox"
                                                    name="patient_details_in_header"
                                                    id='patient_details_in_header'
                                                    onChange={onchangeHandler}
                                                    checked={formData.patient_details_in_header}
                                                    className=' cursor-pointer'
                                                />
                                                <Label htmlFor='patient_details_in_header' className="my-2 block dark:text-white text-black" text="">Include patient details in header </Label>
                                            </div>
                                            <div className=' flex items-center gap-2'>
                                                <input
                                                    type="checkbox"
                                                    name="signature"
                                                    id='signature'
                                                    onChange={onchangeHandler}
                                                    checked={formData.signature}
                                                    className=' cursor-pointer'
                                                />
                                                <Label htmlFor='signature' className="my-2 block dark:text-white text-black" text="" >Include Signature in report</Label>
                                            </div>
                                            <div className=' flex items-center gap-2'>
                                                <input
                                                    type="checkbox"
                                                    name="disclaimer"
                                                    id='disclaimer'
                                                    onChange={onchangeHandler}
                                                    checked={formData.disclaimer}
                                                    className=' cursor-pointer'
                                                />
                                                <Label htmlFor='disclaimer' className="my-2 block dark:text-white text-black" text="">Disclaimer</Label>
                                            </div>
                                            <div className=' flex items-center gap-2'>
                                                <input
                                                    type="checkbox"
                                                    name="remove_defualt_report"
                                                    id='remove_defualt_report'
                                                    onChange={onchangeHandler}
                                                    checked={formData.remove_defualt_report}
                                                    className=' cursor-pointer'
                                                />
                                                <Label htmlFor='remove_defualt_report' className="my-2 block dark:text-white text-black" text="">Remove Default Report</Label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=' grid grid-cols-3 grid-flow-row gap-4 mt-4'>
                                        {
                                            formData.include_header || formData.multiple_header_and_footer ? (
                                                <div className=' border-b-secondary-dark dark:border-b-primary-main'>
                                                    <Label className="my-2 block" text="">Upload header</Label>
                                                    <input type="file" onChange={headerUploadHandler} className=' dark:bg-primary-dark bg-primary-light mr-5 shadow transition duration-300 appearance-none border border-secondary-dark dark:border-primary-main focus:border-inputfield-focus focus:outline-none disabled:border-inputfield-disabled rounded w-full py-2 px-3 text-sm placeholder-inputfield-placeholder leading-tight dark:text-white text-black' name='header_image' accept='image/*' />
                                                </div>
                                            ) : ''
                                        }
                                        {
                                            formData.include_footer || formData.multiple_header_and_footer ? (
                                                <div className=' border-inputfield-main'>
                                                    <Label className="my-2 block" text="">Upload footer</Label>
                                                    <input type="file" onChange={footerUploadHandler} className='dark:bg-primary-dark bg-primary-light mr-5 shadow transition duration-300 appearance-none border border-secondary-dark dark:border-primary-main focus:border-inputfield-focus focus:outline-none disabled:border-inputfield-disabled rounded w-full py-2 px-3 text-sm dark:text-white text-black placeholder-inputfield-placeholder leading-tight' name='upload_header' accept='image/*' />
                                                </div>
                                            ) : ''
                                        }
                                        {
                                            formData.include_watermark ? (
                                                <div className=' border-inputfield-main'>
                                                    <Label className="my-2 block" text="">Upload watermark</Label>
                                                    <input type="file" onChange={watermarkUploadHandler} className=' dark:bg-primary-dark bg-primary-light shadow transition duration-300 appearance-none border border-secondary-dark dark:border-primary-main focus:border-inputfield-focus focus:outline-none disabled:border-inputfield-disabled rounded w-full py-2 px-3 text-sm dark:text-white text-black placeholder-inputfield-placeholder leading-tight' name='upload_header' accept='image/*' />
                                                </div>
                                            ) : ''
                                        }
                                        {
                                            formData.disclaimer ? (
                                                <div className=' border-b-secondary-dark dark:border-b-primary-main'>
                                                    <Label className="my-2 block dark:text-white text-black" text="">Disclaimer details </Label>
                                                    <Field
                                                        type='text'
                                                        className="mr-5"
                                                        name="disclaimer_details"
                                                        placeholder={'Enter Disclaimer Details'}
                                                        onChange={onchangeHandler}
                                                        as={Input}
                                                        value={formData.disclaimer_details}
                                                    />
                                                </div>
                                            ) : ''
                                        }
                                    </div>
                                    <div className=' flex justify-between items-center mt-4'>
                                        <button type="button" className=' bg-primary-main box-content inline-flex flex-row items-center justify-center gap-[5px] justify center p-[10px] outline-none rounded' onClick={handlerImageUpload}>Image upload</button>
                                        <button type="submit" className=' bg-primary-main box-content inline-flex flex-row items-center justify-center gap-[5px] justify center p-[10px] outline-none rounded mr-3'>Save</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Section>
                </div>
            </div >

        </section>
    );
};

export default ReportSetting;
