import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { fetchLogs, fetchDateRangeLogs, fetchWindowPresets, createWindowPresets, updateWindowPresets, deleteWindowPresets } from '../requestHandler';
import moment from 'moment';

export const LogViewerContext = createContext(null);

export const LogViewerProvider = ({ children }) => {
  const [logs, setLogs] = useState([]);
  const [selectedDate, setSelectedDate] = useState(''); // State for selected date
  const [selectedDateRange, setSelectedDateRange] = useState({ startDate: '', endDate: '' });
  const [selectedLogFileName, setSelectedLogFileName] = useState(''); // State for selected log file name
  const [windowPresets, setWindowPresets] = useState('');
  const [defaultWindowPresets, setDefaultWindowPresets] = useState('');

  // Effect to fetch logs when selectedDate is provided (but not when date range is provided)
  useEffect(() => {
    const { startDate, endDate } = selectedDateRange;

    // Fetch logs for single date only if date range is not provided
    if (selectedDate && selectedLogFileName && !(startDate && endDate)) {
      fetchLogs(selectedDate, selectedLogFileName)
        .then(data => setLogs(data))
        .catch(error => console.error('Error fetching logs:', error));
    }
  }, [selectedDate, selectedLogFileName, selectedDateRange]);

  useEffect(() => {
    fetchWindowPresets()
      .then(data => setWindowPresets(data))
      .catch(error => console.error('Error fetching window presets:', error));
  }, []);

  // Effect to fetch logs for a date range
  useEffect(() => {
    const { startDate, endDate } = selectedDateRange;
    const start_date = moment(startDate).format('YYYY-MM-DD');
    const end_date = moment(endDate).format('YYYY-MM-DD');
    if (startDate && endDate && selectedLogFileName) {
      fetchDateRangeLogs(start_date, end_date, selectedLogFileName)
        .then(data => setLogs(data))
        .catch(error => console.error('Error fetching logs:', error));
    }
  }, [selectedDateRange, selectedLogFileName]);

  const value = useMemo(
    () => ({
      logs,
      setSelectedDate, // Provide the setter function for date
      setSelectedDateRange,
      setSelectedLogFileName, // Provide the setter function for log file name
      selectedDateRange,
      fetchWindowPresets,
      setWindowPresets,
      createWindowPresets,
      windowPresets,
      updateWindowPresets,
      setDefaultWindowPresets,
      defaultWindowPresets,
      deleteWindowPresets
    }),
    [logs, windowPresets, defaultWindowPresets]
  );

  return <LogViewerContext.Provider value={value}>{children}</LogViewerContext.Provider>;
};

export const useLogViewer = () => useContext(LogViewerContext);
