import React, { createContext, useState, useEffect, useMemo, useContext } from 'react';
import { fetchDocumentUpload, createDocument, updateDocument, deleteDocumentUrl, sendReportData, sendImageToSMS, sendImageToEmail, sendReportDataToWhastapp } from '../requestHandler/documentUploadRequest';

export const DocumentUploadContext = createContext(null);

export const DocumentUploadProvider = ({ children, service, extension }) => {
    const [documentUploadDetails, setDocumentUploadDetails] = useState('');

    useEffect(() => {
        fetchDocumentUpload()
            .then(data => setDocumentUploadDetails(data))
            .catch(error => console.error('Error fetching document upload details:', error))
    }, []);

    const value = useMemo(
        () => ({
            documentUploadDetails,
            createDocument,
            updateDocument,
            deleteDocumentUrl,
            sendReportData,
            sendImageToSMS,
            sendImageToEmail,
            sendReportDataToWhastapp,
            setDocumentUploadDetails
        }),
        [documentUploadDetails]
    );

    return <DocumentUploadContext.Provider value={value}>{children}</DocumentUploadContext.Provider>
};

export const useDocumentEditor = () => useContext(DocumentUploadContext);
