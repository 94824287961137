import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppConfig } from '@state';

import {
  useRadiologyGroup,
  useSnackbar,
  useUser,
} from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import axios from 'axios';
import { Button, SettingsLeftPanel, Typography, Select, Label, Header } from '@ohif/ui';
import { ToastContainer, toast } from 'react-toastify';

const ChangeRadiologistMember = ({ servicesManager, extensionManager, hotkeysManager, data: studies, }) => {
  const { userAuthenticationService } = servicesManager.services;

  const user = userAuthenticationService.getUser();
  const { fetchUsers, updateUser, setUsersList } = useUser();
  const {
    radiologyGroupList,
    deleteRadiologyGroupMember,
    addRadiologyGroupMember,
    fetchSingleRadiologyGroup,
    setSingleRadiologyGroupList,
    groupIds,
  } = useRadiologyGroup();

  const { t } = useTranslation('UserPreferencesModal');
  const [currentGroup, setCurrentGroup] = useState(null);
  const [cGroup, setCGroup] = useState(null);
  const [selectedGroupName, setSelectedGroupName] = useState(null);
  const [radiologistUserList, setRadiologistUserList] = useState([]);
  const { show } = useSnackbar();
  const [appConfig] = useAppConfig();
  const navigate = useNavigate();

  const {
    token,
    userToken,
    setToken,
  } = useUser();

  useEffect(() => {
    const getToken = async () => {
      try {
        const data = {
          token: user.access_token,
        };
        const response = await userToken(data);
        setToken(response);
      } catch (error) {
        console.log(error);
      }
    };
    getToken();
  }, []);

  const onClickReturnButton = () => {
    const { pathname } = location;
    const dataSourceIdx = pathname.indexOf('/', 1);
    const query = new URLSearchParams(window.location.search);
    const configUrl = query.get('configUrl');

    const dataSourceName = pathname.substring(dataSourceIdx + 1);
    const existingDataSource = extensionManager.getDataSources(dataSourceName);

    const searchQuery = new URLSearchParams();
    if (dataSourceIdx !== -1 && existingDataSource) {
      searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
    }

    if (configUrl) {
      searchQuery.append('configUrl', configUrl);
    }

    navigate({
      pathname: '/',
      search: decodeURIComponent(searchQuery.toString()),
    });
  };

  useEffect(() => {
    fetchUsers(user.access_token)
      .then(data => setRadiologistUserList(data))
      .catch(error => console.error('Error fetching users:', error));
  }, [user.access_token]);

  const [selectedUser, setSelectedUser] = useState(null);
  const optionGroup = radiologyGroupList.map(group => ({
    label: group.name,
    value: group.name,
  }));

  const radiologistUser = radiologistUserList.length > 0 && radiologistUserList?.filter(
    user =>
      user.attributes &&
      user.attributes.roleType &&
      user.attributes.roleType.includes('Radiologist')
  );
  const optionRadiologistUser = radiologistUser.length > 0 && radiologistUser?.map(data => ({
    label: data.username,
    value: data.username,
  }));
  const selectedUserData: any = radiologistUser.length > 0 && radiologistUser?.find(data => data.username === selectedUser);
  const selectedUserId = selectedUserData ? selectedUserData.id : null;
  const selectedGroupData = radiologyGroupList.find(group => group.name === selectedGroupName);
  const selectedGroupId = selectedGroupData ? selectedGroupData.id : null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/users/${selectedUserId}/groups`,
          {
            headers: {
              Authorization: `${user.token_type} ${user.access_token}`,
            },
          }
        );
        const data = res.data.map(data => data.name);
        // const dataId = res.data.map(data => data.id);
        setCGroup(data.toString());
        setSelectedGroupName(data.toString());
        setCurrentGroup(res.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (selectedUser) {
      fetchData();
    }
  }, [selectedUser, user.token_type, user.access_token]);

  const handleSubmit = async () => {
    try {
      if (currentGroup && currentGroup.length > 0) {
        let updatedUser = { ...selectedUserData };

        // Replace the radiologyGroup with the new group name
        updatedUser.attributes = {
          ...updatedUser.attributes,
          radiologyGroup: [selectedGroupName]
        };

        const findOldId = currentGroup.map(data => data.id);

        if (selectedGroupName && findOldId[0] !== selectedGroupId) {
          await updateUser(updatedUser?.id, updatedUser, user.access_token)
          await addRadiologyGroupMember(selectedUserId, selectedGroupId, user.access_token);
          await deleteRadiologyGroupMember(selectedUserId, findOldId[0], user.access_token);
        }
        await fetchUsers(user.access_token)
          .then(data => setUsersList(data))
          .catch(error => console.error('Error fetching users:', error));
        await fetchSingleRadiologyGroup(user.access_token, groupIds)
          .then(data => setSingleRadiologyGroupList(data))
          .catch(error => console.error('Error fetching users:', error));
      } else {
        await addRadiologyGroupMember(selectedUserId, selectedGroupId, user.access_token);
        await fetchSingleRadiologyGroup(user.access_token, groupIds)
          .then(data => setSingleRadiologyGroupList(data))
          .catch(error => console.error('Error fetching users:', error));
      }
      // show({
      //   title: 'Success!',
      //   message: 'Successfully Change Radiology Group',
      //   type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
      //   position: 'topRight',
      // });
      toast.success('Successfully Change Radiology Group');
    } catch (error) {
      console.log(error, 'error');
      toast.warning('Please Refresh and Try Again');
    }
  };

  const handleDelete = async () => {
    await deleteRadiologyGroupMember(selectedUserId, selectedGroupId, user.access_token);
    await fetchSingleRadiologyGroup(user.access_token, groupIds)
      .then(data => setSingleRadiologyGroupList(data))
      .catch(error => console.error('Error fetching users:', error));
    // show({
    //   title: 'Success!',
    //   message: 'Successfully Remove Radiology Group',
    //   type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
    //   position: 'topRight',
    // });
    toast.warning('Successfully Remove Radiology Group');
  };

  const handleSelectionGroupNameChange = selected => {
    setSelectedGroupName(selected.value);
  };

  const handleSelectionRadiologistUser = selected => {
    setSelectedUser(selected.value);
  };

  const Section = ({ title, children }) => (
    <>
      <div className="mb-2 border-b-2 border-b-secondary-dark dark:border-b-primary-main">
        <Typography
          variant="inherit"
          color="primaryLight"
          className="flex pb-2 text-[16px] font-semibold !leading-[1.2] dark:text-white text-black"
        >
          {title}
        </Typography>
      </div>
      <div>{children}</div>
    </>
  );

  return (
    <>
      <section className="relative w-full dark:text-white text-black">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Header
          servicesManager={servicesManager}
          hotkeysManager={hotkeysManager}
          sortedStudies={studies}
          isReturnEnabled={!!appConfig.showStudyList}
          onClickReturnButton={onClickReturnButton}
          WhiteLabeling={appConfig.whiteLabeling}
          loggedInUserDetails={user}
          loggedInUserTokenDetails={token}
          // headerOpen={true}
        />
        {/* <div className="absolute top-16 left-[260px]"> */}
        <div className='max-sm:px-3'>
          <div className='container relative mx-auto flex flex-col pt-5'>
            {' '}
            <Section title={t('Change Radiology Group')}>
              <div className="flex items-end justify-center">
                <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-2 w-full">
                  <div className="md:w-72 w-full">
                    <Label className="my-2 block dark:text-white text-black">Radiology User</Label>
                    <Select
                      id="group"
                      className=""
                      placeholder={'Select Radiology User'}
                      onChange={handleSelectionRadiologistUser}
                      options={optionRadiologistUser}
                      value={[selectedUser]}
                    />
                  </div>
                  <div className="md:w-72 w-full">
                    <Label className="my-2 block dark:text-white text-black">Radiology Group Name </Label>
                    <Select
                      id="group"
                      className=""
                      placeholder={'Select Radiology Name'}
                      onChange={handleSelectionGroupNameChange}
                      options={optionGroup}
                      value={[selectedGroupName]}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-end">
                <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
                  <div className="mt-4">
                    <Button onClick={handleSubmit}>Update Radiology Group </Button>
                  </div>
                  <div
                    className="sm:mt-4"
                    style={{ marginRight: '-27px' }}
                  >
                    <Button onClick={handleDelete}>Remove Radiology Group</Button>
                  </div>
                </div>
              </div>
            </Section>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChangeRadiologistMember;
