import React, { useEffect, useState } from 'react';
import Label from '../Label';
import Input from '../Input';
import Button from '../Button';
import Icon from '../Icon';
import './QAUsers.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import InputMultiSelect from '../InputMultiSelect';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import { useSnackbar } from '../../contextProviders';
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  GetState,
  GetCity
} from "react-country-state-city";
import PhoneInput from 'react-phone-input-2';
import { ToastContainer, toast } from 'react-toastify';

const QAUsersCreateModel = ({
  createUser,
  updateUser,
  fetchUsers,
  setUsersList,
  qaUserData,
  user,
  hide,
  countriesList,
}) => {
  const initialValues = {
    firstName: qaUserData?.firstName || '',
    lastName: qaUserData?.lastName || '',
    username: qaUserData?.username || '',
    email: qaUserData?.email || '',
    // mobileNumber: qaUserData?.attributes?.mobileNumber || '',
    password: qaUserData?.password || '',
    confirmPassword: qaUserData?.password || '',
    title: qaUserData?.attributes?.title[0] || '',
    permission: qaUserData?.attributes?.permission[0] || '',
    street: qaUserData?.attributes?.userStreet || '',
    district: qaUserData?.attributes?.userDistrict || '',
    cityName: (qaUserData?.attributes?.userCityid || [0]).map(Number) || '',
    stateName: (qaUserData?.attributes?.userStateid || [0]).map(Number) || '',
    countryName: (qaUserData?.attributes?.userCountryid || [0]).map(Number) || '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    username: Yup.string().required('User Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: !qaUserData ? Yup.string().required('Password is required') : Yup.string(),
    confirmPassword: !qaUserData
      ? Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
      : Yup.string(),
    // mobileNumber: Yup.number().required('Mobile Number is required'),
    title: Yup.string().required('Title is required'),
    // street: Yup.string().required('street is required'),
    // district: Yup.string().required('district is required'),
    // permission: Yup.array().required('Select at least one Radiology Group is required'),
  });

  const options = [
    { label: 'Assign', value: 'Assign' },
    { label: 'Create Radiology Group', value: 'Create Radiology Group' },
    { label: 'Approve Report', value: 'Approve Report' },
    { label: 'Add Radiology Group Admin', value: 'Add Radiology Group Admin' },
    { label: 'Add Radiology Group Technologist', value: 'Add Radiology Group Technologist' },
    { label: 'Add Radiologist', value: 'Add Radiologist' },
    { label: 'Generate Invoice', value: 'Generate Invoice' },
    { label: 'Access Dashboard', value: 'Access Dashboard' },
    { label: 'Delete Study', value: 'Delete Study' },
    { label: 'Upload Study', value: 'Upload Study' },
    { label: 'Export Worklist ', value: 'Export Worklist ' },
    { label: 'Edit Report', value: 'Edit Report' },
    // { label: 'Access Backup and Archive', value: 'Access Backup and Archive' },
    { label: 'Chat Access', value: 'Chat Access' },
  ];

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    const countryid = (qaUserData?.attributes?.userCountryid || [0]).map(Number);
    const stateid = (qaUserData?.attributes?.userStateid || [0]).map(Number);

    GetState(countryid[0]).then((result) => {
      setStateList(result);
    });
    GetCity(countryid[0], stateid[0]).then((result) => {
      setCityList(result);
    });
  }, [])

  const initialSelectedOptions = qaUserData?.attributes?.permission || [];
  const initialSelectedCountry = (qaUserData?.attributes?.userCountryid || [0]).map(Number);
  const initialSelectedState = (qaUserData?.attributes?.userStateid || [0]).map(Number);
  const initialSelectedCities = (qaUserData?.attributes?.userCityid || [0]).map(Number);

  const { show } = useSnackbar();
  const [passwordeye, setpasswordeye] = useState(false);
  const [confirmPassword, setConfirmPasswordeye] = useState(false);
  const [error, setError] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(
    initialSelectedOptions.flatMap(option => option.split(', '))
  );
  const [country, setCountry] = useState(initialSelectedCountry);
  const [state, setstate] = useState(initialSelectedState);
  const [city, setcity] = useState(initialSelectedCities);
  const [phoneNuber, setPhoneNuber] = useState(qaUserData?.attributes?.mobileNumber[0] || '')

  const handleSelectionChange = selected => {
    setSelectedOptions(selected);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (qaUserData) {
        const data = {
          username: values.username,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          attributes: {
            roleType: ['QaUsers'],
            mobileNumber: phoneNuber,
            permission: selectedOptions,
            title: values.title,
            templates: ['Select All'],
            userStreet: values.street === initialValues.street ? values.street : [values.street],
            userDistrict: values.district === initialValues.district ? values.district : [values.district],
            userCityid: city[0]?.id ? [city[0]?.id] : [city[0]],
            userStateid: state[0]?.id ? [state[0]?.id] : [state[0]],
            userCountryid: country[0]?.id ? [country[0]?.id] : [country[0]],
            userCityName: city[0]?.name ? [city[0]?.name] : qaUserData?.attributes?.userCityName,
            userStateName: state[0]?.name ? [state[0]?.name] : qaUserData?.attributes?.userStateName,
            userCountryName: country[0]?.name ? [country[0]?.name] : qaUserData?.attributes?.userCountryName,
          },
          // credentials: [{ type: 'password', temporary: true, value: values.password }],
          // enabled: true,
          // emailVerified: true,
        };

        const response = await updateUser(qaUserData.id, data, user.access_token);
        if (response.status === 204) {
          await fetchUsers(user.access_token)
            .then(data => setUsersList(data))
            .catch(error => console.error('Error fetching users:', error));
          toast.success('QA User Successfully Updated');
          // show({
          //   title: 'Success!',
          //   message: 'Successfully QA Users Update',
          //   type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
          //   position: 'topRight',
          // });
        }
        hide();
      } else {
        const data = {
          username: values.username,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          attributes: {
            roleType: ['QaUsers'],
            mobileNumber: phoneNuber,
            permission: selectedOptions,
            title: [values.title],
            templates: ['Select All'],
            userCityid: city[0]?.id && [city[0]?.id],
            userStateid: state[0]?.id && [state[0]?.id],
            userCountryid: country[0]?.id && [country[0]?.id],
            userCityName: city[0]?.name && [city[0]?.name],
            userStateName: state[0]?.name && [state[0]?.name],
            userCountryName: country[0]?.name && [country[0]?.name],
          },
          credentials: [{ type: 'password', temporary: true, value: values.password }],
          enabled: true,
          emailVerified: true,
          realmRoles: ['qa-user'],
        };
        const response = await createUser(data, user.access_token);
        if (response.status === 201) {
          await fetchUsers(user.access_token)
            .then(data => setUsersList(data))
            .catch(error => console.error('Error fetching users:', error));
          toast.success('QA User Successfully Created');
          // show({
          //   title: 'Success!',
          //   message: 'Successfully QA Users Created',
          //   type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
          //   position: 'topRight',
          // });
          hide();
        } else {
          const errorMessage = await response.json();
          setError(errorMessage.errorMessage);
        }
      }
    } catch (error) {
      console.error(error);
      toast.warning('Please Refresh and Try Again');
    } finally {
      setSubmitting(false);
    }
  };
  const setFieldValue = (e, value, name) => {

    if (name === 'mobileNumber') {
      let dialCode = value?.dialCode || '';
      let phoneNumber = e;
      let moblieNumber

      if (phoneNumber.startsWith(dialCode)) {
        // Remove the dial code from the phone number
        phoneNumber = phoneNumber.substring(dialCode.length);
        moblieNumber = `+${dialCode}${phoneNumber}`
      }

      setPhoneNuber(moblieNumber);
    }
  }

  return (
    <section className="w-full">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-2">
              <div>
                <Label className="my-2 block">First Name</Label>
                <Field
                  type="text"
                  name="firstName"
                  as={Input}
                  placeholder="Please enter your first name"
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block">Last Name</Label>
                <Field
                  type="text"
                  name="lastName"
                  as={Input}
                  placeholder="Please enter your last name"
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block">User Name</Label>
                <Field
                  type="text"
                  name="username"
                  as={Input}
                  placeholder="Please enter your user name"
                  disabled={qaUserData ? true : false}
                />
                <ErrorMessage
                  name="username"
                  component="div"
                  className="text-red-600"
                />
              </div>
              {/* <div>
                <Label className="my-2 block">Mobile Number</Label>
                <Field
                  type="text"
                  name="mobileNumber"
                  as={Input}
                  placeholder="Please enter your mobile number"
                />
                <ErrorMessage
                  name="mobileNumber"
                  component="div"
                  className="text-red-600"
                />
              </div> */}
              <div className=' w-full'>
                <Label className='my-2 block' text=''>Mobile Number</Label>
                <PhoneInput
                  country={"us"}
                  value={qaUserData?.attributes?.mobileNumber[0]}
                  onChange={(e, phone) => setFieldValue(e, phone, "mobileNumber")}
                  inputStyle={{ width: '100%', marginTop: '0.5rem', fontSize: '1.125rem', color: 'white', backgroundColor: 'black', borderColor: `rgb(58 63 153)` }}
                  containerStyle={{ width: '100%' }}
                />
              </div>
              <div>
                <Label className="my-2 block">Email</Label>
                <Field
                  type="text"
                  name="email"
                  as={Input}
                  placeholder="Please enter your email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="passworld_box">
                <Label className="my-2 block">Password</Label>
                <Field
                  type={passwordeye ? 'text' : 'password'}
                  name="password"
                  as={Input}
                  placeholder="Please enter your password"
                  disabled={qaUserData ? true : false}
                />
                <Icon
                  onClick={() => setpasswordeye(!passwordeye)}
                  name={passwordeye ? 'eye-visible' : 'eye-hidden'}
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="passworld_box">
                <Label className="my-2 block">Confirm Password</Label>
                <Field
                  type={confirmPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  as={Input}
                  placeholder="Please enter your confirm password"
                  disabled={qaUserData ? true : false}
                />
                <Icon
                  onClick={() => setConfirmPasswordeye(!confirmPassword)}
                  name={confirmPassword ? 'eye-visible' : 'eye-hidden'}
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block">Title</Label>
                <Field
                  type="text"
                  name="title"
                  as={Input}
                  placeholder="Please enter your title"
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="from__dropdown">
                <Label className="my-2 block">Permission</Label>
                <Field
                  name="permission"
                  as={InputMultiSelect}
                  options={options}
                  value={selectedOptions}
                  onChange={handleSelectionChange}
                />

                <ErrorMessage
                  name="permission"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="from__dropdown">
                <Label className="my-2 block" text=""> Country</Label>
                <CountrySelect
                  onChange={(e) => {
                    setCountry([e]);
                  }}
                  defaultValue={country[0]?.id ? country[0] : countriesList.find((item) => item.id === initialSelectedCountry[0])}
                  placeHolder="Select Country"
                />
                <ErrorMessage
                  name="countryName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="from__dropdown">
                <Label className="my-2 block" text=""> State</Label>
                <StateSelect
                  countryid={country[0]?.id ? country[0].id : country[0]}
                  onChange={(e) => {
                    setstate([e]);
                  }}
                  defaultValue={state[0].id ? state[0] : stateList?.find((item) => item.id === initialSelectedState[0])}
                  placeHolder="Select State"
                />
                <ErrorMessage
                  name="stateName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="from__dropdown">
                <Label className="my-2 block" text=""> City</Label>
                <CitySelect
                  stateid={state[0].id ? state[0].id : state[0]}
                  countryid={country[0]?.id ? country[0].id : country[0]}
                  onChange={(e) => {
                    setcity([e])
                  }}
                  defaultValue={city[0].id ? city[0] : cityList?.find((item) => item.id === initialSelectedCities[0])}
                  placeHolder="Select City"
                />
                <ErrorMessage
                  name="stateName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div>
                <Label className="my-2 block" text={''}>District</Label>
                <Field type="text" name="district" as={Input} placeholder="Please enter your district" />
                <ErrorMessage name="district" component="div" className="text-red-600" />
              </div>
              <div>
                <Label className="my-2 block" text={''}>Street</Label>
                <Field type="text" name="street" as={Input} placeholder="Please enter your street" />
                <ErrorMessage name="street" component="div" className="text-red-600" />
              </div>
            </div>
            <div className="flex">
              <Button
                className="mt-8"
                disabled={isSubmitting}
              >
                {qaUserData ? 'Update QA User' : 'Create QA Users'}
              </Button>
              <Label className="my-2 mt-9 block h-3 pl-3 text-red-600	">{error}</Label>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default QAUsersCreateModel;
