// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @media (max-width:321px){ */
  /* .RadiologyActivePopup{
    min-width: 400px;
    height: 500px;
  } */
  /* } */
  /* @media (max-width:321px){ */
  .radiology-active-popup{
    min-width: 300px;
    height: 500px;
  }
  /* } */
  input[type="number"]::-webkit-input-placeholder{
  color: #000 !important;
}
  input[type="number"]::-moz-placeholder{
  color: #000 !important;
}
  input[type="number"]:-ms-input-placeholder{
  color: #000 !important;
}
  input[type="number"]::-ms-input-placeholder{
  color: #000 !important;
}
  input[type="number"]::placeholder{
  color: #000 !important;
}
  body.dark input[type="number"]::-webkit-input-placeholder{
  color: #fff !important;
}
  body.dark input[type="number"]::-moz-placeholder{
  color: #fff !important;
}
  body.dark input[type="number"]:-ms-input-placeholder{
  color: #fff !important;
}
  body.dark input[type="number"]::-ms-input-placeholder{
  color: #fff !important;
}
  body.dark input[type="number"]::placeholder{
  color: #fff !important;
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/StudyListPagination/RadiologyActivePopup.css"],"names":[],"mappings":"AAAA,8BAA8B;EAC5B;;;KAGG;EACL,MAAM;EACN,8BAA8B;EAC5B;IACE,gBAAgB;IAChB,aAAa;EACf;EACF,MAAM;EAEN;EACE,sBAAsB;AACxB;EAFA;EACE,sBAAsB;AACxB;EAFA;EACE,sBAAsB;AACxB;EAFA;EACE,sBAAsB;AACxB;EAFA;EACE,sBAAsB;AACxB;EACA;EACE,sBAAsB;AACxB;EAFA;EACE,sBAAsB;AACxB;EAFA;EACE,sBAAsB;AACxB;EAFA;EACE,sBAAsB;AACxB;EAFA;EACE,sBAAsB;AACxB","sourcesContent":["/* @media (max-width:321px){ */\n  /* .RadiologyActivePopup{\n    min-width: 400px;\n    height: 500px;\n  } */\n/* } */\n/* @media (max-width:321px){ */\n  .radiology-active-popup{\n    min-width: 300px;\n    height: 500px;\n  }\n/* } */\n\ninput[type=\"number\"]::placeholder{\n  color: #000 !important;\n}\nbody.dark input[type=\"number\"]::placeholder{\n  color: #fff !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
