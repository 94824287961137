import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { FaEdit } from 'react-icons/fa';
import Typography from '../Typography';
import { useModal, useUser } from '../../contextProviders';
import { useAppConfig } from '@state';
import UserCreateModel from './UserCreateModel';
import { ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';
import Header from '../Header';
import Select from '../Select';
import LegacyButtonGroup from '../LegacyButtonGroup';
import LegacyButton from '../LegacyButton';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { BsSearch } from 'react-icons/bs';
import Input from '../Input';
import Tooltip from '../Tooltip';


const Users = ({ servicesManager, hotkeysManager, extensionManager }) => {
  const {
    usersList,
    currentPage,
    setCurrentPage,
    resultsPerPage,
    deleteUser,
    fetchUsers,
    createUser,
    setUsersList,
    updateUser,
    setResultPerPage,
    searchQuery,
    setSearchQuery,
    results,
    fetchPaginatedUsers,
  } = useUser();

  const ranges = [
    { value: '10', label: '10' },
    { value: '20', label: '20' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
  ];

  const [selectedRange, setSelectedRange] = useState({
    value: '10',
    label: '10'
  });

  const onSelectedRange = selectedRange => {
    setSelectedRange(selectedRange);
    setResultPerPage(Number(selectedRange.value))
  };

  const tableHeaders = {
    email: 'Email',
    username: 'Username',
    firstName: 'First Name',
    lastName: 'Last Name',
    createdTimestamp: 'Added'
  };

  const keys = Object.keys(tableHeaders);
  const values = Object.values(tableHeaders);
  const { userAuthenticationService } = servicesManager.services;
  const { show, hide } = useModal();
  const user = userAuthenticationService.getUser();
  const { t } = useTranslation('StudyList');

  const handleDelete = async id => {
    Swal.fire({
      // title: 'Are you sure?',
      text: 'Are you sure to delete this user!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then(async result => {
      if (result.isConfirmed) {
        // If the user confirms deletion, proceed with the deletion
        await deleteUser(id, user.access_token)
          .then(async res => {
            Swal.fire({
              title: 'Deleted',
              text: 'User has been Delete Successfully',
              icon: 'success',
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
            });
            if (res.status === 204) {
              await fetchUsers(user.access_token)
                .then(data => setUsersList(data))
                .catch(error => console.error('Error fetching users:', error));
            }
          })
          .catch(error => {
            console.error('Error deleting user:', error);
            Swal.fire({
              title: 'Error!',
              text: 'Failed to delete user. Please try again later.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          // title: 'Cancelled',
          text: 'User Delete Cancel.',
          icon: 'info',
          confirmButtonText: 'OK',
        });
      }
    });
  };

  const [appConfig] = useAppConfig();
  const navigate = useNavigate();
  const location = useLocation();

  const onClickReturnButton = () => {
    const { pathname } = location;
    const dataSourceIdx = pathname.indexOf('/', 1);
    const query = new URLSearchParams(window.location.search);
    const configUrl = query.get('configUrl');

    const dataSourceName = pathname.substring(dataSourceIdx + 1);
    const existingDataSource = extensionManager.getDataSources(dataSourceName);

    const searchQuery = new URLSearchParams();
    if (dataSourceIdx !== -1 && existingDataSource) {
      searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
    }

    if (configUrl) {
      searchQuery.append('configUrl', configUrl);
    }

    navigate({
      pathname: '/',
      search: decodeURIComponent(searchQuery.toString()),
    });
  };

  const modelShow = userData => {
    show({
      content: UserCreateModel,
      title: `UserCreateModel: ${userData.username ? 'Update Users' : 'Create Users'}`,
      contentProps: {
        createUser,
        user,
        hide,
        fetchUsers,
        setUsersList,
        userData: userData.username ? userData : '',
        updateUser,
        fetchPaginatedUsers,
        currentPage,
        searchQuery,
      },
    });
  };

  const handleNextPage = () => {
    if (results.length.toString() === resultsPerPage.toString()) {
      // setCurrentPage(prevPage => prevPage + resultsPerPage);
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      // setCurrentPage(prevPage => Math.max(prevPage - resultsPerPage, 0));
      setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    }
  };

  const handleFirstPage = () => {
    setCurrentPage(1)
  };

  return (
    <section className="">
      <Header
        isReturnEnabled={!!appConfig.showStudyList}
        onClickReturnButton={onClickReturnButton}
        WhiteLabeling={appConfig.whiteLabeling}
        loggedInUserDetails={user}
        hotkeysManager={hotkeysManager}
        // headerOpen={true}
      />
      <ToastContainer />
      <div className='max-sm:px-3'>
        <div className="container relative mx-auto flex flex-col pt-5">
          <div className="mb-5 flex flex-row justify-between">
            <div className="flex min-w-[1px] shrink flex-row items-center gap-6">
              <Typography
                variant="h6"
                className="dark:text-white text-black max-sm:text-[16px]"
                component={'h6'}
              >
                All Users
              </Typography>
            </div>
            <div className="relative">
              {/* Search icon positioned inside the input */}
              <div className="pointer-events-none absolute top-2 left-3 flex items-center">
                <BsSearch className="h-4 w-4 dark:text-white text-black" />
              </div>

              <Formik
                initialValues={{ searchTerm: '' }}
              >
                {({ isSubmitting, values, setFieldValue }) => (
                  <Form>
                    {/* Input field with padding to accommodate the search icon */}
                    <Field
                      name="searchTerm"
                      type="text"
                      as={Input}
                      placeholder="Search user..."
                      className="pl-10"
                      value={values.searchTerm}
                      onChange={(e) => {
                        setFieldValue('searchTerm', e.target.value);
                        setSearchQuery(e.target.value);
                        setCurrentPage(1);
                      }}
                    />
                  </Form>
                )}
              </Formik>
            </div>
            {/* <div className="flex h-[34px] flex-row items-center">
              <Typography
                variant="h6"
                className="text-primary-light cursor-pointer"
                component={'h6'}
                onClick={modelShow}
              >
                Add User
              </Typography>
            </div> */}
          </div>
        </div>

        <div
          className=" overflow-y-auto container m-auto mt-2 telerapp-scrollbar"
          style={{
            maxHeight: 'calc(100vh - 201px)', // Full height minus header height
            // scrollbarColor: '#173239 transparent',
          }}
        >
          <table className="w-full table-auto text-white">
            <thead className="bg-[#d1d1d1] dark:bg-primary-dark dark:border-primary-main border-secondary-dark border sticky top-0 right-0 left-0">
              <tr className=' max-sm:text-base '>
                {values.map(tableHead => (
                  <th
                    className="p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black"
                    key={'tableHead'}
                  >
                    {tableHead}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className="mt-1">
              {results && results.length > 0 ? (
                results.map(instance => (
                  <tr
                    key={instance.id}
                    className="bg-secondary-light dark:bg-secondary-dark dark:border-primary-main border-secondary-dark hover:border-secondary-dark dark:hover:bg-[#414141] hover:bg-primary-light cursor-pointer border text-center transition duration-300 max-sm:text-sm"
                  >
                    {keys.map(key => (
                      <td
                        className="p-2 dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black"
                        key={key}
                      >
                        {key === 'delete' ? (
                          <div
                            className="flex cursor-pointer items-center justify-center text-xl text-[#ff0000] transition-all hover:text-red-400"
                            onClick={() => handleDelete(instance.id)}
                          >
                            <RiDeleteBin6Fill className=' max-sm:text-lg' />
                          </div>
                        ) : key === 'update' ? (
                          <div
                            className="flex cursor-pointer items-center justify-center text-xl transition-all hover:opacity-10"
                            onClick={() => modelShow(instance)}
                          >
                            <FaEdit className=' max-sm:text-lg' />
                          </div>
                        ) : key === 'createdTimestamp' ? (
                          <span>
                            {moment(instance.createdTimestamp).format('MM/DD/YYYY hh:mm:ss A')}
                          </span>
                        ) : (
                          instance[key]
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={keys.length}
                    className="py-20 text-center dark:text-white text-black"
                  >
                    No users found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="container relative mx-auto dark:bg-primary-dark bg-primary-light pt-5">
          <div className="flex justify-between">
            <div className="flex items-center gap-4">
              <div className="flex items-center">
                <Tooltip
                  content={'Results per page'}
                  position="top"
                  style={{ padding: '8px', fontWeight: 'normal' }}
                >
                  <Select
                    id="rows-per-page"
                    className="border-primary-main relative mr-3 sm:w-24 w-[91px]"
                    options={ranges}
                    value={selectedRange}
                    isMulti={false}
                    isClearable={false}
                    isSearchable={false}
                    closeMenuOnSelect={false}
                    menuPlacement="top"
                    // hideSelectedOptions={true}
                    onChange={onSelectedRange}
                  />
                </Tooltip>
                {/* <Typography className="text-base opacity-60">{t('Results per page')}</Typography> */}
              </div>
            </div>
            <div className="">
              <div className="flex items-center">
                <Typography className="mr-4 text-base opacity-60 max-sm:text-sm dark:text-white text-black">
                  {/* {t('Page')} {Math.floor(currentPage / resultsPerPage) + 1} */}
                  {t('Page')} {currentPage}
                </Typography>
                {/* TODO Revisit design of LegacyButtonGroup later - for now use LegacyButton for its children.*/}
                <LegacyButtonGroup>
                  <LegacyButton
                    size="initial"
                    className="px-4 py-2 text-base max-sm:text-sm dark:text-white text-black"
                    color="translucent"
                    border="primary"
                    variant="outlined"
                    onClick={handleFirstPage}
                  >
                    {`<<`}
                  </LegacyButton>
                  <LegacyButton
                    size="initial"
                    className="py-2 px-2 text-base max-sm:text-sm dark:text-white text-black"
                    color="translucent"
                    border="primary"
                    variant="outlined"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 0}
                  >
                    {t('Previous')}
                  </LegacyButton>
                  <LegacyButton
                    size="initial"
                    className="py-2 px-4 text-base max-sm:text-sm dark:text-white text-black"
                    color="translucent"
                    border="primary"
                    variant="outlined"
                    onClick={handleNextPage}
                    disabled={results.length < resultsPerPage}
                  >
                    {t('Next')}
                  </LegacyButton>
                </LegacyButtonGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Users;
