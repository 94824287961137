import React, { useState } from 'react';
import Button from '../Button';
import { BsPersonFillAdd, BsPersonFillDash } from "react-icons/bs";
import { SendtoML, saveAiResultsToBackend } from '../AiIntegration/aiUtils';

const AddRadiologistModel = ({
  user,
  token,
  hide,
  usersList,
  modality,
  studyInstanceUid,
  handleSelectedUserDataChange,
  handleSelectedUserRemove,
  patientReportsDetails,
  patientId,
  accession,
  patientName,
  studyID,
  uuid,
  description,
  institutionName
}) => {
  const [loading, setLoading] = useState(false);
  const permissions = user?.profile?.permission
  const hasAssignPermission =
    permissions?.includes('Assign') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const radiologyGroupName = user?.profile?.radiologyGroup;
  const TelerappUser = token?.realm_access?.roles.includes('super-admin') || token?.realm_access?.roles.includes('deputy-admin') || user?.profile?.roleType === 'TelerappManager';

  const IsTechnologist = token?.realm_access?.roles.includes('Technologist') || user?.profile?.roleType === 'Technologist'
  const IsRadiologyGroupAdmin = user?.profile?.roleType === 'RadiologyGroupAdmin'
  const tableHeaders = {
    username: 'User Name',
    fullName: 'Full Name',
    assignTime: 'Assign Time',
    assignBy: 'Assign By',
    ...(hasAssignPermission && { action: 'Assign / Unassign' }),
  };

  const keys = Object.keys(tableHeaders);
  const values = Object.values(tableHeaders);

  // const radiologistUserLists =
  //   usersList &&
  //   usersList?.filter(user => {
  //     return TelerappUser ? user?.attributes?.roleType?.includes('Radiologist') || user?.attributes?.roleType?.includes('QaUsers') || user?.attributes?.roleType?.includes('Physician') || user?.attributes?.radiologyGroup?.includes('Default') : IsTechnologist ? user?.attributes?.radiologyGroup?.includes(radiologyGroupName) && user?.attributes?.roleType?.includes('Radiologist')

  //       : user?.attributes?.radiologyGroup?.includes(radiologyGroupName) || !IsTechnologist && user?.attributes?.roleType?.includes('QaUsers')
  //       ;
  //   });

  const radiologistUserLists =
    usersList &&
    usersList.filter(user => {
      const roleType = user.attributes?.roleType;
      const radiologyGroup = user.attributes?.radiologyGroup;

      return TelerappUser
        ? roleType?.includes('Radiologist')
        || roleType?.includes('QaUsers')
        || roleType?.includes('Physician')
        || radiologyGroup?.includes('Default')
        : IsTechnologist
          ? radiologyGroup?.includes(radiologyGroupName)
          && roleType?.includes('Radiologist')
          : IsRadiologyGroupAdmin
            ? radiologyGroup?.includes(radiologyGroupName)
            && (roleType?.includes('Radiologist'))
            : radiologyGroup?.includes(radiologyGroupName)
            || (!IsTechnologist && roleType?.includes('QaUsers'));
    });

  const findPatientReportsDetails = patientReportsDetails?.filter(
    user => user.study_UIDs === studyInstanceUid
  );
  const assignNameConavat = findPatientReportsDetails?.map(item => item.assign?.map(JSON.parse));

  // const handleAssignRemoveButtonClick = async instance => {
  //   // Check if the user is already assigned
  //   const isAssigned = assignNameConavat?.some(item =>
  //     item?.some(e => e.assign_name === instance.username)
  //   );

  //   // If assigned, remove user; otherwise, assign user
  //   if (isAssigned) {
  //     handleSelectedUserRemove({ assignNameConavat, studyInstanceUid, instance, patientId, accession, institutionName, studyID });
  //   } else {
  //     const currentTime = new Date();
  //     handleSelectedUserDataChange({ userName: instance.username, currentTime, studyInstanceUid, modality, patientId, accession, institutionName, studyID });

  //     // Trigger AI processing directly on assignment
  //     setLoading(true);
  //     try {
  //       const aiResult = await SendtoML(modality, description, uuid); // Default model URL
  //       if (aiResult) {
  //         await saveAiResultsToBackend(aiResult, uuid, studyInstanceUid, modality, description);
  //       }
  //     } catch (error) {
  //       console.error('AI processing error:', error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }

  //   hide();
  // };

  const handleAssignRemoveButtonClick = async instance => {
    // Check if the user is already assigned
    const isAssigned = assignNameConavat?.some(item =>
      item?.some(e => e.assign_name === instance.username)
    );

    // If assigned, remove user; otherwise, assign user
    if (isAssigned) {
      handleSelectedUserRemove({ assignNameConavat, studyInstanceUid, instance, patientId, accession, institutionName, studyID });
    } else {
      const currentTime = new Date();
      try {
        // Attempt to execute `handleSelectedUserDataChange`
        await handleSelectedUserDataChange({
          userName: instance.username,
          currentTime,
          studyInstanceUid,
          modality,
          patientId,
          accession,
          institutionName,
          studyID
        });

        // If successful, immediately hide
        hide();

        // Run AI processing in the background
        setLoading(true);
        SendtoML(modality, description, uuid)
          .then(aiResult => {
            if (aiResult) {
              return saveAiResultsToBackend(aiResult, uuid, studyInstanceUid, modality, description);
            }
          })
          .catch(error => {
            console.error('AI processing error:', error);
          })
          .finally(() => {
            setLoading(false);
          });

      } catch (error) {
        console.error('Error in handleSelectedUserDataChange:', error);
      }
    }

    hide();
  };

  const timeHandler = instance => {
    const findAssignName = assignNameConavat?.find(item =>
      item?.some(e => e.assign_name === instance.username)
    );

    return findAssignName?.map(
      e =>
        e.assign_name === instance?.username &&
        new Date(e.assign_time).toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
        })
    );
  };
  const assignBy = instance => {
    const findAssignName = assignNameConavat?.find(item =>
      item?.some(e => e.assign_name === instance.username)
    );
    const foundElement = findAssignName?.find(e => e.assign_name === instance.username);

    return foundElement?.assign_by;
  };

  return (
    <section className="w-full">
      {patientName && (
        <p>Patient: {patientName.replace(/,/g, '')}</p>
      )}
      {loading && <p>Processing AI results...</p>}
      {usersList?.length <= 0 ? (
        <span className="flex justify-center">No Members Exiting</span>
      ) : (
        <table className="container m-auto mt-2 w-full table-auto text-white">
          <thead className="bg-[#d1d1d1] dark:bg-primary-dark dark:border-primary-main border-secondary-dark border sticky top-[-10px] z-2">
            <tr className=' max-sm:text-base '>
              {values.map((tableHead, index) => (
                <th
                  className="p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black"
                  key={index}
                >
                  {tableHead}
                </th>
              ))}
            </tr>
          </thead>
          {radiologistUserLists && radiologistUserLists.length <= 0 ? (
            <span className="absolute  flex h-96 w-5/12 items-center justify-end dark:text-white text-black">
              No Radiologist Users Exiting
            </span>
          ) : (
            <tbody className="mt-1">
              {radiologistUserLists &&
                radiologistUserLists?.map((instance, index) => (
                  <tr
                    key={index}
                    className="bg-secondary-light dark:bg-secondary-dark dark:border-primary-main border-secondary-dark hover:border-secondary-dark dark:hover:bg-[#414141] hover:bg-primary-light cursor-pointer border text-center transition duration-300 max-sm:text-sm"
                  >
                    {keys.map((key, i) => (
                      <td
                        className="p-2 text-center dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black"
                        key={i}
                      >
                        {key === 'fullName' ? (
                          `${instance.firstName} ${instance.lastName}`
                        ) : key === 'assignTime' ? (
                          <span>{timeHandler(instance)}</span>
                        ) : key === 'assignBy' ? (
                          <span>{assignBy(instance)}</span>
                        ) : hasAssignPermission && key === 'action' ? (
                          <div
                            className="cursor-pointer flex justify-center items-center "
                            onClick={() => handleAssignRemoveButtonClick(instance)}
                          >
                            {assignNameConavat?.find(item =>
                              item?.some(e => e.assign_name === instance.username)
                            )
                              ? <BsPersonFillDash className=' text-2xl text-primary-dark dark:text-primary-light hover:opacity-70 transition-all' />
                              : <BsPersonFillAdd className=' text-2xl hover:hover:opacity-10  transition-all' />}
                          </div>
                        ) : (
                          instance[key]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          )}
        </table>
      )}
    </section>
  );
};

export default AddRadiologistModel;
