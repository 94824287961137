import axios from 'axios';

let storedPatientData = null; // Initialize a global variable to store patient data

/**
 * Store the patient data in the global variable for future use.
 * @param {Object} patientData - The patient data to store.
 * @returns {Promise} - A promise that resolves when the patient data is stored.
 */
export const storePatientData = (patientData) => {
    return new Promise((resolve, reject) => {
        if (!patientData) {
            console.log('No patient data provided to store.');
            reject(new Error('No patient data provided to store.'));
        } else {
            storedPatientData = patientData; // Store data in the global variable
            // console.log('Patient data stored:', storedPatientData);
            resolve(storedPatientData); // Resolve when data is stored
        }
    });
};

/**
 * Function to get the stored patient data.
 * @returns {Object | null} - The stored patient data, or null if no data is available.
 */
export const getPatientData = () => {
    if (!storedPatientData) {
        console.warn('No patient data is currently stored.');
        return null;
    }
    return storedPatientData;
};

/**
 * Fetch data dynamically using patient data and specific model URLs.
 * @param {string} uuid_value - UUID value to use in the API endpoint.
 * @returns {Promise<Object>} - A promise that resolves to the fetched data and the selected model.
 */
export const fetchData = async (uuid_value) => {
    if (!storedPatientData) {
        throw new Error('Patient data is not available. Ensure storePatientData is called first.');
    }

    const { patient_modality: modalities, study: description } = storedPatientData;

    // Define feedback-specific backend URLs
    const databaseUrls = {
        modelCXR: `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/chestm1`,
        modelBreastCancer: `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/breastcancerm1`,
        modelSpineAI: `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/spinem1`,
        modelBrainStroke: `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/brainstrokem1`,
        modelBoneFracture: `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/bonefracturem1`,
    };

    // storedPatientData= null;

    // Determine the correct model URL
    const { url: modelUrl, modelName } = determineModelUrl(modalities, description, databaseUrls);

    if (!modelUrl) {
        throw new Error('No matching URL found for the given patient data.');
    }

    try {
        const response = await axios.get(`${modelUrl}/${uuid_value}`);
        return {
            data: response.data,
            model: modelName, // Include the selected model
        };
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

/**
 * Determine the model URL based on modalities and description.
 * @param {string} modalities - The patient's modalities.
 * @param {string} description - The study description.
 * @param {Object} modelUrls - The model URLs mapping.
 * @returns {Object} - An object containing the determined model URL and model name.
 */
const determineModelUrl = (modalities, description, modelUrls) => {
    const modelKeywords = {
        modelCXR: ['CXR', 'CR', 'DX', 'X-ray', 'x-ray', 'Xray', 'xray', 'Chest'],
        modelBreastCancer: ['MG', 'CR', 'DX', 'Mammogram', 'Mammo', 'Tomo', 'breast'],
        modelSpineAI: ['MR', 'cervical', 'thoracic', 'lumbar', 'Spine'],
        modelBrainStroke: ['CT', 'brain', 'stroke', 'ischemic', 'embolism', 'thrombosis', 'arterial dissection', 'cerebral hypoxia'],
        modelBoneFracture: [
            'CR', 'DX', 'hand', 'shoulder', 'elbow', 'knee', 'wrist', 'finger', 'fracture', 'bone', 'toe',
            'leg', 'arm', 'tibia', 'fibula', 'femur', 'ankle', 'rib', 'msk', 'musculoskeletal', 'radius',
            'ulna', 'thumb', 'elbow', 'humerus',
        ],
    };

    let selectedModel = null;
    let maxMatches = 0;

    Object.keys(modelKeywords).forEach((modelKey) => {
        const keywords = modelKeywords[modelKey];
        let matchCount = 0;

        // Count keyword matches in modalities and description
        keywords.forEach((keyword) => {
            if (
                (modalities || '').toLowerCase().includes(keyword.toLowerCase()) ||
                (description || '').toLowerCase().includes(keyword.toLowerCase())
            ) {
                matchCount++;
            }
        });

        // Select the model only if it has the most matches
        if (matchCount >= 2 && matchCount > maxMatches) {
            maxMatches = matchCount;
            selectedModel = {
                modelName: modelKey,
                url: modelUrls[modelKey],
            };
        }
    });
    // console.log(selectedModel)

    return selectedModel || { modelName: 'default', url: process.env.REACT_APP_DEFAULT_MODEL_URL }; // Default URL if no match
};


/**
 * Update feedback dynamically using patient data and specific feedback URLs.
 * @param {string} uuid_value - UUID value to use in the API endpoint.
 * @param {string} feedback - The feedback text (optional).
 * @param {Array} feedbackAnnotations - The feedback annotations array (optional).
 * @returns {Promise} - A promise that resolves to the updated record.
 */
export const updateFeedback = async (uuid_value, feedback, feedbackAnnotations) => {
    if (!storedPatientData) {
        throw new Error('Patient data is not available. Ensure storePatientData is called first.');
    }

    const { patient_modality: modalities, study: description } = storedPatientData;

    // Define feedback-specific backend URLs
    const feedbackBackendUrls = {
        modelCXR: `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/chestm1`,
        modelBreastCancer: `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/breastcancerm1`,
        modelSpineAI: `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/spinem1`,
        modelBrainStroke: `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/brainstrokem1`,
        modelBoneFracture: `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/bonefracturem1`,
    };

    // Determine the correct backend URL
    const { url: backendUrl } = determineModelUrl(modalities, description, feedbackBackendUrls);

    if (!backendUrl) {
        throw new Error('No matching backend URL found for the given patient data.');
    }

    // Prepare the payload
    const payload = {
        ...(feedback && { feedback }),
        ...(feedbackAnnotations && { feedback_annotations: feedbackAnnotations }),
    };

    try {
        console.log('Using backend URL for feedback update:', backendUrl);
        const response = await axios.put(`${backendUrl}/${uuid_value}`, payload);
        // console.log('Feedback updated successfully:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error updating feedback:', error);
        throw error;
    }
};
