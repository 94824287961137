import React, { useEffect, useState } from 'react';
import { Header, useUser } from '@ohif/ui';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useAppConfig } from '@state';
import { ToastContainer } from 'react-toastify';
import Typography from '../Typography';

const Radiologist = ({ servicesManager, hotkeysManager, extensionManager }) => {
  const tableHeadersBase = {
    firstName: 'First Name',
    lastName: 'Last Name',
    username: 'Username',
    email: 'Email',
    mobileNumber: 'Mobile Number',
    radiologyGroup: 'Radiology Group',
    roleType: 'Role Type',
  };

  const keys = Object.keys(tableHeadersBase);
  const values = Object.values(tableHeadersBase);
  const { userAuthenticationService } = servicesManager.services;
  const user = userAuthenticationService.getUser();
  const { usersList, token } = useUser();

  const superPermission =
    token?.realm_access?.roles?.includes('super-admin') ||
    user?.profile?.roleType.includes('DeputyAdmin') ||
    user?.profile?.roleType.includes('TelerappManager') ||
    user?.profile?.roleType.includes('QaUsers') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const loginUserGroup = user?.profile?.radiologyGroup;

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const transformedUsers = usersList
      .filter(user => {
        const radiologyGroup = user?.attributes?.radiologyGroup;

        // If the user has superPermission, include all users
        if (superPermission) {
          return true;
        }

        // Otherwise, include users based on radiologyGroup matching
        return radiologyGroup?.includes(loginUserGroup);
      })
      // .map(user => ({
      //   id: user?.id,
      //   name: user?.username,
      // }));

    setUsers(transformedUsers);
  }, [usersList, superPermission, loginUserGroup]);

  const [appConfig] = useAppConfig();
  const navigate = useNavigate();
  const location = useLocation();

  const onClickReturnButton = () => {
    const { pathname } = location;
    const dataSourceIdx = pathname.indexOf('/', 1);
    const query = new URLSearchParams(window.location.search);
    const configUrl = query.get('configUrl');

    const dataSourceName = pathname.substring(dataSourceIdx + 1);
    const existingDataSource = extensionManager.getDataSources(dataSourceName);

    const searchQuery = new URLSearchParams();
    if (dataSourceIdx !== -1 && existingDataSource) {
      searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
    }

    if (configUrl) {
      searchQuery.append('configUrl', configUrl);
    }

    navigate({
      pathname: '/',
      search: decodeURIComponent(searchQuery.toString()),
    });
  };
  return (
    <section className="">
      <Header
        isReturnEnabled={!!appConfig.showStudyList}
        onClickReturnButton={onClickReturnButton}
        WhiteLabeling={appConfig.whiteLabeling}
        loggedInUserDetails={user}
        hotkeysManager={hotkeysManager}
        // headerOpen={true}
      />
      <ToastContainer />
      <div className="max-sm:px-3">
        <div className="container relative mx-auto flex flex-col pt-5">
          <div className="mb-5 flex flex-row justify-between">
            <div className="flex min-w-[1px] shrink flex-row items-center gap-6">
              <Typography
                variant="h6"
                className="text-black dark:text-white max-sm:text-[16px]"
                component={'h6'}
              >
                Radiologist
              </Typography>
            </div>
          </div>
        </div>
        <div
          className="telerapp-scrollbar container m-auto mt-2 overflow-y-auto"
          style={{
            maxHeight: 'calc(100vh - 201px)', // Full height minus header height
            // scrollbarColor: '#173239 transparent',
          }}
        >
          <table className="w-full table-auto text-white">
            <thead className="dark:bg-primary-dark dark:border-primary-main border-secondary-dark sticky top-0 right-0 left-0 border bg-[#d1d1d1]">
              <tr className="max-sm:text-base">
                {values.map(tableHead => (
                  <th
                    className="dark:border-primary-main border-secondary-dark border p-2 text-black dark:text-white"
                    key={'tableHead'}
                  >
                    {tableHead}
                  </th>
                ))}
              </tr>
            </thead>
            {users && users.length <= 0 ? (
              <span className="absolute flex h-96 w-5/12 items-center justify-end text-black dark:text-white">
                No Radiologist Exiting
              </span>
            ) : (
              <tbody className="mt-1">
                {users &&
                  users?.map(instance => (
                    <tr className="bg-secondary-light dark:bg-secondary-dark dark:border-primary-main border-secondary-dark hover:border-secondary-dark hover:bg-primary-light cursor-pointer border text-center transition duration-300 dark:hover:bg-[#414141] max-sm:text-sm">
                      {keys.map(key => (
                        <td
                        className="dark:border-primary-main border-secondary-dark hover:border-secondary-dark border p-2 text-black dark:text-white"
                        key={key}
                        >
                          {console.log(instance,'instance')}
                          {key === 'radiologyGroup' ? (
                            <span className="cursor-pointer">
                              {instance?.attributes?.radiologyGroup}
                            </span>
                          ) : key === 'mobileNumber' ? (
                            <span
                              className="cursor-pointer"
                            >
                              {instance?.attributes?.mobileNumber}
                            </span>
                          ) : key === 'roleType' ? (
                            <span
                              className="cursor-pointer"
                            >
                              {instance?.attributes?.roleType}
                            </span>
                          ) : (
                            instance[key]
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </section>
  );
};

export default Radiologist;
