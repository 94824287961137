export const createUser = async (data, accessTokens) => {
  const response = await fetch(`${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/users`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessTokens}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  return response
}

export const fetchUsers = async (accessTokens) => {
  return await fetch(`${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/users`, {
    headers: {
      Authorization: `Bearer ${accessTokens}`
    }
  })
    .then((response) => response.json())
}

export const fetchGroupWiseUsers = async (accessTokens, page, max, groupId) => {
  return await fetch(`${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/groups/${groupId}/members?first=${(page - 1) * max}&max=${max}`, {
    headers: {
      Authorization: `Bearer ${accessTokens}`
    }
  })
    .then((response) => response.json())
}

export const fetchUsersPagination = async (accessTokens, page, max, search) => {
  // return await fetch(`${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/users?first=${page}&max=${max}&`, {
  return await fetch(`${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/users?first=${(page - 1) * max}&max=${max}&search=${search}`, {
    headers: {
      Authorization: `Bearer ${accessTokens}`
    }
  })
    .then((response) => response.json())
}

export const updateUser = (id, data, accessTokens) => {
  const response = fetch(`${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/users/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessTokens}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  return response
}

export const deleteUser = async (id, accessTokens) => {
  const response = fetch(`${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/users/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessTokens}`
    }
  })
  return response
}

export const updateUserPassword = (userId, accessToken) => {
  const response = fetch(
    `${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/users/${userId}/reset-password`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ temporary: true, value: 'temporaryPassword' })
    }
  )
    .then((response) => response.json());

  return response
}

export const userToken = async (accessToken) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/user-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(accessToken)
  })
  const data = await response.json()

  return data
}


export const createWorklistAttributes = async (data) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/worklist-setting`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })
  return response
}

export const fetchWorklistAttributes = () => {
  return fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/worklist-setting`)
    .then((response) => response.json())
}

export const updateWorklistAttributes = async (id, data) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/worklist-setting/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })

  return response

}

// export const fetchStudyList = () => {
//   return fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/studies`)
//     .then((response) => response.json())
// }

// export const deleteStudies = async (id, setAllStudyList) => {
//   const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/studiesDelete/${id}`, {
//     method: 'DELETE',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   })
//   fetchStudyList()
//       .then(data => setAllStudyList(data))
//       .catch(error => console.error('Error fetching study list:', error));
//   const data = await response.json()

//   return data
// }


export const deleteStudies = async (reportIds, setAllStudyList) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/studiesDelete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(reportIds),
  })
  // fetchStudyList()
  //   .then(data => setAllStudyList(data))
  //   .catch(error => console.error('Error fetching study list:', error));
  const data = await response.json()

  return data
}


// anonymizeStudies



export const anonymizeStudies = async (reportIds, setAllStudyList) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/studiesAnonymize`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(reportIds),
  })
  // fetchStudyList()
  //   .then(data => setAllStudyList(data))
  //   .catch(error => console.error('Error fetching study list:', error));
  const data = await response.json()

  return data
}

export const anonymizeStudiesDeleteOrigin = async (reportIds, setAllStudyList) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/studiesAnonymizeDelete`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(reportIds),
  })
  // fetchStudyList()
  //   .then(data => setAllStudyList(data))
  //   .catch(error => console.error('Error fetching study list:', error));
  const data = await response.json()

  return data
}


export const uploadStudy = async (files, setAllStudyList) => {
  const formData = new FormData();
  formData.append('files', files);

  files.forEach(file => {
    formData.append('files[]', file);
  });

  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/uploadStudy`, {
      method: 'POST',
      body: formData,
    });

    // fetchStudyList()
    //   .then(data => setAllStudyList(data))
    //   .catch(error => console.error('Error fetching study list:', error));

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Failed to upload study: ${error}`);
  }
};

export const fetchSessionsRadiologistUser = async (accessTokens, ids) => {
  const promises = ids.map(id => {
    return fetch(`${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/users/${id}/sessions`, {
      headers: {
        Authorization: `Bearer ${accessTokens}`
      }
    }).then(response => response.json());
  });

  return Promise.all(promises);
}

export const fetchChatUser = async (accessTokens) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/users`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessTokens}`,
    },
  });

  const data = await response.json();
  return data;
}

export const fetchUnreadUserMessages = async (userId, accessTokens) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/unread/${userId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessTokens}`,
    },
  });

  const data = await response.json();
  return data;
}

export const uploadVideoAttchmnet = async (attachment) => {
  const formData = new FormData();
  formData.append('attachment', attachment);

  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/video-upload-chat`, {
    method: 'POST',
    body: formData,
  });

  const data = await response.json();
  return data;
}

export const fetchMarkAsRead = async (data) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/mark-as-read`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error(`Failed to mark chat as read: ${response.statusText}`);
  }
}
