import React, { useEffect, useMemo, useState } from 'react';
import { Tooltip, useViewportGrid } from '@ohif/ui';
import classnames from 'classnames';
import { useToolbar } from '@ohif/core';
import { useLocation } from 'react-router-dom'
import jwt from "jsonwebtoken";

export function Toolbar({ servicesManager, buttonSection = 'primary' }) {
  const { toolbarButtons, onInteraction } = useToolbar({
    servicesManager,
    buttonSection,
  });

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 640);
  const toolbarService = useMemo(() => servicesManager.services.toolbarService, [servicesManager]);

  const hiddenButtons = [
    'StackScroll',
    'Cine',
    'Angle',
    'Magnify',
    'ImageSliceSync',
    'EllipticalROI',
    'RectangleROI',
    'Reset',
    'PlanarFreehandROI',
    'Length',
    'TagBrowser',
    'Probe'
  ];

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 640;
      setIsMobileView(isMobile);

      if (toolbarService.resetMenu) {
        toolbarService.resetMenu(isMobile);
      }
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [toolbarService]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const data = queryParams.get('StudyInstanceUIDs')
  const password = jwt.decode(data);
  const patientId = password?.url?.split('&')[1]
  const shouldDisplay = patientId?.includes('PatientId') || patientId?.includes('Accession');
  const shouldDisplay1 = queryParams.has('PatientId') || queryParams.has('Accession');

  const filteredButtons = toolbarButtons.filter(button => {
    if (shouldDisplay) {
      // Include only specific buttons when shouldDisplay is true
      return ["StackScroll", "Zoom", "Pan", "Magnify", "Reset"].includes(button.id);
    }
    // Include all buttons when shouldDisplay is false
    return true;
  });

  if (!toolbarButtons.length) {
    return null;
  }

  return (
    <>
      {filteredButtons.map(toolDef => {
        const { id, Component, componentProps } = toolDef;

        // Conditionally hide buttons in mobile view
        if (isMobileView && hiddenButtons.includes(id)) {
          return null;
        }

        return (
          <div key={id}>
            <Component
              id={id}
              {...componentProps}
              onInteraction={onInteraction}
              servicesManager={servicesManager}
            />
          </div>
        );
      })}
    </>
  );
}
