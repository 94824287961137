import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Select from '../Select';
import Typography from '../Typography';
import Button from '../Button';

import { Formik, Form, Field } from 'formik';
import Label from '../Label';
import { useSnackbar } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import { ToastContainer, toast } from 'react-toastify';


const RadiologyTimeZoneSetting = ({ onCancel, createRadiologyGroupSetting, updateRadiologyGroupSetting, timeZoneSetting, fetchRadiologyGroupSetting, setRadiologyGroupSetting, radiologyGroupList }) => {
  const { show } = useSnackbar();
  const { t } = useTranslation('UserPreferencesModal');
  const fieldData = timeZoneSetting ? JSON.parse(timeZoneSetting.field[0]) : null;

  const initialValues = {
    groupName: fieldData?.radiologyGroupNameTimeZone || '',
    groupTimeZone: fieldData?.timeZone || '',
    groupDaylightSaving: fieldData?.daylightSaving || '',
  };

  const [selectedGroupTimeZone, setSelectedGroupTimeZone] = useState(fieldData?.timeZone || null);
  const [selectedGroupDaylightSaving, setSelectedGroupDaylightSaving] = useState(fieldData?.daylightSaving || null);
  const [selectedGroupName, setSelectedGroupName] = useState(fieldData?.radiologyGroupNameTimeZone || null);



  const onSubmitHandler = async (values, { setSubmitting }) => {
    try {
      if (timeZoneSetting) {
        const data = {
          field: [
            JSON.stringify({
              radiologyGroupNameTimeZone: selectedGroupName,
              timeZone: selectedGroupTimeZone,
              daylightSaving: selectedGroupDaylightSaving
            })
          ],
        };
        const response = await updateRadiologyGroupSetting(timeZoneSetting.id, data)
        if (response.status) {
          fetchRadiologyGroupSetting()
            .then(data => setRadiologyGroupSetting(data))
            .catch(error => console.error('Error fetching radiology groups setting :', error));
          toast.success('Successfully Update Radiology Group Setting');
          // show({
          //   title: "Success!",
          //   message: "Successfully Update Radiology Group Setting",
          //   type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
          //   position: "topRight"
          // });
          onCancel()
        }
      } else {
        const data = {
          name: 'RadiologyGroupTimeZoneSetting',
          field: [
            JSON.stringify({
              radiologyGroupNameTimeZone: selectedGroupName,
              timeZone: selectedGroupTimeZone,
              daylightSaving: selectedGroupDaylightSaving
            })
          ],
        };
        const response = await createRadiologyGroupSetting(data)
        if (response.status) {
          fetchRadiologyGroupSetting()
            .then(data => setRadiologyGroupSetting(data))
            .catch(error => console.error('Error fetching radiology groups setting :', error));
          toast.success('Successfully Update Radiology Group Setting');
          // show({
          //   title: "Success!",
          //   message: "Successfully Saved Radiology Group Setting ",
          //   type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
          //   position: "topRight"
          // });
          onCancel()
        }
      }

      setSubmitting(false);
    } catch (error) {
      console.log(error.message);
      toast.warning('Please Refresh and Try Again');
    }
  };

  const optionGroup = radiologyGroupList.map(group => ({
    label: group.name,
    value: group.name
  }));

  const optionDayLightSaving = [
    { label: 'Yes', value: 'Yes' },
    { label: 'Know', value: 'Know' },
  ];

  const optionTimeZone = [
    { label: 'India', value: 'India' },
    { label: 'U.S.A', value: 'U.S.A' },
  ];

  const handleSelectionDaylightSavingChange = (selected) => {
    setSelectedGroupDaylightSaving(selected.value);
  };
  const handleSelectionaChange = (selected) => {
    setSelectedGroupName(selected.value);
  };
  const handleSelectionTimeZoneChange = (selected) => {
    setSelectedGroupTimeZone(selected.value);
  };


  const Section = ({ title, children }) => (
    <>
      <div className="mb-2 border-b-2 border-b-secondary-dark dark:border-b-primary-main">
        <Typography
          variant="inherit"
          color="primaryLight"
          className="flex pb-2 text-[16px] font-semibold !leading-[1.2] dark:text-white text-black"
        >
          {title}
        </Typography>
      </div>
      <div className=" mb-8">{children}</div>
    </>
  );

  return (
    <>
      {/* Radiology Group TAT */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
      >
        {({ isSubmitting }) => (
          <Form>
            <Section title={t('Radiology Group Time zone Setting')}>
              <div className='flex items-end'>
                <div className='grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4'>
                  <div className='md:w-72 w-full'>
                    <Label className="my-2 block dark:text-white text-black">Radiology Group Name</Label>
                    <Field
                      className=""
                      name="groupName"
                      placeholder={'Select Radiology Group Name'}
                      onChange={handleSelectionaChange}
                      as={Select}
                      options={optionGroup}
                      value={[selectedGroupName]}
                    />
                  </div>
                  <div className='md:w-72 w-full'>
                    <Label className="my-2 block dark:text-white text-black">Time Zone</Label>
                    <Field
                      as={Select}
                      placeholder={'Select Time Zone'}
                      options={optionTimeZone}
                      className=""
                      name="groupTAT"
                      value={[selectedGroupTimeZone]}
                      onChange={handleSelectionTimeZoneChange}
                    />
                  </div>
                  <div className='md:w-72 w-full'>
                    <Label className="my-2 block dark:text-white text-black">Daylight Saving</Label>
                    <Field
                      as={Select}
                      placeholder={'Select Daylight Saving'}
                      options={optionDayLightSaving}
                      className=""
                      name="groupTAT"
                      value={[selectedGroupDaylightSaving]}
                      onChange={handleSelectionDaylightSavingChange}
                    />
                  </div>
                </div>
                <Button disabled={isSubmitting} className='ml-4'>
                  Save
                </Button>
              </div>
            </Section>
          </Form>
        )}
      </Formik>

    </>
  );
};

export default RadiologyTimeZoneSetting;
