import React from 'react';

interface BreastCancerAiInterfaceProps {
    fetchedData?: {
        image: string;
        message: string;
        predictions: Array<{ [key: string]: number }>;
    };
}

const BreastCancerAiInterface: React.FC<BreastCancerAiInterfaceProps> = ({ fetchedData }) => {
    if (!fetchedData) return null;

    const { image, message, predictions = [] } = fetchedData || {};

    const isPredictionsValid = Array.isArray(predictions);

    // Calculate the highest risk score across all predictions
    const highestRiskScore = isPredictionsValid
        ? Math.max(
            ...predictions.map((prediction) =>
                Math.max(...Object.values(prediction))
            )
        )
        : 0;

    // Convert the highest score to a percentage (scale 0-100)
    const riskPercentage = highestRiskScore > 0 ? (highestRiskScore * 100).toFixed(0) : "0";

    // Function to handle image download
    const handleDownloadImage = () => {
        if (image) {
            const imageSrc = `data:image/png;base64,${image}`;
            const link = document.createElement("a");
            link.href = imageSrc;
            link.download = "breast_cancer_result_image.png";
            link.click();
            alert("Image download initiated!");
        } else {
            alert("No image to download");
        }
    };

    // Extract and deduplicate predicted class names
    const predictedClasses = Array.from(
        new Set(
            predictions
                .flatMap((prediction) =>
                    Object.entries(prediction)
                        .filter(([, score]) => score > 0) // Filter classes with non-zero scores
                        .map(([className]) => className) // Extract class names
                )
        )
    );

    // Create a message string based on predicted classes
    const predictedMessage =
        predictedClasses.length > 0
            ? `${predictedClasses.join(", ")} is present`
            : "No abnormalities detected";

    return (
        <div className="breast-cancer-ai-interface" style={{ padding: "20px" }}>
            {/* Display Image Section */}
            {image && (
                <div style={{ marginBottom: "20px", position: "relative" }}>
                    <img
                        src={`data:image/png;base64,${image}`}
                        alt="Breast Cancer AI Result"
                        style={{ width: "100%", maxHeight: "500px", objectFit: "contain" }}
                    />
                    <button
                        title="Download image"
                        onClick={handleDownloadImage}
                        style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            padding: "5px 10px",
                            backgroundColor: "#808080",
                            color: "#FFFFFF",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontWeight: "bold",
                        }}
                    >
                        ⇩
                    </button>
                </div>
            )}

            {/* Display Message Section */}
            {message && (
                <div style={{ marginBottom: "20px" }}>
                    <strong>{message}</strong>
                </div>
            )}

            {/* Display Predicted Classes */}
            {predictedMessage && (
                <div style={{ marginBottom: "20px", fontSize: "16px" }}>
                    <strong>{predictedMessage}</strong>
                </div>
            )}

            {/* Risk Scale Section */}
            <div style={{ marginBottom: "20px", textAlign: "center" }}>
                <h3>Risk Based on Presence of Abnormalities</h3>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                    <div style={{ flex: 1, maxWidth: "400px", position: "relative" }}>
                        {/* Progress Bar */}
                        <div
                            style={{
                                position: "relative",
                                height: "15px",
                                backgroundColor: "#e0e0e0",
                                borderRadius: "10px",
                                overflow: "hidden",
                            }}
                        >
                            <div
                                style={{
                                    width: `${riskPercentage}%`,
                                    backgroundColor: highestRiskScore > 0.75
                                        ? "#FF6F61"
                                        : highestRiskScore > 0.49
                                            ? "#FFC107"
                                            : "#4CAF50",
                                    height: "100%",
                                }}
                            />
                        </div>

                        {/* Labels */}
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                fontSize: "12px",
                                marginTop: "5px",
                            }}
                        >
                            <span>Low</span>
                            <span>Intermediate</span>
                            <span>Elevated</span>
                        </div>
                    </div>

                    {/* Highest Region Score */}
                    <div style={{ marginLeft: "20px", fontSize: "24px", fontWeight: "bold" }}>
                        {riskPercentage}%
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BreastCancerAiInterface;
