import React, { useEffect, useState } from 'react';
import {
  Button,
  Dashboard,
  InputDateRange,
  Label,
  Select,
  Typography,
  useRadiologyGroup,
  useSnackbar,
  useUser,
} from '@ohif/ui';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import { useAnalytics } from '../../contextProviders';

const StudyStatus = ({ servicesManager, extensionManager, hotkeysManager }) => {
  const { userAuthenticationService } = servicesManager.services;
  const { show } = useSnackbar();

  const user = userAuthenticationService.getUser();
  const { radiologyGroupList } = useRadiologyGroup();
  const { t } = useTranslation('UserPreferencesModal');
  const { fetchUsers, usersList } = useUser();
  const currentuser = user?.profile?.roleType?.includes('RadiologyGroupAdmin')
  const radiologyGroupName = user?.profile?.radiologyGroup;

  const {
    studyCount,
    fetchStudyStatusByPriorityAndDocument,
    studyStatusByPriorityAndDocument,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
  } = useAnalytics();

  const [selectedGroupName, setSelectedGroupName] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  // const [selectedDateRange, setSelectedDateRange] = useState<any>({ startDate, endDate });
  const [selectedDateRange, setSelectedDateRange] = useState<any>({
    startDate,
    endDate,
    ...(currentuser && { radiologyGroupName })
  });
  const [radiologistUserLists, setRadiologistUserLists] = useState([]);
  const [error, setError] = useState(null);
  const [studyFilterHeight, setStudyFilterHeight] = useState(false);

  // const optionGroup = radiologyGroupList.map(group => ({
  //   label: group.name,
  //   value: group.name,
  // }));

  // const optionGroup = Object.keys(studyStatusByPriorityAndDocument).flatMap(group => {
  //   return {
  //     label: group,
  //     value: group,
  //   };
  // });

  // const optionGroup = [
  //   { label: 'All Institutions', value: 'All Institutions' },
  //   ...Object.keys(studyStatusByPriorityAndDocument).flatMap(group => {
  //     return {
  //       label: group,
  //       value: group,
  //     };
  //   }),
  // ];



  // const optionGroup = currentuser
  //   ? Object.keys(studyStatusByPriorityAndDocument).flatMap(group => ({
  //     label: group,
  //     value: group,
  //   })) // If the user is RadiologyGroupAdmin, show only the study status options
  //   : [
  //     { label: 'All Institutions', value: 'All Institutions' },
  //     ...Object.keys(studyStatusByPriorityAndDocument).flatMap(group => ({
  //       label: group,
  //       value: group,
  //     })),
  //   ]; // If the user is not RadiologyGroupAdmin, include 'All Institutions'

  const optionGroup = currentuser
    ? [
      {
        label: radiologyGroupName, // Show only the current user's radiology group
        value: radiologyGroupName,
      },
    ]
    : [
      { label: 'All Institutions', value: 'All Institutions' },
      ...Object.keys(studyStatusByPriorityAndDocument).flatMap(group => ({
        label: group,
        value: group,
      })),
    ];


  // const optionRadiologistUser = Object.keys(studyStatusByPriorityAndDocument)
  // .filter(group => selectedGroupName === "All Institutions" || group === selectedGroupName)
  //   .flatMap(group => {
  //     const users = studyStatusByPriorityAndDocument[group];
  //     return Object.keys(users).map(user => {
  //       return {
  //         label: user,
  //         value: user,
  //       };
  //     });
  //   });

  const uniqueUsers = new Set();

  // const optionRadiologistUser = [
  //   { label: 'All Users', value: 'All Users' },
  //   ...Object.keys(studyStatusByPriorityAndDocument)
  //     .filter(group => selectedGroupName === 'All Institutions' || group === selectedGroupName)
  //     .flatMap(group => {
  //       const users = studyStatusByPriorityAndDocument[group];
  //       return Object.keys(users)
  //         .filter(user => {
  //           if (!uniqueUsers.has(user)) {
  //             uniqueUsers.add(user);
  //             return true;
  //           }
  //           return false;
  //         })
  //         .map(user => ({
  //           label: user,
  //           value: user,
  //         }));
  //     }),
  // ];

  // const optionRadiologistUser = currentuser
  //   ? Object.keys(studyStatusByPriorityAndDocument)
  //     .filter(group => selectedGroupName === 'All Institutions' || group === selectedGroupName)
  //     .flatMap(group => {
  //       const users = studyStatusByPriorityAndDocument[group];
  //       return Object.keys(users)
  //         .filter(user => {
  //           if (!uniqueUsers.has(user)) {
  //             uniqueUsers.add(user);
  //             return true;
  //           }
  //           return false;
  //         })
  //         .map(user => ({
  //           label: user,
  //           value: user,
  //         }));
  //     })
  //   : [
  //     { label: 'All Users', value: 'All Users' },
  //     ...Object.keys(studyStatusByPriorityAndDocument)
  //       .filter(group => selectedGroupName === 'All Institutions' || group === selectedGroupName)
  //       .flatMap(group => {
  //         const users = studyStatusByPriorityAndDocument[group];
  //         return Object.keys(users)
  //           .filter(user => {
  //             if (!uniqueUsers.has(user)) {
  //               uniqueUsers.add(user);
  //               return true;
  //             }
  //             return false;
  //           })
  //           .map(user => ({
  //             label: user,
  //             value: user,
  //           }));
  //       }),
  //   ];

  const optionRadiologistUser = currentuser
    ? [
      { label: 'All Users', value: 'All Users' }, // Add "All Users" option for the group
      ...Object.keys(studyStatusByPriorityAndDocument)
        .filter(group => group === selectedGroupName) // Only include the selected group
        .flatMap(group => {
          const users = studyStatusByPriorityAndDocument[group];
          return Object.keys(users)
            .filter(user => {
              if (!uniqueUsers.has(user)) {
                uniqueUsers.add(user);
                return true;
              }
              return false;
            })
            .map(user => ({
              label: user,
              value: user,
            }));
        }),
    ]
    : [
      { label: 'All Users', value: 'All Users' },
      ...Object.keys(studyStatusByPriorityAndDocument)
        .filter(group => selectedGroupName === 'All Institutions' || group === selectedGroupName)
        .flatMap(group => {
          const users = studyStatusByPriorityAndDocument[group];
          return Object.keys(users)
            .filter(user => {
              if (!uniqueUsers.has(user)) {
                uniqueUsers.add(user);
                return true;
              }
              return false;
            })
            .map(user => ({
              label: user,
              value: user,
            }));
        }),
    ];

  const handleSelectionGroupNameChange = selected => {
    setSelectedGroupName(selected.value);
  };

  const handleSelectionRadiologistUser = selected => {
    setSelectedUser(selected.value);
  };

  const handleDateRangeFieldChange = ({ startDate, endDate }) => {
    const start_date = moment(startDate).format('YYYY-MM-DD');
    const end_date = moment(endDate).format('YYYY-MM-DD');
    setSelectedDateRange({ start_date, end_date });
    setStartDate(start_date);
    setEndDate(end_date);
  };

  const fetchData = (startDate, endDate, radiologyGroupName) => {
    const reportsByPriorityParams = currentuser ? [startDate, endDate, radiologyGroupName] : [startDate, endDate];

    fetchStudyStatusByPriorityAndDocument(...reportsByPriorityParams).catch(error => setError(error));
  };

  const getLast7Days = () => {
    const today = new Date();
    const priorDate = new Date().setDate(today.getDate() - 7);

    return {
      startDate: new Date(priorDate).toISOString().split('T')[0],
      endDate: today.toISOString().split('T')[0],
    };
  };

  useEffect(() => {
    const { startDate, endDate } = getLast7Days();
    setSelectedDateRange({ startDate, endDate });
    setStartDate(startDate);
    setEndDate(endDate);
    fetchData(startDate, endDate, radiologyGroupName);
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      fetchData(startDate, endDate, radiologyGroupName);
      setSelectedDateRange({ startDate, endDate });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (startDate && endDate) {
      fetchData(startDate, endDate, radiologyGroupName);
      setSelectedDateRange({ startDate, endDate });
    }
  }, [startDate, endDate, selectedGroupName, selectedUser]);

  useEffect(() => {
    if (error) {
      show({ message: error.message, type: SnackbarTypes.ERROR });
    }
  }, [error, show]);

  const headers = ['group', 'user', 'Routine', 'STAT', 'ASAP', 'Completed', 'Pending'];

  const handleSearch: any = ({ startDate, endDate }) => {
    const startTimestamp = moment(startDate).format('MM/DD/YYYY');
    const endTimestamp = moment(endDate).format('MM/DD/YYYY');

    const filteredUsers = Object.keys(studyStatusByPriorityAndDocument)
      .filter(group => selectedGroupName === 'All Institutions' || group === selectedGroupName)
      .flatMap(group => {
        const users = studyStatusByPriorityAndDocument[group];
        return Object.keys(users)
          .filter(user => selectedUser === 'All Users' || user === selectedUser)
          .map(user => {
            const statusCounts = users[user];
            const userTimestamp = moment(statusCounts.createdTimestamp).format('MM/DD/YYYY');

            // Filter based on date range
            if (userTimestamp >= startTimestamp && userTimestamp <= endTimestamp) {
              return {
                group,
                user,
                ASAP: statusCounts.ASAP || 0,
                Completed: statusCounts.completed || 0,
                STAT: statusCounts.STAT || 0,
                Pending: statusCounts.pending || 0,
                Null: statusCounts.null || 0,
                Routine: statusCounts.Routine || 0,
              };
            }
            return null;
          })
          .filter(Boolean); // Remove null entries
      });

    setRadiologistUserLists(filteredUsers);

    if (filteredUsers.length === 0) {
      show({
        title: 'Error!',
        message: 'No Radiologist Users Exiting',
        type: SnackbarTypes.ERROR,
        position: 'topRight',
      });
    }
  };

  return (
    <>
      <div className=" container mx-auto min-h-[500px] max-h-[550px] overflow-y-auto overflow-x-auto telerapp-scrollbar"
        style={{
          maxHeight: 'calc(100vh - 115px)',
          // scrollbarColor: '#173239 transparent',
        }}>
        <div className=" flex items-center lg:justify-between justify-start my-5 gap-5 max-lg:flex-col">
          <div className=" flex items-center gap-3 w-full">
            <Label
              className="mt-2 max-sm:text-[16px] max-[375px]:text-[13px] dark:text-white text-black"
              text={''}
            >
              Date Range :{' '}
            </Label>
            <InputDateRange
              id={'report-analutic'}
              value={selectedDateRange}
              onChange={handleDateRangeFieldChange}
              setStudyFilterHeight={setStudyFilterHeight}
            />
          </div>
          <div className=' w-full lg:text-end text-start'>
            <p className="inline-flex items-center rounded border dark:border-primary-main border-secondary-dark bg-secondary-light dark:bg-secondary-dark py-2 px-4 font-semibold dark:text-white text-black transition-all opacity-80 mr-3 max-sm:text-[16px] max-[375px]:text-[13px]">
              Study Count: {studyCount?.count || 0}
            </p>
          </div>
        </div>

        <div className={` mb-5 `}>
          <p className="  dark:text-[#5acce6] text-black">Study Status </p>

          <div className="flex items-center lg:justify-between justify-start max-lg:flex-col lg:gap-4">
            <div className="flex sm:items-center ld:justify-between justify-start max-lg:w-full max-sm:flex-col">
              <Label
                className="my-2 block max-sm:text-[16px] max-[375px]:text-[13px] dark:text-white text-black"
                text={''}
              >
                Institution Name :{' '}
              </Label>
              <Select
                id="report-status-group"
                className={`sm:ml-3 ${selectedGroupName ? 'w-52' : ''}`}
                placeholder={'Select Groups Name'}
                onChange={handleSelectionGroupNameChange}
                options={optionGroup}
                value={[selectedGroupName]}
              />
            </div>
            <div className="flex items-center ld:justify-between justify-start max-lg:w-full max-sm:flex-col">
              <div className=' flex sm:items-center w-full max-sm:flex-col'>
                <Label
                  className="my-2 block max-sm:text-[16px] max-[375px]:text-[13px] dark:text-white text-black"
                  text={''}
                >
                  Radiologist User Name :
                </Label>
                <Select
                  id="group"
                  className={`sm:ml-3 ${selectedUser ? 'w-52' : ''}`}
                  placeholder={'Select Radiologist User Name'}
                  onChange={handleSelectionRadiologistUser}
                  options={optionRadiologistUser}
                  value={[selectedUser]}
                />
              </div>
              <div className=' max-sm:w-full'>
                <Button
                  className="my-2 py-0.5 px-5 ml-3 max-sm:text-[16px] max-[375px]:text-[13px] "
                  onClick={() => handleSearch(startDate, endDate)}
                >
                  Search
                </Button>
              </div>
            </div>
            <div className="flex items-center justify-end ">

            </div>
          </div>
          <div className={` mb-5 ${radiologistUserLists.length === 0 ? 'h-48 ' : ''} mr-3`}>
            <div className="max-h-[500px] overflow-y-auto telerapp-scrollbar"
              style={{
                overflowX: 'auto',
                maxHeight: 'calc(100vh - 230px)',
                // scrollbarColor: '#173239 transparent',
              }}>
              {radiologistUserLists.length > 0 ? (
                <table className=" container m-auto mt-2 w-full table-auto text-white">
                  <thead className="bg-[#d1d1d1] dark:bg-primary-dark dark:border-primary-main border-secondary-dark border sticky top-[-10px] z-2" >
                    <tr className=' max-sm:text-base '>
                      {headers.map(header => (
                        <th
                          className="p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black"
                          key={header}
                        >
                          {header === 'group'
                            ? 'Institution Group'
                            : header === 'user'
                              ? 'Radiologist User'
                              : header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="mt-1">
                    {radiologistUserLists.map((row, index) => (
                      <tr
                        key={index}
                        className="bg-secondary-light dark:bg-secondary-dark dark:border-primary-main border-secondary-dark hover:border-secondary-dark dark:hover:bg-[#414141] hover:bg-primary-light cursor-pointer border text-center transition duration-300 max-sm:text-sm"
                      >
                        {headers.map(header => (
                          <td
                            className="p-2 dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black"
                            key={header}
                          >
                            {row[header]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className=" h-full flex justify-center items-center bg-secondary-light dark:bg-secondary-dark  dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black p-4 mt-2  max-sm:text-[16px] max-[375px]:text-[13px]">
                  Data not available.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudyStatus;
