import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LegacyButton from '../LegacyButton';
import LegacyButtonGroup from '../LegacyButtonGroup';
import Typography from '../Typography';
import Select from '../Select';
import Button from '../Button';
import { useAnalytics, useModal, useReportEditor, useUser } from '../../contextProviders';
import RadiologistUserActiveDetailsModel from './RadiologistUserActiveDetailsModel';
import { RxCross2 } from 'react-icons/rx';
import { Loader } from 'rsuite';
import { GoDotFill } from 'react-icons/go';
import { FaChalkboardUser } from 'react-icons/fa6';
import { FaUserMd } from 'react-icons/fa';
import Tooltip from '../Tooltip';
import LiveChat from '../LiveChat';
import { BsChatDotsFill } from 'react-icons/bs';
import { io } from "socket.io-client";

const StudyListPagination = ({ servicesManager, onChangePage, currentPage, perPage, numOfStudies, onChangePerPage, token, numOfFilteredStudies }) => {
  const { t } = useTranslation('StudyList');
  const { show, hide } = useModal();
  const { radiologistData, setLast24endDate, setLast24startDate, } = useAnalytics();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isChatPopupOpen, setIsChatPopupOpen] = useState(false);
  const [pageInput, setPageInput] = useState(null);
  const [serverUser, setServerUser] = useState([]);
  // const togglePopup = () => {
  //   setIsPopupOpen(!isPopupOpen);
  // };

  const togglePopup = (e) => {
    e.stopPropagation(); // Stop the click event from propagating
    setIsPopupOpen(prev => !prev); // Toggle the popup state
  };

  const { userAuthenticationService } = servicesManager.services;
  const user = userAuthenticationService.getUser();

  const loginUserId = user?.profile?.sub;
  const currentUserradiologyGroup = user?.profile?.radiologyGroup;
  const permissions = user?.profile?.permission

  const toggleChatPopup = (e) => {
    localStorage.setItem('loginUserId', loginUserId)
    e.stopPropagation(); // Stop the click event from propagating
    setIsChatPopupOpen(prev => !prev); // Toggle the popup state
  };
  const hasActiveRadiologyUserList =
    (permissions?.includes('Access Dashboard') &&
      permissions?.includes('Access Active Radiologist') &&
      user?.profile?.roleType === 'RadiologyGroupAdmin') ||

    (permissions?.includes('Access Dashboard') &&
      ['Radiologist', 'QaUsers', 'TelerappManager', 'Physician', 'Technologist', 'DeputyAdmin']?.includes(user?.profile?.roleType)) ||

    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const superPermission =
    token?.realm_access?.roles?.includes('super-admin') ||
    user?.profile?.roleType?.includes('DeputyAdmin') ||
    user?.profile?.roleType?.includes('TelerappManager') ||
    user?.profile?.roleType?.includes('QaUsers') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const chatPermission = token?.realm_access?.roles?.includes('super-admin') || permissions?.includes('Chat Access')
  const isRadiologyGroupAdmin = user?.profile?.roleType?.includes('RadiologyGroupAdmin');
  const loginUserGroup = user?.profile?.radiologyGroup;
  const {
    usersList,
    fetchSessionsRadiologistUser,
    fetchChatUser,
    fetchUnreadUserMessages,
    uploadVideoAttchmnet,
    fetchMarkAsRead,
  } = useUser();

  const radiologistUserLists =usersList &&usersList?.filter(user => user?.attributes?.roleType?.includes('Radiologist'));
  const telerappManagerList = usersList && usersList?.filter(user => user?.attributes?.roleType?.includes('TelerappManager'));
  const telerappManagerListFilter = telerappManagerList.filter((i)=> i?.attributes?.permission?.includes("Chat Access"))

  const [users, setUsers] = useState([]);

  useEffect(() => {
    let transformedUsers
    if(isRadiologyGroupAdmin){
      const radilogyGroupList = usersList.filter((user) => {
        const radiologyGroup = user?.attributes?.radiologyGroup;

        // If the user has superPermission, include all users
        if (superPermission) {
          return true;
        }

        // Otherwise, include users based on radiologyGroup matching
        return radiologyGroup?.includes(loginUserGroup);
      })
      .map((user) => ({
        id: user?.id,
        name: user?.username,
      }));

      const telerappManagerListFilter2 =  telerappManagerListFilter.map((i)=> ({
        id: i?.id,
        name: i?.username,
      }))

      transformedUsers = [...radilogyGroupList,...telerappManagerListFilter2]
    }else {
      transformedUsers = usersList.filter(user => {
        const radiologyGroup = user?.attributes?.radiologyGroup;

        // If the user has superPermission, include all users
        if (superPermission) {
          return true;
        }

        // Otherwise, include users based on radiologyGroup matching
        return radiologyGroup?.includes(loginUserGroup);
      }).map(user => ({
        id: user?.id,
        name: `${user?.firstName} ${user?.lastName}`
      }));
    }
    setUsers(transformedUsers);
  }, [usersList, superPermission, loginUserGroup]);

  const fetchUsers = async () => {
    try {
      const data = await fetchChatUser(user?.access_token);
      const filterData = data?.filter(i => i.userId === loginUserId);

      const filterData2 = data && data
        .map(i => {
          if (i.receiverId === loginUserId) {
            return {
              userId: i.receiverId, // or generate a default value
              receiverId: i.userId.toString(),
              last_conversation_time: i.last_conversation_time, // or set a default time
              receiverName: i.name, // assuming `name` is the property you want
            };
          }
          return null; // return null for non-matching items (optional)
        })
        .filter(item => item !== null);

      const mergeData = [...filterData2, ...filterData];

      // Use a Map to store the latest entries by receiverId
      const latestMap = new Map();

      mergeData.forEach(item => {
        const existingItem = latestMap.get(item.receiverId);
        if (
          !existingItem ||
          new Date(item.last_conversation_time) > new Date(existingItem.last_conversation_time)
        ) {
          latestMap.set(item.receiverId, item);
        }
      });
      const mixData = Array.from(latestMap.values());

      const matchedData = [];

      // Iterate over each user and check if it exists in mixData
      users.forEach(user => {
        const matchedUser = mixData.find(serverUser => serverUser.receiverId === user.id.toString());

        if (matchedUser) {
          // Push matched data into the new array
          matchedData.push({
            ...matchedUser,
            receiverName: user.name, // Update receiverName to ensure it's from the `user` object
          });
        } else {
          // If no match is found, you can also add missing data if needed
          matchedData.push({
            userId: `${loginUserId}`, // or generate a default value
            receiverId: user.id.toString(),
            last_conversation_time: null, // or set a default time
            receiverName: user.name,
          });
        }
      });

      const sortedData2 = matchedData.sort((a, b) => {
        // Handle null values
        if (!a.last_conversation_time) return 1; // Move null to the end
        if (!b.last_conversation_time) return -1; // Move null to the end

        // Compare dates in descending order
        return new Date(b.last_conversation_time) - new Date(a.last_conversation_time);
      });

      const mappedData = sortedData2.map(item => {
        const matchedUser = users.find(user => user.id === item.receiverId);
        return {
          ...item,
          receiverName: matchedUser ? matchedUser.name : '',
        };
      });

      setServerUser(mappedData);
      // setServerUser(data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [users])

  const radiologistUser =
    user?.profile?.roleType?.includes('RadiologyGroupAdmin')
      ? Array.isArray(radiologistUserLists)
        ? radiologistUserLists.filter(
          data =>
            data?.attributes?.radiologyGroup &&
            data?.attributes?.radiologyGroup?.includes(currentUserradiologyGroup)
        )
          .map(data => data.id)
        : [] // Fallback to an empty array if radiologistUserLists is not an array
      : Array.isArray(radiologistUserLists)
        ? radiologistUserLists.map(data => data.id)
        : [];

  const navigateToPage = page => {
    const toPage = page < 1 ? 1 : page;
    onChangePage(toPage);
    setPageInput('');
  };

  const handlePageInputChange = e => {
    const value = e.target.value;
    setPageInput(value ? parseInt(value) : ''); // Allow blank input
  };

  const handlePageInputKeyPress = (e) => {
    if (e.key === 'Enter' && pageInput && pageInput > 0) {
      navigateToPage(pageInput);
    }
  };

  const inputRef = useRef(null); // Create a ref for the input element

  const handlePageTextClick = () => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input when "Page" is clicked
    }
  };

  const ranges = [
    { value: '15', label: '15' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
  ];

  const [selectedRange, setSelectedRange] = useState({
    value: '15',
    label: '15'
  });

  const onSelectedRange = selectedRange => {
    setSelectedRange(selectedRange);
    onChangePerPage(selectedRange.value);
  };

  const handlemodelShow = () => {
    show({
      content: RadiologistUserActiveDetailsModel,
      title: `Radiologist List`,
      contentProps: {
        fetchSessionsRadiologistUser,
        radiologistData,
        user,
        radiologistUser,
      },
    });
  };

  return (
    <div className="dark:bg-primary-dark bg-primary-light py-3 ">
      <div className="px-5 relative">
        {/* Ensure the popup can position relative to this container */}
        <div className="flex justify-between">
          <div className="flex items-center gap-4">
            <div className="flex items-center">
              <Tooltip
                content={'Results per page'}
                position="top"
                style={{ padding: '8px', fontWeight: 'normal' }}
              >
                <Select
                  id="rows-per-page"
                  className="border-primary-main relative w-24 mr-2"
                  options={ranges}
                  value={selectedRange}
                  isMulti={false}
                  isClearable={false}
                  isSearchable={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  onChange={onSelectedRange}
                />
              </Tooltip>

              <Typography variant="h8" className='max-sm:hidden dark:text-white text-black'>
                Quantum Powered by Telerapp
              </Typography>
            </div>
          </div>

          <div className="">
            <div className="flex items-center">
              <Typography variant="h8" className="dark:text-white text-secondary-dark max-sm:hidden">
                {`${t('Study count')}: `}
              </Typography>
              <Typography variant="h8" className="mr-2 max-sm:hidden dark:text-white text-secondary-dark " data-cy={'num-studies'}>
                {/* {numOfStudies} */}
                {numOfFilteredStudies}
              </Typography>

              <LegacyButtonGroup>
                <LegacyButton
                  size="initial"
                  className="px-2 py-1 text-base flex items-center dark:text-white text-black"
                  color="translucent"
                  border="primary"
                  variant="outlined"
                >
                  <span onClick={handlePageTextClick} className="cursor-pointer">
                    Page
                  </span>
                  <input
                    ref={inputRef}
                    type="number"
                    className="w-5 text-center bg-transparent outline-none dark:text-white text-black"
                    value={pageInput}
                    onChange={handlePageInputChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handlePageInputKeyPress(e); // Call your function here when Enter is pressed
                      }
                    }}
                    placeholder={currentPage}
                  // min="1"
                  />
                </LegacyButton>
                <LegacyButton
                  size="initial"
                  className="py-2 px-4 text-base w-5 dark:text-white text-black"
                  color="translucent"
                  border="primary"
                  variant="outlined"
                  onClick={() => {
                    navigateToPage(currentPage - 1)
                  }}
                >
                  {t('<')}
                </LegacyButton>
                <LegacyButton
                  size="initial"
                  className="py-2 px-4 text-base w-5 dark:text-white text-black"
                  color="translucent"
                  border="primary"
                  variant="outlined"
                  onClick={() => {
                    navigateToPage(currentPage + 1)
                  }}
                // disabled={numOfStudies <= perPage * currentPage}
                >
                  {t('>')}
                </LegacyButton>
              </LegacyButtonGroup>


              {hasActiveRadiologyUserList && <div className="ml-2 max-[383px]:hidden">
                <button onClick={togglePopup} className="inline-flex items-center rounded-md border dark:border-primary-main border-secondary-dark bg-secondary-light dark:bg-secondary-dark py-1 px-4 font-semibold dark:text-white text-black transition-all hover:opacity-60">

                  <Tooltip
                    content={'Radiologists'}
                    position="top"
                    style={{ padding: '8px', fontWeight: 'normal' }}
                  ><FaUserMd className=" text-xl" /></Tooltip>
                </button>
              </div>}

              {isPopupOpen && (
                < RadiologistUserActiveDetailsModel fetchSessionsRadiologistUser={fetchSessionsRadiologistUser}
                  radiologistData={radiologistData}
                  user={user}
                  radiologistUser={radiologistUser}
                  togglePopup={togglePopup}
                  setLast24startDate={setLast24startDate}
                  setLast24endDate={setLast24endDate}
                />
              )}
              {chatPermission && <div className="ml-2 max-[383px]:hidden">
                <button onClick={toggleChatPopup} className="inline-flex items-center rounded-md border dark:border-primary-main border-secondary-dark bg-secondary-light dark:bg-secondary-dark py-1 px-4 font-semibold dark:text-white text-black transition-all hover:opacity-60">

                  <Tooltip
                    content={'Live Chat With User'}
                    position="left"
                    style={{ padding: '8px', fontWeight: 'normal' }}
                  ><BsChatDotsFill className=" text-xl" /></Tooltip>
                </button>
              </div>}

              {isChatPopupOpen && (
                <LiveChat
                  usersList={usersList}
                  users={users}
                  setUsers={setUsers}
                  loginUserId={loginUserId}
                  serverUser={serverUser}
                  fetchSessionsRadiologistUser={fetchSessionsRadiologistUser}
                  access_token={user?.access_token}
                  fetchUsers={fetchUsers}
                  modelOpen={show}
                  toggleChatPopup={toggleChatPopup}
                  fetchUnreadUserMessages={fetchUnreadUserMessages}
                  uploadVideoAttchmnet={uploadVideoAttchmnet}
                  fetchMarkAsRead={fetchMarkAsRead}
                />
              )}
            </div>
          </div>
        </div>

        {/* max-width 383px */}
        <div className='flex justify-between min-[384px]:hidden mt-2 '>
            {hasActiveRadiologyUserList && <div>
              <button onClick={togglePopup} className="inline-flex items-center rounded-md border dark:border-primary-main border-secondary-dark bg-secondary-light dark:bg-secondary-dark py-1 px-4 font-semibold dark:text-white text-black transition-all hover:opacity-60">

                <Tooltip
                  content={'Radiologists'}
                  position="top"
                  style={{ padding: '8px', fontWeight: 'normal' }}
                ><FaUserMd className=" text-xl" /></Tooltip>
              </button>
            </div>}
          <div>
            <button
              onClick={toggleChatPopup}
              className="dark:border-primary-main border-secondary-dark bg-secondary-light dark:bg-secondary-dark inline-flex items-center rounded-md border py-1 px-4 font-semibold text-black transition-all hover:opacity-60 dark:text-white"
            >
              <Tooltip
                content={'Live Chat With USer'}
                position="left"
                style={{ padding: '8px', fontWeight: 'normal' }}
              >
                <BsChatDotsFill className="text-xl" />
              </Tooltip>
            </button>
          </div>
        </div>
        {/* max-width 431px */}
        <div className='flex justify-between min-[431px]:hidden mt-1 '>
          <div>
            <Typography variant="h8" className={'text-base dark:text-white text-black'}>
              Quantum Powered by Telerapp
            </Typography>
          </div>
          <div>
            <Typography variant="h8" className="dark:text-white text-secondary-dark text-base">
              {`${t('Study count')}: `}
            </Typography>
            <Typography variant="h8" className="mr-2 text-base dark:text-white text-secondary-dark" data-cy={'num-studies'}>
              {numOfStudies}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

StudyListPagination.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  onChangePerPage: PropTypes.func.isRequired,
  token: PropTypes.object.isRequired,
};

export default StudyListPagination;
