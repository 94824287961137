import React, { useState, useEffect } from "react";
import { BsDot } from "react-icons/bs";

const TypingIndicator = ({ isTyping, text = "is typing", animationDelay = 300 }) => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    if (isTyping) {
      const interval = setInterval(() => {
        setDots((prev) => (prev.length < 3 ? prev + "." : ""));
      }, animationDelay);
      return () => clearInterval(interval);
    }
    setDots("");
  }, [isTyping, animationDelay]);

  if (!isTyping) return null;

  return (
    <div className=" flex justify-start items-center">
      <div className=" py-2 dark:text-secondary-light text-secondary-dark rounded-lg flex items-center">
        <span>Typing {dots}</span>
      </div>
    </div>
  );
};

export default TypingIndicator;
