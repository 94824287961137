import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Header, SettingsLeftPanel } from '@ohif/ui';
// Setting Components
import RadiologyTATSetting from './RadiologyTATSetting';
import RadiologyViewerSetting from './RadiologyViewerSetting';
import RadiologyPatientInformationSetting from './RadiologyPatientInformationSetting';
import RadiologyTimeZoneSetting from './RadiologyTimeZoneSetting';
import { useRadiologyGroup, useUser } from '../../contextProviders';
import { useAppConfig } from '@state';
import { useNavigate } from 'react-router-dom';


const RadiologyGroupSettings = ({
  onCancel,
  servicesManager,
  extensionManager,
  hotkeysManager,
  data: studies,
}) => {
  const {
    createRadiologyGroupSetting,
    radiologyGroupSetting,
    updateRadiologyGroupSetting,
    fetchRadiologyGroupSetting,
    setRadiologyGroupSetting,
    radiologyGroupList,
  } = useRadiologyGroup();

  const { t } = useTranslation('UserPreferencesModal');
  const [appConfig] = useAppConfig();

  const TATSetting = radiologyGroupSetting.length > 0 && radiologyGroupSetting?.find(item => item.name === 'RadiologyGroupTATSetting');
  const viewerSetting = radiologyGroupSetting.length > 0 && radiologyGroupSetting?.find(
    item => item.name === 'RadiologyGroupViewerSetting'
  );
  const patientInformationSetting = radiologyGroupSetting.length > 0 && radiologyGroupSetting?.find(
    item => item.name === 'RadiologyGroupPatientInformationSetting'
  );
  const timeZoneSetting = radiologyGroupSetting.length > 0 && radiologyGroupSetting?.find(
    item => item.name === 'RadiologyGroupTimeZoneSetting'
  );
  const { userAuthenticationService } = servicesManager.services;
  const navigate = useNavigate();
  const {
    token,
    userToken,
    setToken,
  } = useUser();

  const user = userAuthenticationService.getUser();

  useEffect(() => {
    const getToken = async () => {
      try {
        const data = {
          token: user.access_token,
        };
        const response = await userToken(data);
        setToken(response);
      } catch (error) {
        console.log(error);
      }
    };
    getToken();
  }, []);

  const onClickReturnButton = () => {
    const { pathname } = location;
    const dataSourceIdx = pathname.indexOf('/', 1);
    const query = new URLSearchParams(window.location.search);
    const configUrl = query.get('configUrl');

    const dataSourceName = pathname.substring(dataSourceIdx + 1);
    const existingDataSource = extensionManager.getDataSources(dataSourceName);

    const searchQuery = new URLSearchParams();
    if (dataSourceIdx !== -1 && existingDataSource) {
      searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
    }

    if (configUrl) {
      searchQuery.append('configUrl', configUrl);
    }

    navigate({
      pathname: '/',
      search: decodeURIComponent(searchQuery.toString()),
    });
  };

  const onCancelHandler = () => {
    onCancel();
  };

  return (
    <>
      <Header
        servicesManager={servicesManager}
        hotkeysManager={hotkeysManager}
        sortedStudies={studies}
        isReturnEnabled={!!appConfig.showStudyList}
        onClickReturnButton={onClickReturnButton}
        WhiteLabeling={appConfig.whiteLabeling}
        loggedInUserDetails={user}
        loggedInUserTokenDetails={token}
        // headerOpen={true}
      />
      {/* <div className="absolute top-16 left-[260px] text-white"> */}
      <div className='max-sm:px-3'>
        <div className='container relative mx-auto flex flex-col pt-5 dark:text-white text-black'>
          {/* Radiology Group TAT Setting */}
          <RadiologyTATSetting
            createRadiologyGroupSetting={createRadiologyGroupSetting}
            updateRadiologyGroupSetting={updateRadiologyGroupSetting}
            TATSetting={TATSetting}
            fetchRadiologyGroupSetting={fetchRadiologyGroupSetting}
            setRadiologyGroupSetting={setRadiologyGroupSetting}
            onCancel={onCancel}
            radiologyGroupList={radiologyGroupList}
            studies={studies}
          />

          {/* Radiology Group Viewer Setting */}
          <RadiologyViewerSetting
            createRadiologyGroupSetting={createRadiologyGroupSetting}
            updateRadiologyGroupSetting={updateRadiologyGroupSetting}
            viewerSetting={viewerSetting}
            fetchRadiologyGroupSetting={fetchRadiologyGroupSetting}
            setRadiologyGroupSetting={setRadiologyGroupSetting}
            onCancel={onCancel}
            radiologyGroupList={radiologyGroupList}
          />

          {/* Radiology Group Patient Information Setting */}
          <RadiologyPatientInformationSetting
            createRadiologyGroupSetting={createRadiologyGroupSetting}
            updateRadiologyGroupSetting={updateRadiologyGroupSetting}
            patientInformationSetting={patientInformationSetting}
            fetchRadiologyGroupSetting={fetchRadiologyGroupSetting}
            setRadiologyGroupSetting={setRadiologyGroupSetting}
            onCancel={onCancel}
            radiologyGroupList={radiologyGroupList}
          />

          {/* Radiology Group Time zone Setting */}
          <RadiologyTimeZoneSetting
            createRadiologyGroupSetting={createRadiologyGroupSetting}
            updateRadiologyGroupSetting={updateRadiologyGroupSetting}
            timeZoneSetting={timeZoneSetting}
            fetchRadiologyGroupSetting={fetchRadiologyGroupSetting}
            setRadiologyGroupSetting={setRadiologyGroupSetting}
            onCancel={onCancel}
            radiologyGroupList={radiologyGroupList}
          />
        </div>
      </div>
    </>
  );
};

const noop = () => {};

RadiologyGroupSettings.propTypes = {
  disabled: PropTypes.bool,
  hotkeyDefaults: PropTypes.object.isRequired,
  hotkeyDefinitions: PropTypes.object.isRequired,
  languageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ),
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  hotkeysModule: PropTypes.shape({
    initialize: PropTypes.func.isRequired,
    pause: PropTypes.func.isRequired,
    unpause: PropTypes.func.isRequired,
    startRecording: PropTypes.func.isRequired,
    record: PropTypes.func.isRequired,
  }).isRequired,
};

RadiologyGroupSettings.defaultProps = {
  languageOptions: [
    { value: 'ONE', label: 'ONE' },
    { value: 'TWO', label: 'TWO' },
  ],
  onCancel: noop,
  onSubmit: noop,
  onReset: noop,
  disabled: false,
};

export default RadiologyGroupSettings;
