import React, { useEffect, useState } from 'react';
import { Dashboard, Header, InputDateRange, useUser } from '@ohif/ui';
import LogList from './LogList';
import LogList2 from './LogList2';
import * as XLSX from 'xlsx'; // Import XLSX for handling Excel export
import { useLogViewer } from '../../contextProviders/LogViewerProvider'; // Adjust the import path as needed
import './LogViewer.css';
import { BsSearch } from 'react-icons/bs';
import { GrPowerReset } from 'react-icons/gr';
import { FaFileDownload } from "react-icons/fa";
import Typography from '../Typography';
import Button from '../Button';
import EventLogs from './EventLogs';
import { useNavigate } from 'react-router-dom';
import { useAppConfig } from '@state';
import Select from '../Select';
import moment from 'moment';


const LogViewer = ({ servicesManager, extensionManager, hotkeysManager, data: studies }) => {
    const { logs, setSelectedDate, setSelectedLogFileName, setSelectedDateRange, selectedDateRange } = useLogViewer();// Ensure 'logs' is destructured from context
    const [selectedLog, setSelectedLog] = useState(null);
    const [fileName, setFileName] = useState('StudyLogs'); // Default file name (can be changed by the dropdown)
    const [searchTerm, setSearchTerm] = useState(''); // State for search term
    const [studyFilterHeight, setStudyFilterHeight] = useState(false);

    // Initialize the dates to today
    const today = new Date().toISOString().split('T')[0];
    const [date, setDate] = useState(today);
    const [endDate, setEndDate] = useState(today);

    const { userAuthenticationService } = servicesManager.services;
    const user = userAuthenticationService.getUser();
    const navigate = useNavigate();
    const [appConfig] = useAppConfig();

    const {
        token,
        userToken,
        setToken,
    } = useUser();

    const logOption = [
        { label: 'StudyLogs', value: 'StudyLogs' },
        { label: 'AddendumLogs', value: 'AddendumLogs' },
        { label: 'ApproveLogs', value: 'ApproveLogs' },
        { label: 'AssignLogs', value: 'AssignLogs' },
        { label: 'UnassignLogs', value: 'UnassignLogs' },
        // { label: 'HistoryLogs', value: 'HistoryLogs' },
        // { label: 'ShareLogs', value: 'ShareLogs' },
        { label: 'PriorityLogs', value: 'PriorityLogs' },
        { label: 'ErrorLogs', value: 'ErrorLogs' },
        // { label: 'DeleteLogs', value: 'DeleteLogs' },
        { label: 'SubmitLogs', value: 'SubmitLogs' },
        { label: 'DraftLogs', value: 'DraftLogs' },
        { label: 'CriticalLogs', value: 'CriticalLogs' },
        { label: 'LogInLogs', value: 'LogInLogs' },
    ]

    useEffect(() => {
        const getToken = async () => {
            try {
                const data = {
                    token: user.access_token,
                };
                const response = await userToken(data);
                setToken(response);
            } catch (error) {
                console.log(error);
            }
        };
        getToken();
    }, []);

    const onClickReturnButton = () => {
        const { pathname } = location;
        const dataSourceIdx = pathname.indexOf('/', 1);
        const query = new URLSearchParams(window.location.search);
        const configUrl = query.get('configUrl');

        const dataSourceName = pathname.substring(dataSourceIdx + 1);
        const existingDataSource = extensionManager.getDataSources(dataSourceName);

        const searchQuery = new URLSearchParams();
        if (dataSourceIdx !== -1 && existingDataSource) {
            searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
        }

        if (configUrl) {
            searchQuery.append('configUrl', configUrl);
        }

        navigate({
            pathname: '/',
            search: decodeURIComponent(searchQuery.toString()),
        });
    };

    useEffect(() => {
        handleFetchLogs(); // Fetch logs for default values on component mount
    }, []);

    // const handleDateChange = (e) => {
    //     setDate(e.target.value);
    // };

    const handleDateRangeFieldChange = ({ startDate, endDate }) => {
        setSelectedDateRange({ startDate, endDate });
        setDate(startDate);
        setEndDate(endDate);
    };

    // const handleEndDateChange = (e) => {
    //     setEndDate(e.target.value);
    // };

    const handleFileNameChange = (e) => {
        setFileName(e.value); // Update selected file name from dropdown
    };

    const handleFetchLogs = () => {
        if (date && !endDate) {
            setSelectedDate(date); // Set the selected date if no end date is provided
            setSelectedLogFileName(fileName); // Set the selected log file name
        } else if (date && endDate) {
            setSelectedDateRange({ startDate: date, endDate }); // Set the selected date range if both dates are provided
            setSelectedLogFileName(fileName); // Set the selected log file name
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value); // Update search term
    };

    const handleReset = () => {
        // Reset states to default
        setSelectedDateRange({ startDate: today, endDate: today });
        setDate(today);
        setEndDate(today);
        setSearchTerm('');
        setFileName('StudyLogs');
        handleFetchLogs(); // Fetch logs with reset values
    };

    // Handle Export functionality
    const handleExport = () => {
        if (!logs) {
            alert('No logs available to export.');
            return;
        }

        let logEntries = [];

        // Helper function to format the log date and time in India/Mumbai time zone
        const formatDateTime = (timestamp) => {
            const dateTime = new Date(timestamp);

            const options = {
                timeZone: 'Asia/Kolkata',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true,
            };

            const formattedDateTime = new Intl.DateTimeFormat('en-IN', options).formatToParts(dateTime);
            const date = `${formattedDateTime[4].value}-${formattedDateTime[2].value}-${formattedDateTime[0].value}`;
            const time = `${formattedDateTime[6].value}:${formattedDateTime[8].value}:${formattedDateTime[10].value} ${formattedDateTime[12].value}`;
            return { date, time };
        };

        // Parse logs based on the selected fileName
        const parseLogs = (logContent, type) => {
            const logLines = logContent.split('\n').filter((entry) => entry.trim() !== '');
            return logLines.map((line) => {
                try {
                    const log = JSON.parse(line);
                    if (type === 'StudyLogs') {
                        const { StudyInstanceUID, PatientID, AET, InstitutionName, Modality, timestamp } = log;
                        const dateTime = formatDateTime(timestamp);
                        return {
                            Date: dateTime.date,
                            Time: dateTime.time,
                            'Study UID': StudyInstanceUID || 'N/A',
                            'Patient ID': PatientID || 'N/A',
                            AET: AET || 'N/A',
                            Institution: InstitutionName || 'N/A',
                            Modality: Modality || 'N/A',
                        };
                    } else {
                        const { accession, patientID, action, userName, timestamp, UID } = log;
                        const dateTime = formatDateTime(timestamp);
                        return {
                            Date: dateTime.date,
                            Time: dateTime.time,
                            UID: UID || 'N/A',
                            Accession: accession || 'N/A',
                            'Patient ID': patientID || 'N/A',
                            Activity: action || 'Activity not found',
                            User: userName || 'Unknown User',
                        };
                    }
                } catch (error) {
                    console.error('Failed to parse log line:', line, error);
                    return null;
                }
            }).filter(entry => entry !== null);
        };

        // Gather log entries based on the selected fileName
        if (fileName === 'StudyLogs') {
            if (logs?.logs) {
                logs.logs.forEach((log) => {
                    logEntries = [...logEntries, ...parseLogs(log.logContent, 'StudyLogs')];
                });
            } else if (logs?.logContent) {
                logEntries = parseLogs(logs.logContent, 'StudyLogs');
            }
        } else {
            if (logs?.logs) {
                logs.logs.forEach((log) => {
                    logEntries = [...logEntries, ...parseLogs(log.logContent, 'OtherLogs')];
                });
            } else if (logs?.logContent) {
                logEntries = parseLogs(logs.logContent, 'OtherLogs');
            }
        }

        if (logEntries.length === 0) {
            alert('No log entries found to export.');
            return;
        }

        // Create a worksheet from the log entries
        const worksheet = XLSX.utils.json_to_sheet(logEntries);

        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Logs');

        // Generate a buffer
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Create a Blob from the buffer
        const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });

        // Create a download link and trigger the download
        const url = window.URL.createObjectURL(dataBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${fileName}_${new Date().toISOString()}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    return (
        <section className="relative w-full text-white">
            <Header
                servicesManager={servicesManager}
                hotkeysManager={hotkeysManager}
                sortedStudies={studies}
                isReturnEnabled={!!appConfig.showStudyList}
                onClickReturnButton={onClickReturnButton}
                WhiteLabeling={appConfig.whiteLabeling}
                loggedInUserDetails={user}
                loggedInUserTokenDetails={token}
                // headerOpen={true}
            />
            {/* <div className="absolute top-16 p-3 left-60 right-0"> */}
            <div className='max-sm:px-3'>
                <div className="container mx-auto flex flex-col pt-5">
                    <div className="flex lg:flex-row justify-between items-center w-full flex-col gap-3">
                        <div className="flex min-w-[1px] shrink sm:flex-row flex-col sm:items-center gap-6 max-sm:gap-2 max-lg:w-full max-lg:justify-between">
                            <Typography variant="h6" className="dark:text-white text-black max-sm:text-[16px] mt-2" component={'h6'}>
                                Audit Logs
                            </Typography>
                        </div>
                        <div className="flex lg:flex-row flex-col items-center space-x-2 max-lg:w-full">
                            <div className="flex flex-row items-center space-x-2 max-lg:w-full max-lg:justify-between lg:mt-2">
                                <div className="relative">
                                    <input
                                        type="text"
                                        className="mr-5 shadow transition duration-300 appearance-none border dark:border-inputfield-main border-black focus:border-inputfield-focus focus:outline-none disabled:border-inputfield-disabled rounded w-full py-2 px-3 text-sm text-white dark:placeholder-white placeholder-black leading-tight dark:bg-secondary-dark bg-[#d4d4d4]"
                                        placeholder="Search on logs data"
                                        title="Search on logs data"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                                <div className="custom-select-wrapper">
                                    <Select
                                        id="logOption"
                                        className="text-white"
                                        options={logOption}
                                        value={[fileName]}
                                        onChange={handleFileNameChange}
                                        placeholder='Select a Category'
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row items-center max-lg:w-full max-lg:justify-start">
                                <InputDateRange
                                    value={selectedDateRange}
                                    onChange={handleDateRangeFieldChange}
                                    setStudyFilterHeight={setStudyFilterHeight}
                                />
                            </div>
                            <div className="flex flex-row items-center gap-4 max-lg:w-full max-lg:justify-start mt-2">
                                <Button className="lg:ml-5 py-0.5 px-8" onClick={handleFetchLogs}>
                                    <BsSearch className="h-4 w-4 text-[#cacbcc] dark:text-[#cacbcc]" />
                                </Button>

                                <Button className="ml-3 py-0.5 px-8" onClick={handleReset}>
                                    <GrPowerReset className="h-4 w-4 text-[#cacbcc] dark:text-[#cacbcc]" />
                                </Button>

                                <Button className="ml-3 py-0.5 px-8" onClick={handleExport}>
                                    <FaFileDownload className="h-4 w-4 text-[#cacbcc] dark:text-[#cacbcc]" />
                                </Button>
                            </div>
                        </div>

                    </div>

                    <div className="mt-4">
                        {fileName === 'LogInLogs' ? (
                            <EventLogs servicesManager={servicesManager} />
                        ) : fileName === 'StudyLogs' ? (
                            <LogList2 onSelectLog={setSelectedLog} searchTerm={searchTerm} servicesManager={servicesManager} />
                        ) : (
                            <LogList onSelectLog={setSelectedLog} searchTerm={searchTerm} servicesManager={servicesManager} />
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LogViewer;
