const worklistFilter = [
    { label: 'Worklist', value: 'Signed' },
    { label: 'Worklist', value: 'Pending' },
    { label: 'Worklist', value: 'Addendum' },
    { label: 'Worklist', value: 'Read' },
    { label: 'Worklist', value: 'Final' },
    { label: 'Worklist', value: 'All' },
]

export default worklistFilter
