import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { fetchEventsPagination } from '../../requestHandler';
import Typography from '../Typography';

const EventLogs = ({ servicesManager }) => {
    const [eventLogs, setEventLogs] = useState([]);
    const [loading, setLoading] = useState(false);

    const { userAuthenticationService } = servicesManager.services;
    const user = userAuthenticationService.getUser();

    const fetchAndSetEvents = useCallback(async () => {
        setLoading(true);
        try {
            const data = await fetchEventsPagination(user.access_token); // Fetch all events
            setEventLogs(data); // Set the events data directly
        } catch (error) {
            console.error('Error fetching events:', error);
        } finally {
            setLoading(false);
        }
    }, [user.access_token]);

    useEffect(() => {
        fetchAndSetEvents();
    }, [fetchAndSetEvents]);

    // Filter the events if the user has the 'RadiologyGroupAdmin' role
    const filteredEvents = user?.profile?.roleType?.includes('RadiologyGroupAdmin')
        ? eventLogs.filter((log) =>
            log.radiologyGroup?.some((group) => user?.profile?.radiologyGroup?.includes(group))
        )
        : eventLogs;

    return (
        <section>
            <div
                className="max-h-[500px] overflow-y-auto telerapp-scrollbar"
                style={{
                    overflowX: 'auto',
                    maxHeight: 'calc(100vh - 290px)',
                }}
            >
                {loading ? (
                    <div className="text-center">Loading...</div>
                ) : (
                    <>
                        <table className="container m-auto mt-2 w-full table-auto text-white">
                            <thead className="bg-[#d1d1d1] dark:bg-primary-dark dark:border-primary-main border-secondary-dark border sticky top-0 right-0 left-0">
                                <tr className="max-sm:text-base">
                                    <th className="p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black">
                                        Username
                                    </th>
                                    <th className="p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black">
                                        IP Address
                                    </th>
                                    <th className="p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black">
                                        Timestamp
                                    </th>
                                    <th className="p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black">
                                        Event Type
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="mt-1">
                                {filteredEvents.length > 0 ? (
                                    filteredEvents.map((log, index) => (
                                        <tr
                                            key={index}
                                            className="bg-secondary-light dark:bg-secondary-dark dark:border-primary-main border-secondary-dark hover:border-secondary-dark dark:hover:bg-[#414141] hover:bg-primary-light cursor-pointer border text-center transition duration-300 max-sm:text-sm"
                                        >
                                            <td className="p-2 dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black">
                                                {log.username}
                                            </td>
                                            <td className="p-2 dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black">
                                                {log.ipAddress}
                                            </td>
                                            <td className="p-2 dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black">
                                                {moment(log.timestamp).format('MM/DD/YYYY hh:mm:ss A')}
                                            </td>
                                            <td className="p-2 dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black">
                                                {log.type}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <div className="h-full flex justify-center items-center bg-[#d1d1d1] dark:bg-primary-dark dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black p-4 mt-2 max-sm:text-[16px] max-[375px]:text-[13px]">
                                        No events found
                                    </div>
                                )}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
            <div className="flex justify-between items-center mt-4">
                <Typography className="dark:text-white text-black text-base opacity-60 max-sm:text-sm">
                    Total Rows: {filteredEvents.length}
                </Typography>
            </div>
        </section>
    );
};

export default EventLogs;
