import React, { useState } from 'react';
import { useLogViewer } from '../../contextProviders/LogViewerProvider'; // Adjust path as needed
import Typography from '../Typography';
import LegacyButtonGroup from '../LegacyButtonGroup';
import LegacyButton from '../LegacyButton';

const LogList = ({ servicesManager, onSelectLog, searchTerm }) => {
    const { logs } = useLogViewer();
    const { userAuthenticationService } = servicesManager.services;
    const user = userAuthenticationService.getUser();
    const [currentPage, setCurrentPage] = useState(1);
    const logsPerPage = 50;

    let logEntries = [];

    // Helper function to format the date and time to India/Mumbai timezone
    const formatDateTime = (timestamp) => {
        if (!timestamp) return { date: 'N/A', time: 'N/A' };
        const dateTime = new Date(timestamp);
        const options = {
            timeZone: 'Asia/Kolkata',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        };

        const formatted = new Intl.DateTimeFormat('en-IN', options).formatToParts(dateTime);
        const date = `${formatted[4].value}-${formatted[2].value}-${formatted[0].value}`;
        const time = `${formatted[6].value}:${formatted[8].value}:${formatted[10].value} ${formatted[12].value}`;
        return { date, time };
    };

    // Helper function to parse log lines
    const parseLogs = (logContent) => {
        return logContent
            .split('\n') // Split by lines
            .filter((line) => line.trim() !== '') // Remove empty lines
            .map((line) => {
                try {
                    const log = JSON.parse(line);
                    const dateTime = formatDateTime(log.timestamp);
                    return {
                        UID: log.UID || 'N/A',
                        accession: log.accession || 'N/A',
                        patientId: log.patientID || 'N/A',
                        activity: log.action || 'N/A',
                        user: log.userName || 'Unknown User',
                        institutionName: log.institutionName || 'N/A',
                        ...dateTime,
                    };
                } catch (error) {
                    console.error('Error parsing log line:', line, error);
                    return null;
                }
            })
            .filter((entry) => entry !== null); // Remove invalid entries
    };

    // Process log data from response
    if (logs?.logs?.length) {
        logs.logs.forEach((log) => {
            if (log.logContent) {
                logEntries = [...logEntries, ...parseLogs(log.logContent)];
            }
        });
    }

    // Search Term Filtering
    const filteredLogs = logEntries
        .filter((log) =>
            Object.values(log).some((value) =>
                String(value).toLowerCase().includes(searchTerm.toLowerCase())
            )
        )
        .reverse();

    // Role-based Filtering for RadiologyGroupAdmin
    const filteredLogsByInstitution = user?.profile?.roleType?.includes('RadiologyGroupAdmin')
        ? filteredLogs.filter(
            (log) =>
                (log.institutionName || '').replace(/[- ]/g, '') ===
                (user?.profile?.radiologyGroup || '').replace(/[- ]/g, '')
        )
        : filteredLogs;

    // Pagination
    const totalPages = Math.ceil(filteredLogsByInstitution.length / logsPerPage);
    const currentLogs = filteredLogsByInstitution.slice(
        (currentPage - 1) * logsPerPage,
        currentPage * logsPerPage
    );

    const handlePageChange = (direction) => {
        setCurrentPage((prev) =>
            direction === 'next' ? Math.min(prev + 1, totalPages) : Math.max(prev - 1, 1)
        );
    };

    if (!currentLogs.length) {
        return <p>No logs available.</p>;
    }

    return (
        <div>
            {/* Table View */}
            <div
                className="max-h-[500px] overflow-y-auto telerapp-scrollbar"
                style={{ overflowX: 'auto', maxHeight: 'calc(100vh - 290px)' }}
            >
                <table className="container m-auto mt-2 w-full table-auto dark:text-white text-black">
                    <thead className="bg-[#d1d1d1] dark:bg-primary-dark border-secondary-dark border sticky top-[-10px] z-2">
                        <tr className="max-sm:text-base">
                            {['Date', 'Time', 'UID', 'Accession', 'Patient ID', 'Activity', 'User', 'Institution'].map(
                                (header) => (
                                    <th key={header} className="p-2 border dark:text-white text-black">
                                        {header}
                                    </th>
                                )
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {currentLogs.map((log, index) => (
                            <tr
                                key={index}
                                className="hover:bg-primary-light cursor-pointer border text-center transition duration-300"
                                onClick={() => onSelectLog(log)}
                            >
                                <td className="p-2 border">{log.date}</td>
                                <td className="p-2 border">{log.time}</td>
                                <td className="p-2 border">{log.UID}</td>
                                <td className="p-2 border">{log.accession}</td>
                                <td className="p-2 border">{log.patientId}</td>
                                <td className="p-2 border">{log.activity}</td>
                                <td className="p-2 border">{log.user}</td>
                                <td className="p-2 border">{log.institutionName}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Pagination */}
            <div className="flex justify-between items-center mt-4">
                <Typography className="text-base opacity-60">
                    Total Rows: {filteredLogsByInstitution.length}
                </Typography>
                <div className="flex items-center">
                    <Typography className="mr-4 text-base opacity-60">
                        Page {currentPage} of {totalPages}
                    </Typography>
                    <LegacyButtonGroup>
                        <LegacyButton onClick={() => handlePageChange('prev')} disabled={currentPage <= 1}>
                            {'< Back'}
                        </LegacyButton>
                        <LegacyButton onClick={() => handlePageChange('next')} disabled={currentPage >= totalPages}>
                            {'Next >'}
                        </LegacyButton>
                    </LegacyButtonGroup>
                </div>
            </div>
        </div>
    );
};

export default LogList;
