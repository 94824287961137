import React, { useState, useEffect, useRef } from 'react';
import { IoAttach, IoMenu, IoSend } from 'react-icons/io5';
import { io } from 'socket.io-client';
import TypingIndicator from './TypingIndicator';
import { FaFilePdf } from 'react-icons/fa';
import { MdOutlineFileDownload } from 'react-icons/md';
import { GrView } from 'react-icons/gr';
import PreviewPdf from '../PreviewPdf';
import Tooltip from '../Tooltip';
import { RxCross2 } from 'react-icons/rx';
import { BsSearch } from 'react-icons/bs';

// const LiveChat = ({ usersList, users, setUsers, loginUserId, serverUser }) => {
const LiveChat = ({
  usersList,
  users,
  setUsers,
  loginUserId,
  access_token,
  fetchSessionsRadiologistUser,
  fetchUsers,
  serverUser,
  modelOpen,
  toggleChatPopup,
  fetchUnreadUserMessages,
  uploadVideoAttchmnet,
  fetchMarkAsRead,
}) => {
  const [messages, setMessages] = useState({});
  const [inputValue, setInputValue] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [unreadCounts, setUnreadCounts] = useState({});
  const [selectedChat, setSelectedChat] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const socket = useRef(null);
  const [typing, setTyping] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [session, setSession] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const messagesEndRef = useRef(null); // Create a ref for the last message
  const userId = localStorage.getItem('loginUserId');

  const allUsers = serverUser && serverUser?.map(data => data.receiverId);

  const fetchUnreadMessages = async () => {
    const response = await fetchUnreadUserMessages(userId, access_token);
    setUnreadMessages(response);
  };

  useEffect(() => {
    fetchUnreadMessages();
  }, [userId]);

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_BACKEND_API_ENDPOINT, {
      path: '/socket.io',
      transports: ['websocket'],
      secure: true,
      query: { userId },
    });

    socket.current.on('connect', () => setIsConnected(true));
    socket.current.on('disconnect', () => setIsConnected(false));

    setMessages(prevMessages => {
      // Ensure no messages are present initially
      return {};
    });
    // Listen for incoming chat messages
    socket.current.on('chat message', msg => {
      fetchUnreadMessages();
      fetchUsers();
      const {
        content,
        senderId,
        receiverId,
        timestamp,
        attachment,
        attachment_name,
        attachment_type,
        attachment_size,
      } = msg;

      if (
        (senderId === loginUserId && receiverId === selectedUser?.id) ||
        (receiverId === loginUserId && senderId === selectedUser?.id)
      ) {
        setMessages(prevMessages => {
          const userMessages = prevMessages[selectedUser?.id] || [];
          // Check for duplicates (optional, depending on your data structure)
          const isDuplicate = userMessages.some(
            message => message.timestamp === timestamp && message.content === content
          );
          if (isDuplicate) return prevMessages;
          const updatedMessages = [
            ...userMessages,
            {
              content,
              senderId,
              receiverId,
              timestamp,
              attachment,
              attachment_name,
              attachment_type,
              attachment_size,
            },
          ];

          // Only update the selected user's messages
          return {
            ...prevMessages,
            [selectedUser?.id]: updatedMessages,
          };
        });
      }
    });

    socket.current.on('typing', ({ senderId: userId }) => {
      if (userId === selectedUser?.id) {
        setIsTyping(true);
        setTimeout(() => setIsTyping(false), 5000);
      }
    });

    return () => {
      socket.current.off('connect');
      // socket.current.off('disconnect');
      socket.current.off('chat message');
      socket.current.disconnect();
    };
  }, [userId, selectedUser, loginUserId]);

  useEffect(() => {
    socket.current.on('receiveMessage', msg => {
      const { senderId, receiverId } = msg;

      setMessages(prev => ({
        ...prev,
        [senderId]: [...(prev[senderId] || []), msg],
      }));

      if (selectedChat?.id !== senderId && receiverId === userId) {
        setUnreadCounts(prev => ({
          ...prev,
          [senderId]: (prev[senderId] || 0) + 1,
        }));
      }
    });

    return () => {
      socket.current.off('receiveMessage');
    };
  }, [selectedChat, userId]);

  const handleChatOpen = async user => {
    setSelectedChat(user);
    setUnreadCounts(prev => ({
      ...prev,
      [user.id]: 0,
    }));
    fetchUnreadMessages();
    try {
      const data = {
        userId: userId,
        senderId: user.receiverId,
      };
      await fetchMarkAsRead(data);
    } catch (error) {
      console.error('Error marking chat as read:', error);
    }
  };

  // useEffect(() => {
  //   const fetchMessages = async () => {
  //     if (selectedUser) {
  //       try {
  //         // Simulate API call to fetch the last 7 messages for the selected user
  //         const res = await fetch(
  //           `http://localhost:4000/api/messages?userId=${selectedUser.id}&limit=7`
  //         );
  //         const data = await res.json();

  //         setMessages(prevMessages => ({
  //           ...prevMessages,
  //           [selectedUser.id]: data.messages,
  //         }));

  //         // If less than 7 messages were returned, disable further loading
  //         if (data.messages.length < 7) {
  //           setHasMoreMessages(false);
  //         } else {
  //           setHasMoreMessages(true);
  //         }
  //       } catch (error) {
  //         console.error('Error fetching messages:', error);
  //       }
  //     }
  //   };

  //   fetchMessages();
  // }, [selectedUser]);

  // Fetch older messages when scrolling to the top
  // const fetchOlderMessages = async () => {
  //   if (!hasMoreMessages || !selectedUser) return;

  //   const userMessages = messages[selectedUser.id] || [];
  //   const lastMessage = userMessages[0]; // The first message in the current list

  //   try {
  //     // Simulate API call to fetch older messages
  //     const res = await fetch(
  //       `http://localhost:4000/api/messages?userId=${selectedUser.id}&before=${lastMessage.timestamp}&limit=7`
  //     );
  //     const data = await res.json();

  //     setMessages(prevMessages => ({
  //       ...prevMessages,
  //       [selectedUser.id]: [...data.messages, ...userMessages],
  //     }));

  //     // If less than 7 messages were returned, disable further loading
  //     setHasMoreMessages(data.messages.length >= 7);
  //   } catch (error) {
  //     console.error('Error fetching older messages:', error);
  //     // Add an error state or feedback for the user if needed
  //   }
  // };

  useEffect(() => {
    // Scroll to the bottom when messages change or when a user is selected
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'instant' });
    }
  }, [messages, selectedUser, isTyping]);

  const sendMessage = async e => {
    e.preventDefault();
    let videoUrl = '';
    if (attachment?.type === 'video/mp4') {
      videoUrl = await uploadVideoAttchmnet(attachment);
    }

    if ((inputValue.trim() !== '' || attachment) && selectedUser) {
      const msg = {
        id: loginUserId,
        content: inputValue,
        receiverId: selectedUser?.id,
        senderId: loginUserId,
        timestamp: new Date().toISOString(),
      };
      const attach = attachment
        ? {
            data: attachment,
            name: attachment?.name,
            type: attachment?.type,
            size: attachment?.size,
          }
        : null;
      const attachVideo = videoUrl
        ? {
            data: videoUrl,
          }
        : null;

      // Emit message to the server with a callback to handle the response
      socket.current.emit('chat message', msg, selectedUser?.id, attach, attachVideo, response => {
        if (response.success) {
          // Update the messages in the local state
          setMessages(prevMessages => {
            const userMessages = prevMessages[selectedUser.id] || [];
            return {
              ...prevMessages,
              [selectedUser?.id]: [...userMessages, response],
            };
          });

          // Move the selected user to the top of the users list
          setUsers(prevUsers => {
            const updatedUsers = [...prevUsers];
            const index = updatedUsers.findIndex(user => user?.id === selectedUser?.id);
            if (index !== -1) {
              const [user] = updatedUsers.splice(index, 1);
              updatedUsers.unshift(user);
            }
            return updatedUsers;
          });
        } else {
          console.error('Error storing the message:', response?.error);
          alert('Failed to send the message. Please try again.');
        }
      });

      // Clear the input field
      setInputValue('');
      setAttachment(null);
    }
  };

  useEffect(() => {
    fetchSessionsRadiologistUser(access_token, allUsers)
      .then(data => setSession(data))
      .catch(error => console.error('Error fetching radiology user session:', error));
  }, [access_token]);

  const onSelectUsers = id => {
    const data = users?.find(i => i.id === id);
    setMessages({});
    setInputValue('');
    setAttachment(null);
    setSelectedUser(data);
    fetchUnreadMessages();
  };

  const fetchOlderMessages = () => {
    if (!selectedUser?.id || loading || !hasMore) return;

    setLoading(true);
    socket.current.emit('fetch older messages', {
      userId: loginUserId,
      selectedUserId: selectedUser?.id,
      offset: messages[selectedUser?.id]?.length || 0,
    });

    socket.current.on('older messages', olderMessages => {
      if (olderMessages.length === 0) {
        setHasMore(false);
      } else {
        setMessages(prevMessages => ({
          ...prevMessages,
          [selectedUser?.id]: [...olderMessages, ...(prevMessages[selectedUser?.id] || [])],
        }));
      }
      setLoading(false);
    });
  };

  const handleDownload = (msg, msgname) => {
    fetch(msg)
      .then(response => response.blob()) // Fetch the PDF as a Blob
      .then(blob => {
        const link = document.createElement('a'); // Create a temporary <a> element
        link.href = URL.createObjectURL(blob); // Create an object URL from the blob
        link.download = msgname; // Set the filename for the downloaded file
        link.click(); // Trigger the download
      })
      .catch(error => {
        console.error('Error downloading PDF:', error);
      });
  };
  const transformUrl = url => {
    if (url.includes('prod-telerapp-attachments.s3.us-east-2.amazonaws.com')) {
      return url.replace(
        'https://prod-telerapp-attachments.s3.us-east-2.amazonaws.com',
        'https://documents.telerapp.com'
      );
    }
    return url;
  };

  const modelShow = url => {
    modelOpen({
      content: PreviewPdf,
      title: 'Attachment Viewer',
      contentProps: { previewUrl: transformUrl(url) },
    });
  };

  const handleAttachment = e => {
    setAttachment(null);
    setAttachment(e.target.files[0]);
  };

  const handleMessageType = e => {
    setAttachment(null);
    const value = e.target.value;
    setInputValue(value);
  };

  const handleTyping = () => {
    setTyping(true);
    socket.current.emit('typing', { senderId: userId, receiverId: selectedUser?.id });
  };

  const sessionData = session.find(sessionData =>
    sessionData.some(data => data.userId === selectedUser?.id)
  );
  const isActive = sessionData ? true : false; // Determine if the user is active
  const [isUserListVisible, setIsUserListVisible] = useState(false);

  const filteredUsers = serverUser
    ?.filter(user => user?.receiverId !== loginUserId) // Exclude logged-in user
    .filter(user => user?.receiverName.toLowerCase().includes(searchQuery.toLowerCase())); // Search filter

  return (
    <div
      className="dark:bg-primary-dark bg-primary-light dark:border-primary-main border-secondary-dark absolute bottom-[40px] right-0 flex border max-md:w-full xl:w-[40%] lg:w-[60%] md:w-[75%] md:rounded-t-[10px] md:rounded-b-[10px]"
      style={{
        height: '65vh',
        fontFamily: 'Arial, sans-serif',
        // borderRadius: '10px 0 0 10px',
        zIndex: '11',
      }}
    >
      {/* User List */}
      <div
        // className="dark:bg-secondary-dark bg-secondary-light flex flex-col py-5 pl-5 text-black dark:text-white"
        className={`dark:bg-secondary-dark bg-secondary-light fixed flex flex-col py-3 pl-3 text-black transition-all duration-300 dark:text-white sm:static sm:py-5 sm:pl-5 ${
          isUserListVisible ? 'translate-x-0' : '-translate-x-full'
        } overflow-y-auto max-md:w-[50%] sm:translate-x-0 md:w-[65%] md:rounded-t-[10px] md:rounded-b-[10px]`}
        style={{
          height: '65vh',
          boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
          // borderRadius: '10px 0 0 10px',
          zIndex: 10,
        }}
      >
        {/* Search Input */}
        <div className="relative mr-auto w-[90%]">
          <BsSearch className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 transform text-black dark:text-white" />
          <input
            type="text"
            placeholder="Search Users..."
            className="dark:bg-primary-dark bg-primary-light border-secondary-dark dark:border-primary-main focus:border-inputfield-focus disabled:border-inputfield-disabled w-full appearance-none rounded border py-2 pr-3 pl-9 text-sm leading-tight text-black placeholder-black shadow transition duration-300 focus:outline-none dark:text-white dark:placeholder-white"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </div>
        {/* <h3 className="border-b-secondary-dark dark:border-b-primary-main sm:mr-5 mr-3 mb-5 border-b text-center text-black dark:text-white">
          Users
        </h3> */}
        <ul
          style={{ listStyle: 'none', padding: 0, flex: 1, overflowY: 'auto' }}
          className="telerapp-scrollbar border-t-secondary-dark dark:border-t-primary-main mt-5 border-t"
        >
          {filteredUsers.length > 0 ? (
            filteredUsers.map(user => {
              const unreadPopup = unreadMessages.filter(msg => msg.sender_id === user?.receiverId);
              const sessionData = session.find(sessionData =>
                sessionData.some(data => data.userId === user.receiverId)
              );
              const isActive = sessionData ? true : false; // Determine if the user is active
              return (
                <li
                  key={user?.receiverId}
                  className={`mb-[10px] cursor-pointer p-[10px] ${selectedUser?.id === user?.receiverId ? 'bg-primary-light dark:bg-primary-dark' : 'transparent'} border-b-secondary-dark dark:border-b-primary-main text-ellipsis border-b`}
                  style={{
                    borderRadius: '10px',
                    transition: 'background-color 0.3s ease',
                    boxShadow:
                      selectedUser?.id === user?.receiverId
                        ? '0 4px 8px rgb(154 140 155 / 30%)'
                        : '',
                  }}
                  // onClick={() => onSelectUsers(user.receiverId)}
                  onClick={() => {
                    onSelectUsers(user?.receiverId);
                    handleChatOpen(user);
                    setIsUserListVisible(false); // Hide user list on smaller screens
                  }}
                >
                  <div className="flex items-center justify-between font-bold">
                    <div className="flex items-center font-bold max-[320px]:text-[12px]">
                      <div
                        className="dark:text-secondary-dark text-secondary-light bg-secondary-dark dark:bg-secondary-light relative mr-3 flex h-10 w-10 items-center justify-center p-1 text-[16px] font-bold max-[320px]:w-[40px]"
                        style={{
                          borderRadius: '50%',
                        }}
                      >
                        {user?.receiverName?.charAt(0).toUpperCase() || 'U'}

                        {/* Active/Offline indicator */}
                        <div
                          style={{
                            position: 'absolute',
                            bottom: '0',
                            right: '0',
                            width: '12px',
                            height: '12px',
                            borderRadius: '50%',
                            backgroundColor: isActive ? 'green' : 'black', // Green if active, red if offline
                            border: '1px solid white', // Border to make the status dot stand out
                          }}
                        />
                      </div>
                      {user?.receiverName}
                    </div>
                    {user?.receiverId !== selectedUser?.id && unreadPopup.length !== 0 && (
                      <span
                        className="flex h-[24px] w-[24px] items-center justify-center bg-green-900 text-sm font-bold text-white"
                        style={{
                          borderRadius: '50%',
                        }}
                      >
                        {unreadPopup.length !== 0 && unreadPopup.length}
                      </span>
                    )}
                  </div>
                </li>
              );
            })
          ) : (
            <li className="p-3 text-center text-gray-500 dark:text-gray-400">No users found</li>
          )}
        </ul>
      </div>

      {/* Chat Window */}
      <div className="flex w-full flex-col">
        <div className="dark:bg-secondary-dark bg-secondary-light border-secondary-dark dark:border-primary-main flex items-center justify-between border px-5 py-3 text-black dark:text-white">
          <div className="flex items-center justify-between">
            {/* Menu Button for small screens */}
            <button
              onClick={() => setIsUserListVisible(!isUserListVisible)}
              // className="absolute top-3 left-3 z-20 md:hidden"
              className="z-20 mr-[10px] sm:hidden"
            >
              <IoMenu className="text-2xl text-black dark:text-white" />
            </button>
            {selectedUser && (
              <div className="flex items-center max-[320px]:text-[12px]">
                <div
                  className="dark:text-secondary-dark text-secondary-light bg-secondary-dark dark:bg-secondary-light relative mr-3 flex h-10 w-10 items-center justify-center p-1 text-[16px] font-bold max-[320px]:w-[40px]"
                  style={{
                    borderRadius: '50%',
                  }}
                >
                  {(selectedUser && selectedUser?.name?.charAt(0).toUpperCase()) || 'U'}
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '0',
                      right: '0',
                      width: '12px',
                      height: '12px',
                      borderRadius: '50%',
                      backgroundColor: isActive ? 'green' : 'black', // Green if active, red if offline
                      border: '1px solid white', // Border to make the status dot stand out
                    }}
                  />
                </div>
                {selectedUser && selectedUser?.name}
              </div>
            )}
          </div>
          <RxCross2
            onClick={toggleChatPopup}
            className="cursor-pointer text-xl text-black dark:text-white"
          />
        </div>
        {selectedUser ? (
          <>
            <ul
              className="telerapp-scrollbar px-5 pt-5"
              style={{
                listStyle: 'none',
                flex: 1,
                overflowY: 'auto',
                margin: 0,
              }}
              onScroll={e => {
                if (e.target.scrollTop === 0) {
                  fetchOlderMessages();
                }
              }}
            >
              {messages[selectedUser?.id]?.map((msg, index) => {
                const isUserMessage = msg.senderId === loginUserId;
                return (
                  <li
                    key={index}
                    className={`my-[10px] p-3 ${isUserMessage ? 'text-start' : 'text-left'} dark:text-secondary-dark text-secondary-light bg-secondary-dark dark:bg-secondary-light`}
                    style={{
                      borderRadius: '15px',
                      maxWidth: '85%',
                      width: 'fit-content',
                      marginLeft: isUserMessage ? 'auto' : '0',
                      marginRight: isUserMessage ? '0' : 'auto',
                      // color: '#fff',
                      // backgroundColor: isUserMessage ? '#4caf50' : '#2196f3',
                      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    {msg?.content}
                    {/* Check if there's an attachment */}
                    {msg?.attachment && (
                      <div>
                        <div className="flex flex-col items-center justify-between">
                          <div className="flex items-center">
                            {/* <FaFilePdf className="mr-1 text-3xl text-red-700" /> */}
                            <div className="flex flex-col">
                              <span className="font-bold max-[320px]:text-[13px]">
                                {msg?.attachment_name || msg?.attachment?.name}
                              </span>
                              <span className="text-sm text-opacity-30">
                                {(
                                  (msg?.attachment_size || msg?.attachment?.size) /
                                  (1024 * 1024)
                                ).toFixed(2)}{' '}
                                MB
                              </span>
                            </div>
                          </div>
                          <div className="mt-2 flex w-full items-center justify-between">
                            {![
                              'application/vnd.ms-excel',
                              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            ].includes(msg?.attachment_type) && (
                              <span
                                className="dark:bg-primary-dark bg-primary-light hover:opacity-45 mr-3 flex w-[50%] cursor-pointer items-center justify-center rounded-xl p-2 transition-all"
                                onClick={() => modelShow(msg?.attachment)}
                              >
                                <Tooltip
                                  content={'View Attachment'}
                                  position="bottom"
                                  style={{ padding: '8px', fontWeight: 'normal' }}
                                >
                                  <GrView className="text-xl text-black dark:text-white" />
                                </Tooltip>
                              </span>
                            )}
                            <span
                              className="dark:bg-primary-dark bg-primary-light hover:opacity-45 flex w-[50%] cursor-pointer items-center justify-center rounded-xl p-2 transition-all"
                              onClick={() =>
                                handleDownload(
                                  msg?.attachment,
                                  msg?.attachment_name || msg?.attachment?.name
                                )
                              }
                            >
                              <Tooltip
                                content={'Download Attachment'}
                                position="bottom"
                                style={{ padding: '8px', fontWeight: 'normal' }}
                              >
                                <MdOutlineFileDownload className="text-xl text-black dark:text-white" />
                              </Tooltip>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                );
              })}
              {isTyping && <TypingIndicator isTyping={isTyping} />}
              <div ref={messagesEndRef} /> {/* The ref to the last message */}
            </ul>

            <form
              className="dark:bg-secondary-dark bg-secondary-light border-secondary-dark dark:border-primary-main flex border p-4 text-black dark:text-white"
              onSubmit={sendMessage}
            >
              <div className="relative w-full">
                <input
                  type="text"
                  value={attachment ? attachment.name : inputValue}
                  onChange={e => handleMessageType(e)}
                  placeholder="Type your message..."
                  disabled={!selectedUser}
                  className="dark:bg-primary-dark bg-primary-light border-secondary-dark dark:border-primary-main focus:border-inputfield-focus disabled:border-inputfield-disabled placeholder-inputfield-placeholder mr-2 w-full appearance-none rounded-lg border p-2 py-2 px-3 text-[16px] leading-tight text-black shadow transition duration-300 placeholder:text-black focus:outline-none dark:text-white dark:placeholder:text-white"
                  style={{
                    flex: 1,
                    border: '1px solid #ddd',
                    boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
                  }}
                  onKeyDown={handleTyping}
                />
                {/* Attachment Icon */}
                <label
                  htmlFor="attachment-upload"
                  className="absolute top-[45%] right-2 -translate-y-1/2 transform cursor-pointer"
                >
                  <IoAttach className="text-xl" />
                </label>
                <input
                  id="attachment-upload"
                  type="file"
                  className="hidden"
                  accept="image/*, video/mp4, application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet "
                  onChange={e => handleAttachment(e)} // Function to handle file upload
                />
              </div>
              <button
                type="submit"
                className="py-3 pl-3 text-xl text-black hover:opacity-60 dark:text-white"
                disabled={!selectedUser}
              >
                <IoSend />
              </button>
            </form>
          </>
        ) : (
          <div className="flex h-full w-full items-center justify-center text-xl font-semibold dark:text-white text-black">
            Select a user to start messaging
          </div>
        )}
      </div>
    </div>
  );
};

export default LiveChat;
