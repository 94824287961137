export const fetchStudyCount = async (radiologyGroupName) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/studyCount`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ radiologyGroupName }),
    });
    return response.json();
};

// export const fetchStudyCount = async () => {
//     return await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/studyCount`)
//         .then((response) => response.json())
// }


export const fetchRadiologistActiveListData = async (startDate, endDate) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/getRadiologistDataList?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error(`Error fetching radiologist data: ${response.statusText}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching radiologist data:', error);
        throw error;
    }
};


export const fetchReportsByPriorityAndDateRange = async (startDate, endDate, radiologyGroupName) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/reportsByPriorityAndDateRange`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ startDate, endDate, radiologyGroupName }),
    })
    return response.json();
}


export const fetchReportsByModalityAndDateRange = async (startDate, endDate, radiologyGroupName) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/reportsByModalityAndDateRange`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ startDate, endDate, radiologyGroupName }),
    });
    return response.json();
};

export const fetchReportsByDocumentStatusAndDateRange = async (startDate, endDate, radiologyGroupName) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/reportsByDocumentStatusAndDateRange`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ startDate, endDate, radiologyGroupName }),
    });
    return response.json();
};

export const fetchApprovedReportsByModalityAndDateRange = async (startDate, endDate, radiologyGroupName) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/approvedReportsByModalityAndDateRange`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ startDate, endDate, radiologyGroupName }),
    });
    return response.json();
};

export const fetchReportsByDateAnalysis = async (startDate, endDate, radiologyGroupName) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/getReportsByDateAnalysis`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ startDate, endDate, radiologyGroupName }),
    });
    return response.json();
};

export const fetchReportsByModalityAndInstitute = async (startDate, endDate, radiologyGroupName) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/getReportsByModalityAndInstitute`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ startDate, endDate, radiologyGroupName }),
    });
    return response.json();
};

export const fetchStudyStatusByPriorityAndDocumentStatus = async (startDate, endDate, radiologyGroupName) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/getstudystatus`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ startDate, endDate, radiologyGroupName }),
    });
    return response.json();
};



export const fetchReportsByWeekAnlyt = async (startDate, endDate, radiologyGroupName) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/getReportsByWeekAnlyt`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ startDate, endDate, radiologyGroupName }),
    });
    return response.json();
}

export const fetchReportsByUserAndModality = async (startDate, endDate, radiologyGroupName) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/getReportsByUserAndModality`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ startDate, endDate, radiologyGroupName }),
    });
    return response.json();
}

export const fetchUserWeeklyPerformance = async (username, startDate, endDate) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/getUserWeeklyPerformance`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, startDate, endDate }),
    });
    return response.json();
}

export const fetchModalityWiseAverageTAT = async (startDate, endDate, radiologyGroupName) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/getModalityWiseAverageTAT`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ startDate, endDate, radiologyGroupName }),
    });
    return response.json();
}

export const fetchInstituteWeeklyPerformanceData = async (institutionName, startDate, endDate) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/getInstitutionWeeklyPerformance`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ institutionName, startDate, endDate }),
    });
    return response.json();
}
