import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Select from '../Select';
import Typography from '../Typography';
import Button from '../Button';

import { Formik, Form, Field } from 'formik';
import Label from '../Label';
import { useSnackbar } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import { ToastContainer, toast } from 'react-toastify';

const RadiologyTATSetting = ({ onCancel, createRadiologyGroupSetting, updateRadiologyGroupSetting, TATSetting, fetchRadiologyGroupSetting, setRadiologyGroupSetting, radiologyGroupList, studies }) => {
  const { show } = useSnackbar();
  const { t } = useTranslation('UserPreferencesModal');

  const fieldData = TATSetting ? JSON.parse(TATSetting?.field[0]) : null;

  let tatCounterTime = {};

  studies?.forEach(item => {
    tatCounterTime[item.studyInstanceUid] = item.studyInstanceUid;
  });

  const initialValues = {
    groupName: fieldData?.radiologyGroupNameTAT || '',
    tatHours: fieldData?.tatHours || '',
    tatMinutes: `${fieldData?.tatMinutes} minutes` || '',
  };


  const [selectedGroupName, setSelectedGroupName] = useState(fieldData?.radiologyGroupNameTAT || null);
  const [selectedGroupTATHours, setSelectedGroupTATHours] = useState(`${fieldData?.tatHours} Hour` || null);
  const [selectedGroupTATMinutes, setSelectedGroupTATMinutes] = useState(`${fieldData?.tatMinutes} minutes` || null);

  useEffect(() => {
    if (TATSetting && TATSetting?.field && TATSetting?.field?.length > 0) {
      const fieldData = JSON.parse(TATSetting.field[0]);

      setSelectedGroupTATHours(fieldData.tatHours);
      setSelectedGroupTATMinutes(fieldData.tatMinutes);
    }
  }, [TATSetting]);

  const onSubmitHandler = async (values, { setSubmitting }) => {
    try {

      if (TATSetting) {
        const data = {
          field: [
            JSON.stringify({
              radiologyGroupNameTAT: selectedGroupName,
              tatHours: selectedGroupTATHours,
              tatMinutes: selectedGroupTATMinutes,
            })
          ],
        };

        const response = await updateRadiologyGroupSetting(TATSetting.id, data)
        if (response.status) {
          fetchRadiologyGroupSetting()
            .then(data => setRadiologyGroupSetting(data))
            .catch(error => console.error('Error fetching radiology groups setting :', error));
          Object.keys(tatCounterTime).forEach(key => {
            localStorage.removeItem(key);
          });
          toast.success('Successfully Saved Radiology Group Settings');
          // show({
          //   title: "Success!",
          //   message: "Successfully Update Radiology Group Setting",
          //   type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
          //   position: "topRight"
          // });
          onCancel()
        }
      } else {
        const data = {
          name: 'RadiologyGroupTATSetting',
          field: [
            JSON.stringify({
              radiologyGroupNameTAT: selectedGroupName,
              tatHours: selectedGroupTATHours,
              tatMinutes: selectedGroupTATMinutes,
            })
          ],
        };

        const response = await createRadiologyGroupSetting(data)
        if (response.status) {
          fetchRadiologyGroupSetting()
            .then(data => setRadiologyGroupSetting(data))
            .catch(error => console.error('Error fetching radiology groups setting :', error));
          toast.success('Successfully Saved Radiology Group Settings');
          // show({
          //   title: "Success!",
          //   message: "Successfully Saved Radiology Group Setting ",
          //   type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
          //   position: "topRight"
          // });
          onCancel()
        }
      }
      setSubmitting(false);
    } catch (error) {
      console.error('Error:', error);
      toast.warning('Please Refresh and Try Again');
      setSubmitting(false);
    }
  };

  const optionGroup = radiologyGroupList.map(group => ({
    label: group.name,
    value: group.name
  }));

  const optionTATHours = [
    { label: '1 Hour', value: '1' },
    { label: '2 Hour', value: '2' },
    { label: '3 Hour', value: '3' },
    { label: '4 Hour', value: '4' },
    { label: '5 Hour', value: '5' },
    { label: '6 Hour', value: '6' },
    { label: '7 Hour', value: '7' },
    { label: '8 Hour', value: '8' },
    { label: '9 Hour', value: '9' },
    { label: '10 Hour', value: '10' }
  ];

  const optionTATMinutes = [
    { label: '0', value: '0' },
    { label: '5 minutes', value: '5' },
    { label: '10 minutes', value: '10' },
    { label: '15 minutes', value: '15' },
    { label: '25 minutes', value: '25' },
    { label: '30 minutes', value: '30' },
    { label: '35 minutes', value: '35' },
    { label: '40 minutes', value: '40' },
    { label: '45 minutes', value: '45' },
    { label: '50 minutes', value: '50' },
    { label: '55 minutes', value: '55' },
    { label: '60 minutes', value: '60' },
  ];

  const handleSelectionGroupNameChange = (selected) => {
    setSelectedGroupName(selected.value);
  };
  const handleSelectionTATHoursChange = (selected) => {
    setSelectedGroupTATHours(selected.value);
  };
  const handleSelectionTATMinutesChange = (selected) => {
    setSelectedGroupTATMinutes(selected.value);
  };


  const Section = ({ title, children }) => (
    <>
      <div className="mb-2 border-b-2 border-b-secondary-dark dark:border-b-primary-main">
        <Typography
          variant="inherit"
          color="primaryLight"
          className="flex pb-2 text-[16px] font-semibold !leading-[1.2] dark:text-white text-black"
        >
          {title}
        </Typography>
      </div>
      <div className=" mb-8">{children}</div>
    </>
  );

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
      >
        {({ isSubmitting }) => (
          <Form>
            <Section title={t('Radiology Group TAT Setting')}>
              <div className='flex items-end'>
                <div className='grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4'>
                  <div className='md:w-72 w-full'>
                    <Label className="my-2 block dark:text-white text-black">Radiology Group Name </Label>
                    <Field
                      className=""
                      name="groupName"
                      placeholder={'Select Radiology Group Name'}
                      onChange={handleSelectionGroupNameChange}
                      as={Select}
                      options={optionGroup}
                      value={[selectedGroupName]}
                    />
                  </div>
                  <div className='md:w-72 w-full'>
                    <Label className="my-2 block dark:text-white text-black">Radiology Group TAT (In Hours)</Label>
                    <Field
                      as={Select}
                      placeholder={'Select Radiology Group TAT (In Hours)'}
                      options={optionTATHours}
                      className=""
                      name="tatHours"
                      value={[selectedGroupTATHours]}
                      onChange={handleSelectionTATHoursChange}
                    />
                  </div>
                  <div className='md:w-72 w-full'>
                    <Label className="my-2 block dark:text-white text-black">Radiology Group TAT (In Minutes)</Label>
                    <Field
                      as={Select}
                      placeholder={'Select Radiology Group TAT (In Minutes)'}
                      options={optionTATMinutes}
                      className=""
                      name="tatMinutes"
                      value={[selectedGroupTATMinutes]}
                      onChange={handleSelectionTATMinutesChange}
                    />
                  </div>
                </div>
                <Button disabled={isSubmitting} className='ml-4'>
                  Save
                </Button>
                {/* </div> */}
              </div>
            </Section>
          </Form>
        )}
      </Formik>

    </>
  );
};

export default RadiologyTATSetting;
