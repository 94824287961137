import React from 'react';
import Button from '../Button';
import { RiDeleteBin6Fill } from "react-icons/ri";


const DocumentStatusModal = ({ hide, studyInstanceUid, handleSelectedReportStatusChange, institutionName, studyID }) => {

  const handleButtonClick = (reportStatus) => {
    handleSelectedReportStatusChange({ reportStatus, studyInstanceUid, institutionName, studyID });
    hide();
  };

  return (
    <div>
      <div className='flex justify-center mb-4'>Change the Report Status</div>
      <div className='flex justify-center mb-4 gap-3'>
        <Button onClick={() => handleButtonClick('Approved')} >Approve</Button>
        <Button onClick={() => handleButtonClick('Addendum')}>Addendum</Button>
        {/* <Button onClick={() => handleButtonClick('Delete')} className="cursor-pointer transition-all hover:text-red-400 text-xl text-[#ff0000] flex justify-center items-center"> <RiDeleteBin6Fill /></Button> */}
      </div>
    </div>
  );
};


export default DocumentStatusModal;
