import { fetchDefaultTemplates, createPatientReports, fetchPatientReports, generateReportPdf, updatePatientReports, generateReportWord, deletePatientReport, fetchReportSetting, createReportSetting, updateReportSetting, deleteReportSetting, createDefaultTemplates, deleteDefaultTemplates, updateDefaultTemplates } from './reportTemplateRequest'

import { fetchDocumentUpload, createDocument, updateDocument, deleteDocumentUrl, sendReportData, sendImageToSMS, sendImageToEmail, sendReportDataToWhastapp } from './documentUploadRequest';

import {
    fetchStudyCount, fetchReportsByPriorityAndDateRange, fetchReportsByModalityAndDateRange, fetchRadiologistActiveListData,
    fetchReportsByDocumentStatusAndDateRange,
    fetchApprovedReportsByModalityAndDateRange,
    fetchReportsByDateAnalysis,
    fetchReportsByModalityAndInstitute,
    fetchStudyStatusByPriorityAndDocumentStatus,
    fetchReportsByWeekAnlyt,
    fetchReportsByUserAndModality,
    fetchUserWeeklyPerformance,
    fetchModalityWiseAverageTAT,
    fetchInstituteWeeklyPerformanceData
} from './analyticsRequest';

import {
    fetchLogs,
    fetchDateRangeLogs,
    fetchEventsPagination
} from './logsRequest';

import { fetchDisplaySettingsOptions, createDisplaySettingsOptions } from "./displaySettingsRequest"

import { fetchWindowPresets, createWindowPresets, updateWindowPresets, deleteWindowPresets } from "./windowPresets"

export {
    fetchDefaultTemplates,
    createDefaultTemplates,
    createPatientReports,
    fetchPatientReports,
    generateReportPdf,
    updatePatientReports,
    generateReportWord,
    deletePatientReport,
    fetchReportSetting,
    createReportSetting,
    updateReportSetting,
    deleteReportSetting,
    deleteDefaultTemplates,
    updateDefaultTemplates,
    fetchDocumentUpload,
    createDocument,
    updateDocument,
    deleteDocumentUrl,
    sendReportData,
    sendImageToSMS,
    sendImageToEmail,
    sendReportDataToWhastapp,
    fetchStudyCount,
    fetchRadiologistActiveListData,
    fetchReportsByPriorityAndDateRange,
    fetchReportsByModalityAndDateRange,
    fetchReportsByDocumentStatusAndDateRange,
    fetchApprovedReportsByModalityAndDateRange,
    fetchReportsByDateAnalysis,
    fetchReportsByModalityAndInstitute,
    fetchStudyStatusByPriorityAndDocumentStatus,
    fetchReportsByWeekAnlyt,
    fetchReportsByUserAndModality,
    fetchUserWeeklyPerformance,
    fetchModalityWiseAverageTAT,
    fetchInstituteWeeklyPerformanceData,
    fetchLogs,
    fetchDateRangeLogs,
    fetchEventsPagination,
    fetchDisplaySettingsOptions,
    createDisplaySettingsOptions,
    fetchWindowPresets,
    deleteWindowPresets,
    updateWindowPresets,
    createWindowPresets
}
