import React, { useEffect, useState } from 'react';
import Typography from '../Typography';
import Button from '../Button';
import './ConnectivityStatus.css';
import { useUser, Dashboard, InputDateRange, LegacyButton, Icon, useRadiologyGroup, Header } from '@ohif/ui';
import moment from 'moment';
import { GoDotFill } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';
import { useAppConfig } from '@state';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { BsSearch } from 'react-icons/bs';
import Input from '../Input';

const ConnectivityStatus = ({ servicesManager, extensionManager, hotkeysManager, data: studies }) => {
  const { usersList, fetchSessionsRadiologistUser } = useUser();
  const { radiologyGroupList } = useRadiologyGroup();
  const { userAuthenticationService } = servicesManager.services;
  const user = userAuthenticationService.getUser();

  const [activeList, setActiveList] = useState([]);

  const [session, setSession] = useState([]);
  const [radiologistUserLists, setRadiologistUserLists] = useState(radiologyGroupList);
  const [selectedDateRange, setSelectedDateRange] = useState({ startDate: null, endDate: null });
  const [isFiltering, setIsFiltering] = useState(false);
  const [lastActiveTimes, setLastActiveTimes] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [studyFilterHeight, setStudyFilterHeight] = useState(false);

  const navigate = useNavigate();
  const [appConfig] = useAppConfig();

  const {
    token,
    userToken,
    setToken,
  } = useUser();

  useEffect(() => {
    const getToken = async () => {
      try {
        const data = {
          token: user.access_token,
        };
        const response = await userToken(data);
        setToken(response);
      } catch (error) {
        console.log(error);
      }
    };
    getToken();
  }, []);

  const onClickReturnButton = () => {
    const { pathname } = location;
    const dataSourceIdx = pathname.indexOf('/', 1);
    const query = new URLSearchParams(window.location.search);
    const configUrl = query.get('configUrl');

    const dataSourceName = pathname.substring(dataSourceIdx + 1);
    const existingDataSource = extensionManager.getDataSources(dataSourceName);

    const searchQuery = new URLSearchParams();
    if (dataSourceIdx !== -1 && existingDataSource) {
      searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
    }

    if (configUrl) {
      searchQuery.append('configUrl', configUrl);
    }

    navigate({
      pathname: '/',
      search: decodeURIComponent(searchQuery.toString()),
    });
  };

  const tableHeaders = {
    liveStatus: 'Live Status',
    groupName: 'Group Name',
    lastActive: 'Last Active Time'
  };

  const keys = Object.keys(tableHeaders);
  const values = Object.values(tableHeaders);

  const radiologistUser = usersList?.map(data => data.id);

  useEffect(() => {
    fetchSessionsRadiologistUser(user.access_token, radiologistUser)
      .then(data => setSession(data))
      .catch(error => console.error('Error fetching radiology user session:', error));
  }, [user.accessTokens, usersList]);

  useEffect(() => {
    if (!session || !usersList) return;

    const updatedList = usersList.map(userData => {
      const relevantSessions = session.map(sessionData =>
        sessionData && sessionData?.find(data => data.userId === userData.id)
      ).filter(Boolean); // Filter out undefined values

      let lastActiveTime = null;
      if (relevantSessions.length > 0) {
        lastActiveTime = relevantSessions[0].lastAccess;
      }

      if (relevantSessions.length > 0) {
        return {
          ...userData,
          lastActiveTime: moment(lastActiveTime).format('MMM-DD-YYYY hh:mm:ss A')
        };
      } else {
        return null;
      }
    }).filter(Boolean);

    setActiveList(updatedList);
  }, [session]);
  // const handleDateRangeFieldChange = ({ startDate, endDate }) => {
  //   setSelectedDateRange({ startDate, endDate });
  // };

  const handleClearFilters = () => {
    setSelectedDateRange({ startDate: null, endDate: null });
    setRadiologistUserLists(radiologyGroupList);
    setIsFiltering(false);
  };

  // const handleSearch = () => {
  //   const startTimestamp = moment(selectedDateRange.startDate).format('MM/DD/YYYY');
  //   const endTimestamp = moment(selectedDateRange.endDate).format('MM/DD/YYYY');
  //   const filteredUsers = radiologistUserLists.filter(user => {
  //     const userTimestamp = moment(user.createdTimestamp).format('MM/DD/YYYY');

  //     return userTimestamp >= startTimestamp && userTimestamp <= endTimestamp;
  //   });
  //   setIsFiltering(true);
  //   setRadiologistUserLists(filteredUsers);
  // };


  useEffect(() => {
    const newLastActiveTimes = {};

    radiologistUserLists.forEach(instance => {
      const lastActive = activeList
        .filter(sessionData => sessionData?.attributes?.radiologyGroup?.toString() === instance.name)
        .map(sessionData => sessionData?.lastActiveTime)
        .filter(time => time !== null);

      if (lastActive.length > 0) {
        newLastActiveTimes[instance.name] = lastActive.reduce((latest, current) => current > latest ? current : latest);
      } else {
        newLastActiveTimes[instance.name] = null;
      }
    });

    setLastActiveTimes(newLastActiveTimes);
  }, [radiologistUserLists, activeList]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredConnectivityLists = radiologistUserLists.filter(user =>
    user?.name && user?.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedConnectivityLists = [...filteredConnectivityLists].sort((a, b) => {
    const aHasSession = activeList.some(sessionData => sessionData?.attributes?.radiologyGroup?.toString() === a.name) ? 1 : 0;
    const bHasSession = activeList.some(sessionData => sessionData?.attributes?.radiologyGroup?.toString() === b.name) ? 1 : 0;

    return bHasSession - aHasSession; // Sort by session existence
  });

  return (
    <section
      className="relative w-full text-white"
      style={{}}
    >
      <Header
        servicesManager={servicesManager}
        hotkeysManager={hotkeysManager}
        sortedStudies={studies}
        isReturnEnabled={!!appConfig.showStudyList}
        onClickReturnButton={onClickReturnButton}
        WhiteLabeling={appConfig.whiteLabeling}
        loggedInUserDetails={user}
        loggedInUserTokenDetails={token}
        // headerOpen={true}
      />
      {/* <div className="absolute top-16 left-60 p-3 w-10/12"> */}
      <div className='max-sm:px-3'>
        <div className="container relative mx-auto flex w-full flex-col pt-5">
          <div className="mb-5 flex lg:flex-row flex-col justify-between max-lg:gap-2 ">
            <div className="flex min-w-[1px] shrink sm:flex-row flex-col sm:items-center gap-6 max-sm:gap-2 max-lg:w-full max-lg:justify-between">
              <Typography
                variant="h6"
                className="dark:text-white text-black max-sm:text-[16px]"
                component={'h6'}
              >
                Connectivity Status
              </Typography>
              <div className='lg:hidden gap-2'>
                {isFiltering && (
                  <LegacyButton
                    rounded="full"
                    variant="outlined"
                    color="primaryActive"
                    border="primaryActive"
                    className="sm:mx-8 max-sm:mb-3 max-sm:text-[16px] dark:text-white text-black"
                    startIcon={<Icon name="cancel" />}
                    onClick={handleClearFilters}
                  >
                    ClearFilters
                  </LegacyButton>
                )}
                {/* <input
                  type="text"
                  className="custom-input search-box-status"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                /> */}
                <div className="relative">
                  <div className="pointer-events-none absolute top-2 left-3 flex items-center">
                    <BsSearch className="h-4 w-4 dark:text-white text-black" />
                  </div>

                  <Formik
                    initialValues={{ searchTerm: '' }}
                  >
                    {({ isSubmitting, values, setFieldValue }) => (
                      <Form>
                        <Field
                          name="searchTerm"
                          type="text"
                          as={Input}
                          placeholder="Search user..."
                          className="pl-10"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div className="flex h-[34px] flex-row max-lg:justify-end max-lg:items-center">
              <div className=' max-lg:hidden'>
                {isFiltering && (
                  <LegacyButton
                    rounded="full"
                    variant="outlined"
                    color="primaryActive"
                    border="primaryActive"
                    className="mx-8"
                    startIcon={<Icon name="cancel" />}
                    onClick={handleClearFilters}
                  >
                    ClearFilters
                  </LegacyButton>
                )}
                {/* <input
                  type="text"
                  className="custom-input search-box mt-2 mr-3"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                /> */}
                <div className="relative mt-2 mr-3">
                  <div className="pointer-events-none absolute top-2 left-3 flex items-center">
                    <BsSearch className="h-4 w-4 dark:text-white text-black" />
                  </div>

                  <Formik
                    initialValues={{ searchTerm: '' }}
                  >
                    {({ isSubmitting, values, setFieldValue }) => (
                      <Form>
                        <Field
                          name="searchTerm"
                          type="text"
                          as={Input}
                          placeholder="Search user..."
                          className="pl-10"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              {/* <InputDateRange
                value={selectedDateRange}
                onChange={handleDateRangeFieldChange}
                setStudyFilterHeight={setStudyFilterHeight}
              />
              <Button
                className="lg:ml-5 lg:mt-2 mt-[0.5rem] ml-2 py-0.5 px-8"
                onClick={handleSearch}
              >
                Search
              </Button> */}
            </div>
          </div>
          <div className="max-h-[500px] overflow-y-auto telerapp-scrollbar"
            style={{
              overflowX: 'auto',
              maxHeight: 'calc(100vh - 201px)',
              // scrollbarColor: '#173239 transparent',
            }}>
            <table className="container m-auto mt-2 w-full table-auto dark:text-white text-black">
              <thead className="bg-[#d1d1d1] dark:bg-primary-dark dark:border-primary-main border-secondary-dark border sticky top-[-10px] z-2"
              // style={{ backgroundColor: '#041c4a' }}
              >
                <tr className=' max-sm:text-base '>
                  {values.map(tableHead => (
                    <th
                      className="p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black"
                      key={tableHead}
                    >
                      {tableHead}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="mt-1 ">
                {sortedConnectivityLists &&
                  sortedConnectivityLists.map(instance => {
                    const isSessionExist = activeList.some(sessionData =>
                      sessionData?.attributes?.radiologyGroup?.toString() === instance.name
                    );

                    const formattedLastActive = lastActiveTimes[instance.name] || 'N/A';

                    return (
                      <tr className="bg-secondary-light dark:bg-secondary-dark dark:border-primary-main border-secondary-dark hover:border-secondary-dark dark:hover:bg-[#414141] hover:bg-primary-light cursor-pointer border text-center transition duration-300 max-sm:text-sm">
                        {keys.map(key => (
                          <td
                            className="p-2 dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black"
                            key={key}
                          >
                            {key === 'liveStatus' ? (
                              <span className="flex justify-center items-center">
                                <GoDotFill
                                  fontSize={37}
                                  style={{ color: isSessionExist ? 'lime' : 'red' }}
                                />
                              </span>
                            ) : key === 'groupName' ? (
                              <span className="cursor-pointer">
                                {instance?.name}
                              </span>
                            ) : key === 'lastActive' ? (
                              <span className="cursor-pointer">
                                {formattedLastActive}
                              </span>
                            ) : (
                              instance[key]
                            )}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConnectivityStatus;
