import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSnackbar } from '../../contextProviders';
import { GoDotFill } from 'react-icons/go';
import { Loader } from 'rsuite';
import 'rsuite/Loader/styles/index.css';
import { RxCross2 } from 'react-icons/rx';
import './RadiologyActivePopup.css'
import { ThemeContext } from '../ThemeWrapper/ThemeWrapper';
import { IoMdRefresh } from "react-icons/io";
import Tooltip from '../Tooltip';


const RadiologistUserActiveDetailsModel = ({ radiologistData, fetchSessionsRadiologistUser, user, radiologistUser, togglePopup, setLast24startDate, setLast24endDate }) => {


  const [session, setSession] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshPopup, setRefreshPopup] = useState(false);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    fetchSessionsRadiologistUser(user.access_token, radiologistUser)
      .then(data => setSession(data))
      .catch(error => console.error('Error fetching radiology user session:', error));

    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const last24endDate = new Date(); // Current UTC date and time
  const last24startDate = new Date(last24endDate); // Clone endDate

  // Adjust startDate to 24 hours earlier in UTC
  last24startDate.setUTCHours(last24startDate.getUTCHours() - 24);

  useEffect(() => {
    setLast24startDate(last24startDate);
    setLast24endDate(last24endDate);
    setRefreshPopup(false);
  },[refreshPopup])

  const activeUsernames = Array.from(new Set(session.flat().map(user => user.username)));
  // Convert Set to array

  const activeRadiologistData = activeUsernames.map(username => {
    // Get the modalities data for the username or an empty object if not found

    const userData = radiologistData && radiologistData[username] || {}; // Fallback to empty object
    const modalities = userData.modalities || {};     // Fallback to empty object

    // Combine modality names into a single string
    const combinedModality = Object.keys(modalities).join(', ');

    // Calculate total approved and pending counts using reduce
    const totalApproved = Object.values(modalities).reduce((sum, modality) => sum + modality.approved, 0);
    const totalPending = Object.values(modalities).reduce((sum, modality) => sum + modality.pending, 0);


    return {
      username,
      modality: combinedModality,
      approved: totalApproved,
      pending: totalPending,
    };
  });

  const tableHeaders = {
    liveStatus: 'Status',
    radiologist: 'Radiologist',
    modality: 'Modality',
    approved: 'Approved',
    pending: 'Pending'
  };

  const keys = Object.keys(tableHeaders);
  const values = Object.values(tableHeaders);


  return (
    <div
      className="radiology-active-popup fixed min-[431px]:p-6 max-sm:left-[8px] sm:right-4 right-[8px] sm:bottom-12 bottom-20 dark:bg-primary-dark bg-primary-light p-3 rounded-lg shadow-lg z-[99] border border-secondary-light"
    // Fixed height and enable scrolling
    >
      <header className=" dark:bg-primary-dark bg-primary-light flex items-center rounded-tl rounded-tr pb-2">
        <h6 className=" m-0 flex grow text-xl leading-tight !leading-[1.2] dark:text-white text-black">
          Online Radiologists
        </h6>
        <div className=' mr-3'>
          <Tooltip
            content={"Refresh Popup"}
            position="bottom"
            style={{ paddingRight: '4px', fontWeight: 'normal' }}
          >
            <IoMdRefresh onClick={() => setRefreshPopup(true)} className=' dark:text-white text-black text-xl cursor-pointer' />
          </Tooltip>
        </div>
        <RxCross2 onClick={togglePopup} className=' dark:text-white text-black text-xl cursor-pointer' />
      </header>

      <div className=' overflow-y-auto overflow-x-auto'>
        {activeRadiologistData.length > 0 ? (
          <table className="container m-auto mt-2 table-auto text-white w-full">
            <thead className="bg-[#d1d1d1] dark:bg-primary-dark dark:border-primary-main border-secondary-dark border sticky top-0 right-0 left-0">
              <tr className=' max-sm:text-base '>
                {values.map(tableHead => (
                  <th className="p-2 text-[13px] dark:border-primary-main border-secondary-dark border dark:text-white text-black" key={tableHead}>
                    {tableHead}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {activeRadiologistData.map((user, userIndex) => (
                <tr className="bg-secondary-light dark:bg-secondary-dark dark:border-primary-main border-secondary-dark hover:border-secondary-dark dark:hover:bg-[#414141] hover:bg-primary-light cursor-pointer border text-center transition duration-300 max-sm:text-sm">
                  {keys.map(key => (<td className="p-2 text-[13px] dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black"
                    key={key}>
                    {key === 'liveStatus' ? (
                      <span>
                        <GoDotFill
                          fontSize={37}
                          style={{ color: 'lime' }}
                          className="ml-5"
                        />
                      </span>
                    ) : key === 'radiologist' ? (
                      <span className="cursor-pointer">
                        {user?.username}
                      </span>
                    ) : key === 'modality' ? (
                      <span className="text-wrap flex w-[45px] cursor-pointer flex-wrap justify-center break-all">
                        {user?.modality?.split(',')?.map((item, index) => (
                          <span key={index}>
                            {item},
                            <br />
                          </span>
                        ))}
                      </span>
                    ) : key === 'approved' ? (
                      <span className="cursor-pointer">
                        {user.approved}
                      </span>
                    ) : key === 'pending' ? (
                      <span className="cursor-pointer">
                        {user.pending}
                      </span>
                    ) : (
                      user[key]
                    )}
                  </td>))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className=' w-full h-48 bg-secondary-light dark:bg-secondary-dark dark:border-primary-main border-secondary-dark flex items-center justify-center border p-4  dark:text-white text-black'>
            {loading ? (
              <Loader size="sm" color={theme === 'dark' ? '#fff' : '#000'} content="loading..." />
            ) : (
              'User not available'
            )}
          </div>
        )
        }
      </div>
    </div>
  );
};

export default RadiologistUserActiveDetailsModel;
