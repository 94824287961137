// import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';

// function CallbackPage({ userManager, onRedirectSuccess }) {
//   const onRedirectError = error => {
//     throw new Error(error);
//   };

//   useEffect(() => {
//     userManager
//       .signinRedirectCallback()
//       .then(user => onRedirectSuccess(user))
//       .catch(error => onRedirectError(error));
//   }, [userManager, onRedirectSuccess]);

//   return null;
// }

// CallbackPage.propTypes = {
//   userManager: PropTypes.object.isRequired,
// };

// export default CallbackPage;


// import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';

// function CallbackPage({ userManager, onRedirectSuccess }) {
//   const onRedirectError = error => {
//     // throw new Error(error);
//     console.error('Redirect error:', error);
//     const queryParams = new URLSearchParams(location.search);
//     const loginHint = queryParams.get('login_hint');
//     const targetLinkUri = queryParams.get('target_link_uri');

//     userManager.removeUser().then(() => {
//       if (targetLinkUri !== null) {
//         const ohifRedirectTo = {
//           pathname: new URL(targetLinkUri).pathname,
//         };
//         sessionStorage.setItem('ohif-redirect-to', JSON.stringify(ohifRedirectTo));
//       } else {
//         const ohifRedirectTo = {
//           pathname: '/',
//         };
//         sessionStorage.setItem('ohif-redirect-to', JSON.stringify(ohifRedirectTo));
//       }

//       if (loginHint !== null) {
//         userManager.signinRedirect({ login_hint: loginHint });
//       } else {
//         userManager.signinRedirect();
//       }
//     });

//     return null;
//   };

//   useEffect(() => {
//     userManager
//       .signinRedirectCallback()
//       .then(user => onRedirectSuccess(user))
//       .catch(error => onRedirectError(error));

//     // Subscribe to access token expiration events
//     const tokenExpiredCallback = () => {
//       console.warn('Access token expired. Redirecting to login.');
//       userManager.signoutRedirect(); // Redirect to login if token expires
//     };

//     userManager.events.addAccessTokenExpired(tokenExpiredCallback);

//     // Cleanup on unmount
//     return () => {
//       userManager.events.removeAccessTokenExpired(tokenExpiredCallback);
//     };
//   }, [userManager, onRedirectSuccess]);

//   return null;
// }

// CallbackPage.propTypes = {
//   userManager: PropTypes.object.isRequired,
// };

// export default CallbackPage;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function CallbackPage({ userManager, onRedirectSuccess }) {
  // Helper function to validate URLs
  const isValidURL = urlString => {
    try {
      new URL(urlString);
      return true;
    } catch {
      return false;
    }
  };

  const onRedirectError = error => {
    console.error('Redirect error:', error);

    const loginAttempts = parseInt(sessionStorage.getItem('loginAttempts') || '0', 10);

    // Prevent infinite redirect loop if too many attempts
    if (loginAttempts >= 3) {
      console.warn('Exceeded login attempts. Aborting redirect loop.');
      return;
    }

    // Increment login attempts
    sessionStorage.setItem('loginAttempts', loginAttempts + 1);

    const queryParams = new URLSearchParams(location.search);
    const loginHint = queryParams.get('login_hint');
    const targetLinkUri = queryParams.get('target_link_uri');

    if (targetLinkUri && !isValidURL(targetLinkUri)) {
      console.error('Invalid target_link_uri:', targetLinkUri);
      return;
    }

    // Clear user session and redirect to login
    userManager.removeUser().then(() => {
      const ohifRedirectTo = {
        pathname: targetLinkUri ? new URL(targetLinkUri).pathname : '/',
      };
      sessionStorage.setItem('ohif-redirect-to', JSON.stringify(ohifRedirectTo));

      // Trigger login with or without login_hint
      if (loginHint) {
        userManager.signinRedirect({ login_hint: loginHint });
      } else {
        userManager.signinRedirect();
      }
    });
  };

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const user = await userManager.signinRedirectCallback();
        sessionStorage.setItem('loginAttempts', '0'); // Reset login attempts on success
        sessionStorage.setItem('userLoggedIn', 'true'); // Mark as logged in
        onRedirectSuccess(user);
      } catch (error) {
        console.error('Error during signinRedirectCallback:', error);
        onRedirectError(error);
      }
    };

    // Run the callback handler
    handleCallback();

    // Handle token expiration
    const tokenExpiredCallback = () => {
      console.warn('Access token expired. Redirecting to login.');
      userManager.signoutRedirect();
    };

    userManager.events.addAccessTokenExpired(tokenExpiredCallback);

    // Cleanup on component unmount
    return () => {
      userManager.events.removeAccessTokenExpired(tokenExpiredCallback);
    };
  }, [userManager, onRedirectSuccess]);

  return null; // This component doesn't render any UI
}

CallbackPage.propTypes = {
  userManager: PropTypes.object.isRequired,
  onRedirectSuccess: PropTypes.func.isRequired,
};

export default CallbackPage;
