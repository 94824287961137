export const fetchWindowPresets = async () => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/window-presets`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${accessTokens}`,
    },
  });

  const data = await response.json();
  return data;
};

export const createWindowPresets = async ( data,setWindowPresets) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/window-presets`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${accessTokens}`,
    },
    body: JSON.stringify(data),
  });

   await fetchWindowPresets()
     .then((data) => setWindowPresets(data))
     .catch((error) =>
         console.error('Error fetching window presets details:', error)
     );
  return await response.json();
};

export const updateWindowPresets = async ( data, id) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/window-presets/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  return await response.json();
};

export const deleteWindowPresets = async (id) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/window-presets/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await response.json();
};
