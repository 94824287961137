import { Document, Packer, Paragraph, TextRun, Table, TableCell, TableRow, HeadingLevel, AlignmentType } from 'docx';



export const fetchDefaultReportTemplates = () => {
  return fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/report-templates`)
    .then((response) => response.json())
}

export const fetchDefaultTemplates = (page, limit, search) => {
  return fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/templates?page=${page}&limit=${limit}&search=${search}`)
    .then((response) => response.json());
};

export const fetchMultiLevelFilter = async (worklist, assign, priority, country, modality, institutionname, patientName, sex, uid, mrn, description, accession, physicianUserName, startDate, endDate, page, limit) => {
  const assignName = assign === 'All Users' ? '' : assign
  // console.log(institutionname)
  const institutionName = institutionname[0] === 'All Institutions' ? '' : institutionname

  return await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/search_query?worklist=${worklist.toLowerCase()}&assign=${assignName}&priority=${priority}&country=${country}&modality=${modality}&institutionname=${institutionName}&patientName=${patientName}&sex=${sex}&uid=${uid}&mrn=${mrn}&description=${description}&accession=${accession}&physicianUserName=${physicianUserName}&startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`)
    .then((response) => response.json());
};

export const fetchMultiLevelFilterForUser = async (worklist, assign, priority, country, modality, institutionname, patientName, sex, uid, mrn, description, accession, startDate, endDate, page, limit) => {
  const assignName = assign === 'All Users' ? '' : assign
  const institutionName = institutionname[0] === 'All Institutions' ? '' : institutionname

  return await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/user_search_query?worklist=${worklist.toLowerCase()}&assign=${assignName}&priority=${priority}&country=${country}&modality=${modality}&institutionname=${institutionName}&patientName=${patientName}&sex=${sex}&uid=${uid}&mrn=${mrn}&description=${description}&accession=${accession}&startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`)
    .then((response) => response.json());
};

export const createDefaultTemplates = async (templatesDetail) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/templates`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(templatesDetail),
  })
  return response
}

export const updateDefaultTemplates = async (id, data) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/templates/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return response
};

export const deleteDefaultTemplates = async (id) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/templates/${id}`, {
    method: 'DELETE',
  })

  return response;
}

export const fetchPatientReports = () => {
  return fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/reports`)
    .then((response) => response.json())
}

export const createPatientReports = async (patientDetail, setPatientReportsDetails, username, actionlog, institutionName) => {
  const updatedPatientDetail = {
    ...patientDetail,
    username: username,
    actionlog: actionlog,
    institutionName: institutionName
  };
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/reports`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updatedPatientDetail),
  });
  await fetchPatientReports()
    .then((data) => setPatientReportsDetails(data))
    .catch((error) =>
      console.error('Error fetching patient details:', error)
    );
  return response
};

export const updatePatientReports = async (id, patientDetail, setPatientReportsDetails, username, actionlog, institutionName) => {
  const updatedPatientDetail = {
    ...patientDetail,
    username: username,
    actionlog: actionlog,
    institutionName: institutionName
  };
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/reports/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updatedPatientDetail),
  });
  await fetchPatientReports()
    .then((data) => setPatientReportsDetails(data))
    .catch((error) =>
      console.error('Error fetching patient details:', error)
    );
  return response
};

export const deletePatientReport = async (id, patientDetail, setPatientReportsDetails, username, actionlog, institutionName) => {
  const updatedPatientDetail = {
    ...patientDetail,
    username: username,
    actionlog: actionlog,
    institutionName: institutionName
  };
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/reports/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updatedPatientDetail),
  });
  await fetchPatientReports()
    .then(data => setPatientReportsDetails(data))
    .catch(error => console.error('Error fetching patient details:', error));

  return response;
}

export const generateReportPdf = (reportdetails, setIsLoading, patientName, notDownload) => {

  const currentDate = new Date();
  const formattedDate = `${String(currentDate.getMonth() + 1).padStart(2, '0')}_${String(currentDate.getDate()).padStart(2, '0')}_${currentDate.getFullYear()}`;
  const fileName = `${patientName}_${formattedDate}.pdf`;

  return fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/download-pdf`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ html: reportdetails })
  })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      // a.download = `${patientName}.pdf`;
      a.download = fileName;
      !notDownload ? document.body.appendChild(a) : null;
      !notDownload ? a.click() : null;
      notDownload ? window.open(url, '_blank') : null;
      window.URL.revokeObjectURL(url);
    })
    .catch(error => console.error('Error:', error))
    .finally(() => {
      setIsLoading(false);
    });
};


// export const generateReportPdf = (reportdetails, setIsLoading, patientName, notDownload) => {
//   return fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/download-pdf`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({ html: reportdetails }),
//   })
//     .catch((error) => console.error('Error generating PDF:', error))
//     .finally(() => {
//       // Reset loading state
//       setIsLoading(false);
//     });
// };




// export const generateReportWord = (reportdetails, patientName) => {
//   return fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/download-doc`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({ html: reportdetails })
//   })
//     .then(response => response.blob())
//     .then(blob => {
//       const url = window.URL.createObjectURL(blob);
//       const a = document.createElement('a');
//       a.href = url;
//       a.download = `${patientName}.docx`;
//       document.body.appendChild(a);
//       a.click();
//       window.URL.revokeObjectURL(url);
//     })
//     .catch(error => console.error('Error:', error))
//     .finally(() => {
//     });
// };


// report setting


// const htmlToDocx = (html) => {
//   const parser = new DOMParser();
//   const doc = parser.parseFromString(html, 'text/html');
//   const children = Array.from(doc.body.children);
//   const docxElements = [];

//   children.forEach((child) => {
//     if (child.tagName === 'TABLE') {
//       const rows = Array.from(child.querySelectorAll('tr'));
//       const docxRows = rows.map((row) => {
//         const cells = Array.from(row.querySelectorAll('td, th'));
//         const docxCells = cells.map((cell) => {
//           return new TableCell({
//             children: [
//               new Paragraph({
//                 children: [
//                   new TextRun(cell.innerHTML.replace(/<\/?[^>]+(>|$)/g, "").trim()), // Strip HTML tags and trim spaces
//                 ],
//                 alignment: AlignmentType.CENTER, // Center align the cell text
//               }),
//             ],
//           });
//         });
//         return new TableRow({
//           children: docxCells,
//         });
//       });
//       docxElements.push(new Table({
//         rows: docxRows,
//         width: {
//           size: 10000,
//           type: 'dxa',
//         },
//         borders: {
//           top: { style: 'single', size: 4, space: 0 },
//           bottom: { style: 'single', size: 4, space: 0 },
//           left: { style: 'single', size: 4, space: 0 },
//           right: { style: 'single', size: 4, space: 0 },
//           insideHorizontal: { style: 'single', size: 4, space: 0 },
//           insideVertical: { style: 'single', size: 4, space: 0 },
//         },
//       }));
//     } else if (child.tagName === 'P') {
//       // Add paragraphs
//       docxElements.push(new Paragraph({
//         children: [new TextRun(child.innerHTML.replace(/<\/?[^>]+(>|$)/g, "").trim())],
//         alignment: AlignmentType.LEFT,
//       }));
//     } else {
//       // For any other tags, treat them as paragraphs
//       docxElements.push(new Paragraph({
//         children: [new TextRun(child.innerHTML.replace(/<\/?[^>]+(>|$)/g, "").trim())],
//         alignment: AlignmentType.LEFT,
//       }));
//     }
//   });

//   return docxElements;
// };

// export const generateReportWord = (reportdetails, patientName) => {

//   const currentDate = new Date();
//   const formattedDate = `${String(currentDate.getMonth() + 1).padStart(2, '0')}_${String(currentDate.getDate()).padStart(2, '0')}_${currentDate.getFullYear()}`;
//   const fileName = `${patientName}_${formattedDate}.docx`;

//   const doc = new Document({
//     sections: [{
//       properties: {},
//       children: [
//         new Paragraph({
//           text: "Report for " + patientName,
//           heading: HeadingLevel.HEADING_1,
//           alignment: AlignmentType.CENTER,
//         }),
//         new Paragraph({ text: "", }), // Empty paragraph for spacing
//         ...htmlToDocx(reportdetails), // Convert HTML to docx elements
//       ],
//     }],
//     creator: "Your Name", // Set the document creator
//     title: "Report Document", // Set the title
//     subject: "Report for Patient", // Set the subject
//   });

//   // Generate and download the Word document
//   Packer.toBlob(doc).then((blob) => {
//     const url = window.URL.createObjectURL(blob);
//     const a = document.createElement('a');
//     a.href = url;
//     // a.download = `${patientName}.docx`; // Set the file name
//     a.download = fileName; // Set the file name
//     document.body.appendChild(a);
//     a.click(); // Programmatically click the link to trigger download
//     window.URL.revokeObjectURL(url); // Clean up the URL object
//     document.body.removeChild(a); // Remove the link element from the DOM
//   });
// };


export const generateReportWord = (reportdetails, patientName) => {
  const currentDate = new Date();
  const formattedDate = `${String(currentDate.getMonth() + 1).padStart(2, '0')}_${String(currentDate.getDate()).padStart(2, '0')}_${currentDate.getFullYear()}`;
  const fileName = `${patientName}_${formattedDate}.docx`;
  return fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/download-docx`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ html: reportdetails }),
  })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName; // Word document extension
      if (1) {
        document.body.appendChild(a);
        a.click();
      } else {
        window.open(url, '_blank');
      }
      window.URL.revokeObjectURL(url);
    })
    .catch(error => console.error('Error:', error))
    .finally(() => {
    });
};


export const fetchReportSetting = () => {
  return fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/reports-setting`)
    .then((response) => response.json())
}

export const createReportSetting = async (reportSettingDetails, setReportSettingDetails) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/reports-setting`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(reportSettingDetails),
  })
  await fetchReportSetting()
    .then((data) => setReportSettingDetails(data))
    .catch((error) =>
      console.error('Error fetching report setting details:', error)
    );
  return response
};

export const updateReportSetting = async (id, reportSettingDetails, setReportSettingDetails) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/reports-setting/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(reportSettingDetails),
  })
  await fetchReportSetting()
    .then((data) => setReportSettingDetails(data))
    .catch((error) =>
      console.error('Error fetching report setting details:', error)
    );
  return response
};

export const deleteReportSetting = async (id, setReportSettingDetails) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/reports-setting/${id}`, {
    method: 'DELETE',
  })
  await fetchReportSetting()
    .then((data) => setReportSettingDetails(data))
    .catch((error) =>
      console.error('Error fetching report setting details:', error)
    );
  return response
};
