// Institutions.js
import React, { useContext, useEffect, useState } from 'react';
import { useAnalytics, useSnackbar } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import { Area, AreaChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import InputDateRange from '../InputDateRange';
import Label from '../Label';
import moment from 'moment';
import { ThemeContext } from '../ThemeWrapper/ThemeWrapper';

const Institutions = ({ servicesManager, extensionManager, hotkeysManager }) => {
    const { studyCount, fetchReportsByModalityAndInst, reportsByModalityAndInst, fetchInstituteWeeklyPerform, instituteWeeklyPerform, setStartDate, setEndDate, startDate, endDate } = useAnalytics();
    const { userAuthenticationService } = servicesManager.services;
    const user = userAuthenticationService.getUser();

    const currentuser = user?.profile?.roleType?.includes('RadiologyGroupAdmin')
    const radiologyGroupName = user?.profile?.radiologyGroup;

    const { show } = useSnackbar();
    const [instituteName, setInstituteName] = useState(null);
    const [error, setError] = useState(null);
    const [studyFilterHeight, setStudyFilterHeight] = useState(false);
    // const [selectedDateRange, setSelectedDateRange] = useState<any>({ startDate, endDate });
    const [selectedDateRange, setSelectedDateRange] = useState<any>({
        startDate,
        endDate,
        ...(currentuser && { radiologyGroupName })
    });
    const isMobileLayout = window.innerWidth < 640;
    const { theme } = useContext(ThemeContext);

    const handleDateRangeFieldChange = ({ startDate, endDate }) => {
        const start_date = moment(startDate).format('YYYY-MM-DD');
        const end_date = moment(endDate).format('YYYY-MM-DD');
        setSelectedDateRange({ start_date, end_date });
        setStartDate(start_date);
        setEndDate(end_date);
    };

    const fetchData = (startDate, endDate, radiologyGroupName) => {
        const reportsByPriorityParams = currentuser ? [startDate, endDate, radiologyGroupName] : [startDate, endDate];

        fetchReportsByModalityAndInst(...reportsByPriorityParams)
            .catch(error => setError(error));
    };

    const getLast7Days = () => {
        const today = new Date();
        const priorDate = new Date().setDate(today.getDate() - 7);

        return {
            startDate: new Date(priorDate).toISOString().split('T')[0],
            endDate: today.toISOString().split('T')[0],
        };
    };

    const handleRowClick = (institute) => {
        setInstituteName(institute);
    };

    useEffect(() => {
        if (instituteName && startDate && endDate) {
            fetchInstituteWeeklyPerform(instituteName, startDate, endDate);
        }
    }, [instituteName, startDate, endDate]);

    useEffect(() => {
        const { startDate, endDate } = getLast7Days();
        setSelectedDateRange({ startDate, endDate })
        setStartDate(startDate);
        setEndDate(endDate);
        fetchData(startDate, endDate, radiologyGroupName);
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            fetchData(startDate, endDate, radiologyGroupName);
            setSelectedDateRange({ startDate, endDate });
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (error) {
            show({ message: error.message, type: SnackbarTypes.ERROR });
        }
    }, [error, show]);

    // Define types for your data
    interface ModalityCounts {
        [modality: string]: number;
    }

    interface InstitutionData {
        [institute: string]: ModalityCounts;
    }

    interface TransformedRow {
        [key: string]: number | string;
        total: number;
    }

    interface TransformResult {
        transformedData: TransformedRow[];
        allModalities: string[];
    }

    // Transform function for institutions
    const transformTableData = (data: InstitutionData): TransformResult => {
        const allModalities = new Set<string>();

        const transformedData = Object.entries(data).map(([institute, modality]) => {
            const total = Object.values(modality).reduce((sum, count) => sum + count, 0);
            Object.keys(modality).forEach(modality => allModalities.add(modality));
            return {
                institute,
                ...modality,
                total,
            };
        });

        return { transformedData, allModalities: Array.from(allModalities) };
    };

    const { transformedData: tableData, allModalities } = reportsByModalityAndInst
        ? transformTableData(reportsByModalityAndInst)
        : { transformedData: [], allModalities: [] };


    const transformChartData = (rawData: Record<string, string>) => {
        const dataEntries = Object.entries(rawData);

        return dataEntries.map(([date, value]) => ({
            date,    // the date key
            count: parseInt(value, 10)  // converting the string value to a number
        }));
    };


    const lineChartData = transformChartData(instituteWeeklyPerform);

    const CustomTooltip = ({ payload, label }) => {
        if (payload.length > 0) {
            return (
                <div className="custom-tooltip dark:text-white text-black">
                    <p className="label">{`Date: ${label}`}</p>
                    <p className="value">{`Value: ${payload[0].value}`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <div className=" container mx-auto min-h-[500px] max-h-[550px] overflow-y-auto overflow-x-auto telerapp-scrollbar"
            style={{
                maxHeight: 'calc(100vh - 115px)',
                // scrollbarColor: '#173239 transparent',
            }}>
            <div className=' flex items-center lg:justify-between justify-start my-5 gap-5 max-lg:flex-col'>
                <div className=' flex items-center gap-3 w-full'>
                    <Label className="mt-2 max-sm:text-[16px] max-[375px]:text-[13px] dark:text-white text-black" text={''}>Date Range : </Label>
                    <InputDateRange
                        id={'report-analutic'}
                        value={selectedDateRange}
                        onChange={handleDateRangeFieldChange}
                        setStudyFilterHeight={setStudyFilterHeight}
                    />
                </div>
                < div className=' w-full lg:text-end text-start'>
                    <p
                        className="inline-flex items-center rounded border dark:border-primary-main border-secondary-dark bg-secondary-light dark:bg-secondary-dark py-2 px-4 font-semibold dark:text-white text-black transition-all opacity-80 mr-3 max-sm:text-[16px] max-[375px]:text-[13px]"
                    >
                        Study Count: {studyCount?.count}
                    </p>
                </div>
            </div>
            <div className={` mb-5 ${tableData.length === 0 ? 'h-48 ' : ''} mr-3`} >
                <p className=' dark:text-[#5acce6] text-black max-sm:text-[16px] max-[375px]:text-[13px]'>Study by Modality and Institution </p>
                <div className="max-h-[500px] overflow-y-auto telerapp-scrollbar"
                    style={{
                        overflowX: 'auto',
                        maxHeight: 'calc(100vh - 201px)',
                        // scrollbarColor: '#173239 transparent',
                    }}>
                    {tableData.length > 0 ? (
                        <table className="container m-auto mt-2 w-full table-auto text-white" >
                            <thead className="bg-[#d1d1d1] dark:bg-primary-dark dark:border-primary-main border-secondary-dark border sticky top-[-10px] z-2" >
                                <tr className=' max-sm:text-base '>
                                    <th className=" p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black" > Institute </th>
                                    {
                                        allModalities.map(modality => (
                                            <th key={String(modality)} className=" p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black" > {modality} </th>
                                        ))
                                    }
                                    <th className=" p-2 dark:border-primary-main border-secondary-dark border dark:text-white text-black" > Total </th>
                                </tr>
                            </thead>
                            <tbody className="mt-1 ">
                                {
                                    tableData.map((row, index) => (
                                        <tr key={index} className="bg-secondary-light dark:bg-secondary-dark dark:border-primary-main border-secondary-dark hover:border-secondary-dark dark:hover:bg-[#414141] hover:bg-primary-light cursor-pointer border text-center transition duration-300 max-sm:text-sm" >
                                            <td className=" p-2 cursor-pointer dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black"
                                                onClick={() => handleRowClick(row.institute)}
                                            >
                                                {row.institute}
                                            </td>
                                            {
                                                allModalities.map(modality => (
                                                    <td key={String(modality)} className=" p-2 cursor-pointer dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black" > {row[modality] || 0} </td>
                                                ))
                                            }
                                            <td className=" p-2 cursor-pointer dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black" > {row.total} </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    ) : (
                        <div className=' h-full flex justify-center items-center bg-secondary-light dark:bg-secondary-dark  dark:border-primary-main border-secondary-dark border hover:border-secondary-dark dark:text-white text-black p-4 mt-2  max-sm:text-[16px] max-[375px]:text-[13px]'>Data not available.</div>
                    )}
                </div>
            </div>
            {instituteName && (
                <div className=' flex-1 bg-secondary-light dark:bg-secondary-dark dark:border-primary-main border-secondary-dark border p-4 rounded-md mr-3'>
                    <p className=' text-[#5acce6]  max-sm:text-[16px] max-[375px]:text-[13px]'>Weekly Institution performance</p>
                    <ResponsiveContainer width="100%" height={400}>
                        {lineChartData.length > 0 ? (
                            <AreaChart data={lineChartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" stroke='#3a3f99 ' />
                                <XAxis dataKey="date" stroke={theme === 'dark' ? "#fff" : "#000"} fontSize={isMobileLayout ? '12px' : '16px'} />
                                <YAxis stroke={theme === 'dark' ? "#fff" : "#000"} allowDecimals={false} domain={[0, 'auto']} fontSize={isMobileLayout ? '12px' : '16px'} />
                                <Tooltip content={CustomTooltip} />
                                <Line type="monotone" dataKey="count" stroke="#5acce6" />
                                <Area type="monotone" dataKey="count" stroke="#5acce6" fill="#FFBB28" />
                            </AreaChart>
                        ) : (
                            <div className=' bg-secondary-light dark:bg-secondary-dark dark:text-white text-black h-full flex justify-center items-center  max-sm:text-[16px] max-[375px]:text-[13px]'>Data not available.</div>
                        )}
                    </ResponsiveContainer>
                </div>
            )}
        </div>

    );
};

export default Institutions;
