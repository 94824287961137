import React, { useState, useEffect, useMemo, useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import qs from 'query-string';
import isEqual from 'lodash.isequal';
import { useTranslation } from 'react-i18next';
import filtersMeta from './filtersMeta.js';
import { useAppConfig } from '@state';
import { useDebounce, useSearchParams } from '@hooks';
import { utils, hotkeys, ServicesManager } from '@ohif/core';
import { LuPencilLine } from 'react-icons/lu';
import { PiMicrosoftWordLogoBold } from 'react-icons/pi';
import { TbFileTypePdf } from 'react-icons/tb';
import { FaClipboardList, FaFileDownload } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import { BsPersonAdd, BsPersonFillAdd } from 'react-icons/bs';
import { FaShareSquare } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { HiMiniDocumentPlus } from "react-icons/hi2";
import { GrDocumentUpdate } from "react-icons/gr";
import { FaNotesMedical } from "react-icons/fa";
import { BsFileMedicalFill } from "react-icons/bs";
import './WorkList.css';
import { FaUserCog } from "react-icons/fa";
import { IoInformationCircle } from "react-icons/io5";
import { IoMdSettings } from "react-icons/io";
import { FaPowerOff } from "react-icons/fa";

import {
  Icon,
  StudyListExpandedRow,
  LegacyButton,
  EmptyStudies,
  StudyListTable,
  StudyListPagination,
  StudyListFilter,
  TooltipClipboard,
  Header,
  useModal,
  AboutModal,
  UserPreferences,
  LoadingIndicatorProgress,
  useSessionStorage,
  useRadiologyGroup,
  useUser,
  Select,
  InvestigationalUseDialog,
  DocumentStatusModal,
  ReportStatusModal,
  useReportEditor,
  AddRadiologistModel,
  AddClinicalHistoryModel,
  WorklistModel,
  AddAttachmentModel,
  Button,
  ButtonEnums,
  useDocumentEditor,
  ShareModel,
  WorklistLabel,
  useDisplaySettings
} from '@ohif/ui';

// import { Header } from '@ohif/ui-next';
import worklistFilter from './worklistFilter.js';
import { Types } from '@ohif/ui';
import Swal from 'sweetalert2';
import i18n from '@ohif/i18n';
import { Onboarding, ScrollArea } from '@ohif/ui-next';
import { fetchPatientReports } from '../../../../ui/src/requestHandler';
// import { fetchStudyList } from '../../../../ui/src/requestHandler/userRequest';
import { fetchRadiologyGroup } from '../../../../ui/src/requestHandler/radiologyGroupRequest';
import SnackbarTypes from '../../../../ui/src/components/Snackbar/SnackbarTypes.js';
import { fetchMultiLevelFilter, fetchMultiLevelFilterForUser } from '../../../../ui/src/requestHandler/reportTemplateRequest';
const PatientInfoVisibility = Types.PatientInfoVisibility;

const { sortBySeriesDate } = utils;

const { availableLanguages, defaultLanguage, currentLanguage } = i18n;

const seriesInStudiesMap = new Map();

/**
 * TODO:
 * - debounce `setFilterValues` (150ms?)
 */

const DEFAULT_WORKLISTS = {
  checkBox: true,
  patientName: true,
  tat: true,
  studyTat: true,
  patientId: true,
  age: true,
  sex: true,
  dob: true,
  uid: true,
  acquisitiondate: true,
  mrn: true,
  studyDate: true,
  priority: true,
  description: true,
  physicianName: true,
  modality: true,
  accession: true,
  institutionname: true,
  aet: true,
  attachment: true,
  clinicalhistory: true,
  assign: true,
  // reportstatus: true,
  document: true,
  instances: true,
  firstupdate: true,
  lastupdate: true,
  share: false,
  general: true
};
const DEFAULT_VIEWER = {
  microscopy: true,
  tmtv: true,
  segmentation: true,
  viewer: true,
  dynamicVolume: false,
};

function WorkList({
  data: studies,
  dataTotal: studiesTotal,
  isLoadingData,
  dataSource,
  hotkeysManager,
  dataPath,
  onRefresh,
  servicesManager,
}: withAppTypes) {
  const { hotkeyDefinitions, hotkeyDefaults } = hotkeysManager;
  const { show, hide } = useModal();
  const { t } = useTranslation();
  // ~ Modes
  const [appConfig] = useAppConfig();
  // ~ Filters
  const searchParams = useSearchParams();
  const navigate = useNavigate();
  const queryFilterValues = _getQueryFilterValues(searchParams);
  const [sessionQueryFilterValues, updateSessionQueryFilterValues] = useSessionStorage({
    key: 'queryFilterValues',
    defaultValue: queryFilterValues,
    // ToDo: useSessionStorage currently uses an unload listener to clear the filters from session storage
    // so on systems that do not support unload events a user will NOT be able to alter any existing filter
    // in the URL, load the page and have it apply.
    clearOnUnload: true,
  });
  const [filterValues, _setFilterValues] = useState({
    ...defaultFilterValues,
    ...sessionQueryFilterValues,
  });
  const [isLoading, setIsLoading] = useState([])

  const debouncedFilterValues = useDebounce(filterValues, 500);
  const { resultsPerPage, pageNumber, sortBy, sortDirection } = filterValues;

  /*
   * The default sort value keep the filters synchronized with runtime conditional sorting
   * Only applied if no other sorting is specified and there are less than 101 studies
   */
  const canSort = studiesTotal;
  const shouldUseDefaultSort = sortBy === '' || !sortBy;
  const sortModifier = sortDirection === 'descending' ? 1 : -1;
  const defaultSortValues =
    shouldUseDefaultSort && canSort ? { sortBy: 'studyDate', sortDirection: 'ascending' } : {};
  const sortedStudies = studies;

  if (canSort) {
    studies.sort((s1, s2) => {
      if (shouldUseDefaultSort) {
        const ascendingSortModifier = -1;
        return _sortStringDates(s1, s2, ascendingSortModifier);
      }

      const s1Prop = s1[sortBy];
      const s2Prop = s2[sortBy];

      if (typeof s1Prop === 'string' && typeof s2Prop === 'string') {
        return s1Prop.localeCompare(s2Prop) * sortModifier;
      } else if (typeof s1Prop === 'number' && typeof s2Prop === 'number') {
        return (s1Prop > s2Prop ? 1 : -1) * sortModifier;
      } else if (!s1Prop && s2Prop) {
        return -1 * sortModifier;
      } else if (!s2Prop && s1Prop) {
        return 1 * sortModifier;
      } else if (sortBy === 'studyDate') {
        return _sortStringDates(s1, s2, sortModifier);
      }

      return 0;
    });
  }
  const { customizationService, userAuthenticationService } = servicesManager.services;

  const user = userAuthenticationService.getUser();

  const username = user?.profile?.name;

  const {
    createWorklistAttributes,
    worklistAttributesList,
    updateWorklistAttributes,
    fetchWorklistAttributes,
    setWorklistAttributesList,
    usersList,
    userToken,
    token,
    setToken,
    setAllStudy,
  } = useUser();

  const permissions = user?.profile?.permission

  const hasDownloadPdfPermission =
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin') ||
    token?.realm_access?.roles?.includes('Physician');

  const hasShareReportPermission =
    permissions?.includes('Share Report') ||
    token?.realm_access?.roles?.includes('telerapper') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const isAttachment =
    user?.profile?.roleType?.includes('Radiologist') ||
    user?.profile?.roleType?.includes('QaUsers') ||
    user?.profile?.roleType?.includes('Technologist') ||
    user?.profile?.roleType?.includes('RadiologyGroupAdmin') ||
    user?.profile?.roleType?.includes('TelerappManager') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const hasManagerPermission =
    permissions?.includes('Approve Report') ||
    user?.profile?.roleType?.includes('Radiologist') ||
    user?.profile?.roleType?.includes('QaUsers') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  const hasRadiologist = user?.profile?.roleType?.includes('Radiologist')
  const hasManager = user?.profile?.roleType?.includes('TelerappManager')
  const hasPhysician = user?.profile?.roleType?.includes('Physician')
  const hasAssignPermission =
    permissions?.includes('Assign') ||
    token?.realm_access?.roles?.includes('super-admin') ||
    token?.realm_access?.roles?.includes('deputy-admin');

  useEffect(() => {
    if (hasShareReportPermission) {
      if (DEFAULT_WORKLISTS.share === false) {
        DEFAULT_WORKLISTS.share = true;
        filtersMeta.push({
          name: 'share',
          displayName: i18n.t('StudyList:Share'),
          inputType: 'None',
          isSortable: false,
          gridCol: 1,
        });
      }
    }
  }, [hasShareReportPermission]);

  const { displaySettingsOptionsLists, worklistAutoRefreshTime, setWorklistAutoRefreshTime } = useDisplaySettings();

  const userData = usersList?.find((i) => i.email === user.profile.email)
  // const filterDisplaySettings = displaySettingsOptionsLists?.find((i)=> i?.user_Id === userData?.id)

  useEffect(() => {
    // const filterDisplaySettings = displaySettingsOptionsLists?.find((i) => i?.user_Id === userData?.id)
    const filterDisplaySettings = Array.isArray(displaySettingsOptionsLists)
      ? displaySettingsOptionsLists?.find((i) => i?.user_Id === userData?.id)
      : undefined;
    const refreshTime = filterDisplaySettings?.worklist_auto_refresh_time
      ? Number(filterDisplaySettings.worklist_auto_refresh_time) * 60 * 1000
      : 60000; // Default to 1 minute in milliseconds if no value is found

    setWorklistAutoRefreshTime(refreshTime);
  }, [displaySettingsOptionsLists, userData])


  // ~ Worklist Attributes Setting
  const userCurrentWorklist = worklistAttributesList
    ? worklistAttributesList?.find(item => item.user_id === user.profile.sub)
    : null;

  // ~ Worklist Attributes Data
  const worklistData = userCurrentWorklist
    ? JSON.parse(userCurrentWorklist?.worklistAttributes[0])
    : DEFAULT_WORKLISTS;

  // ~ Viewer Attributes Data
  const viewerData = userCurrentWorklist
    ? JSON.parse(userCurrentWorklist?.viewer[0])
    : DEFAULT_VIEWER;

  // ~ Define the desired order of columns based on worklistData keys
  const worklistKeysOrder = Object.keys(worklistData);

  // ~ filter Worklist Coloums
  // Filter and sort filtersMeta based on the presence of data in worklistData
  const filteredFiltersMeta = filtersMeta
    .filter(meta => worklistData[meta?.name]) // Filter based on available data
    .sort((a, b) => {
      // Compare the index in the order array to set the display order
      return worklistKeysOrder.indexOf(a?.name) - worklistKeysOrder.indexOf(b?.name);
    });

  // ~ filter Viewer Coloums
  const filteredModes = appConfig.loadedModes && appConfig.loadedModes?.filter(mode => viewerData[mode.routeName]);

  // ~  Map Worklist Coloums Name to Display Name
  const attributesName = filtersMeta
    .map(item => ({
      name: item.name,
      displayName: item.displayName,
    }));

  // ~  Map Viewer Name to Display Name
  const viewerName = appConfig.loadedModes.map(item => ({
    routeName: item.routeName,
    displayName: item.displayName,
  }));

  const displaySetList = worklistFilter.map(item => ({
    label: item.label,
    value: item.value,
  }));
  const [selectedWorklistFilter, setSelectedWorklistFilter] = useState(displaySetList[1].value);

  // ~ Rows & Studies
  const [expandedRows, setExpandedRows] = useState([]);
  const [studiesWithSeriesData, setStudiesWithSeriesData] = useState([]);
  const [allStudies, setAllStudies] = useState([]);
  const [isCriticalData, setIsCriticalData] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [currentUserStudy, setCurrentUserStudy] = useState([]);
  const [filterStudies, setFilterStudies] = useState([]);
  const [studyFilter, setStudyFilter] = useState([]);
  const [first, setFirst] = useState([])
  const [loaderCount, setLoaderCount] = useState(0);
  const loader = loaderCount > 0;
  const [second, setSecond] = useState(0)
  const [generalStudies, setGeneralStudies] = useState([]);
  const [countryid, setCountryid] = useState('');
  const initialSelectedPriorities = allStudies.reduce((acc, study) => {
    acc[study?.studyInstanceUid] = null;
    return acc;
  }, {});

  const [selectedPriority, setSelectedPriority] = useState(initialSelectedPriorities);
  const [selectedRows, setSelectedRows] = useState([]);

  const {
    patientReportsDetails,
    generateReportPdf,
    createPatientReports,
    updatePatientReports,
    generateReportWord,
    deletePatientReport,
    setPatientReportsDetails,
    getCurrentPatient,
    reportSettingDetails,
    setIsModelOpen, setModelUrl
  } = useReportEditor();

  const {
    documentUploadDetails,
    createDocument,
    updateDocument,
    deleteDocumentUrl,
    sendReportData,
    sendImageToSMS,
    sendImageToEmail,
    sendReportDataToWhastapp,
    setDocumentUploadDetails,
  } = useDocumentEditor();

  const {
    createRadiologyGroupSetting,
    radiologyGroupSetting,
    updateRadiologyGroupSetting,
    fetchRadiologyGroupSetting,
    setRadiologyGroupSetting,
    radiologyGroupList,
    setGroupIds,
    deleteRadiologyGroupMember,
    addRadiologyGroupMember,
    fetchSingleRadiologyGroup,
    setSingleRadiologyGroupList,
    groupIds,
    setRadiologyGroupList,
    singleRadiologyGroupList
  } = useRadiologyGroup();

  const { allStudyList, deleteStudies, setAllStudyList, setIsDeleteData, isDeleteData } = useUser();

  useEffect(() => {
    // console.log('Resetting modelUrl and isModelOpen on studyInstanceUid change');
    setModelUrl("");  // Reset model URL
    setIsModelOpen(false);  // Close the model
  }, []);

  useEffect(() => {
    const institutionFilter = filtersMeta && filtersMeta.find(filter => filter?.name === 'institutionname');

    if (institutionFilter && Array.isArray(institutionFilter.inputProps.options)) {
      // Create a Set to track existing option values
      const existingOptions = new Set(institutionFilter.inputProps.options.map(option => option.value));

      // Add "All Institutions" if it's not present
      if (!existingOptions.has('All Institutions')) {
        institutionFilter.inputProps.options.push({
          label: 'All Institutions',
          value: 'All Institutions'
        });
        existingOptions.add('All Institutions'); // Also update the set to avoid duplicate checks
      }

      // Create a Set of group names for easier comparison
      const groupNamesSet = new Set(radiologyGroupList.map(group => group.name));

      // Filter out options not in radiologyGroupList (except "All Institutions")
      institutionFilter.inputProps.options = institutionFilter.inputProps.options.filter(option =>
        option.value === 'All Institutions' || groupNamesSet.has(option.value)
      );

      // Add any new group names from radiologyGroupList that don't already exist
      radiologyGroupList.forEach(group => {
        if (!existingOptions.has(group.name)) {
          institutionFilter.inputProps.options.push({
            label: group.name,
            value: group.name
          });
        }
      });
    }
  }, [radiologyGroupList, filtersMeta]);

  const accessToken = sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_KEYCLOAK_AUTHORIZATION_URL}`);
  const userData1 = JSON.parse(accessToken);
  const accessTokens = userData1?.access_token;

  const fetchMultiLevelFilterData = async (institionName, data) => {
    try {
      !data && setLoaderCount((prev) => prev + 1);
      let response;
      if (
        token?.realm_access?.roles.includes('super-admin') ||
        token?.realm_access?.roles.includes('deputy-admin') ||
        user?.profile?.roleType === 'TelerappManager' ||
        user?.profile?.roleType === 'DeputyAdmin'
      ) {
        response = await fetchMultiLevelFilter(
          selectedWorklistFilter,
          filterValues?.assign,
          filterValues?.priority,
          institionName,
          filterValues.modality,
          filterValues.institutionname,
          filterValues.patientName,
          filterValues.sex,
          filterValues.uid,
          filterValues.mrn,
          filterValues.description,
          filterValues.accession,
          "",
          filterValues.studyDate.startDate === null ? '' : filterValues.studyDate.startDate,
          filterValues.studyDate.endDate === null ? '' : filterValues.studyDate.endDate,
          pageNumber,
          resultsPerPage,
        );
      } else if (user?.profile?.roleType === 'Radiologist' || user?.profile?.roleType === 'QaUsers') {
        const radiologyGroupName =
          user?.profile?.radiologyGroup === 'Default' ? filterValues.institutionname : user?.profile?.radiologyGroup;
        const radiologyGroupNameUser = user?.profile?.preferred_username;

        response = await fetchMultiLevelFilterForUser(
          selectedWorklistFilter,
          radiologyGroupNameUser,
          filterValues?.priority,
          institionName,
          filterValues.modality,
          radiologyGroupName,
          filterValues.patientName,
          filterValues.sex,
          filterValues.uid,
          filterValues.mrn,
          filterValues.description,
          filterValues.accession,
          filterValues.studyDate.startDate,
          filterValues.studyDate.endDate,
          pageNumber,
          resultsPerPage
        );
      } else if (user?.profile?.roleType === 'RadiologyGroupAdmin' || user?.profile?.roleType === 'Technologist') {
        const radiologyGroupName =
          user?.profile?.radiologyGroup === 'Default' ? filterValues.institutionname : user?.profile?.radiologyGroup;

        response = await fetchMultiLevelFilter(
          selectedWorklistFilter,
          filterValues?.assign,
          filterValues?.priority,
          institionName,
          filterValues.modality,
          radiologyGroupName,
          filterValues.patientName,
          filterValues.sex,
          filterValues.uid,
          filterValues.mrn,
          filterValues.description,
          filterValues.accession,
          "",
          filterValues.studyDate.startDate === null ? '' : filterValues.studyDate.startDate,
          filterValues.studyDate.endDate === null ? '' : filterValues.studyDate.endDate,
          pageNumber,
          resultsPerPage
        );
      } else if (user?.profile?.roleType === 'Physician') {
        const radiologyGroupName =
          user?.profile?.radiologyGroup === 'Default' ? filterValues.institutionname : user?.profile?.radiologyGroup;
        const physicianUserName = user?.profile?.preferred_username;

        response = await fetchMultiLevelFilter(
          selectedWorklistFilter,
          filterValues?.assign,
          filterValues?.priority,
          institionName,
          filterValues.modality,
          radiologyGroupName,
          filterValues.patientName,
          filterValues.sex,
          filterValues.uid,
          filterValues.mrn,
          filterValues.description,
          filterValues.accession,
          physicianUserName,
          filterValues.studyDate.startDate === null ? '' : filterValues.studyDate.startDate,
          filterValues.studyDate.endDate === null ? '' : filterValues.studyDate.endDate,
          pageNumber,
          resultsPerPage
        );
      }

      setFirst(Array.isArray(response.results) ? response.results : []);
      setSecond(response.totalResults);
      setAllStudy(Array.isArray(response.results) ? response.results : []);
    } catch (error) {
      console.error("Error fetching reports list:", error);
      setFirst([]);
      setAllStudy([]);
    } finally {
      setLoaderCount((prev) => Math.max(prev - 1, 0));
    }
  };

  const fetchPatientReportsData = async () => {
    try {
      const response = await fetchPatientReports(); // Replace with your actual API endpoint
      setPatientReportsDetails(response);
    } catch (error) {
      console.error('Error fetching reports list:', error);
    }
  };

  useEffect(() => {
    if (worklistAutoRefreshTime) {
      fetchPatientReportsData()
      const interval = setInterval(() => {
        fetchPatientReportsData();
      }, Number(worklistAutoRefreshTime));
      return () => clearInterval(interval);
    }
  }, [worklistAutoRefreshTime]);

  useEffect(() => {
    if (worklistAutoRefreshTime) {
      const interval = setInterval(() => {
        if (selectedWorklistFilter || filterValues?.assign || filterValues?.priority
          || countryid.id > 0 || filterValues.modality || filterValues.institutionname || filterValues.patientName || filterValues.sex || filterValues.uid || filterValues.mrn || filterValues.description || filterValues.accession || filterValues.studyDate.startDate || filterValues.studyDate.endDate) {

          const countryinstiution = singleRadiologyGroupList.filter(group => (group?.attributes?.groupCountryid || [0]).map(Number)[0] === countryid.id);

          const countryinstiutionname = countryinstiution.map(instiution => instiution.name)
          fetchMultiLevelFilterData(countryinstiutionname, true);
        }
      }, worklistAutoRefreshTime);
      return () => clearInterval(interval);
    }
  }, [worklistAutoRefreshTime, selectedWorklistFilter, filterValues?.assign, filterValues?.priority, countryid, filterValues.modality, filterValues.institutionname, filterValues.patientName, filterValues.sex, filterValues.uid, filterValues.mrn, filterValues.description, filterValues.accession, filterValues.studyDate.startDate, filterValues.studyDate.endDate, pageNumber, resultsPerPage, username, token]);

  const fetchData = async () => {
    try {
      // Fetch radiology groups
      const groupsData = await fetchRadiologyGroup(accessTokens);
      setRadiologyGroupList(groupsData);

      // Only fetch single radiology groups after groupIds are set
      if (groupsData.length > 0) {
        const filteredGroupIds = groupsData && groupsData?.filter(group => !group.error)
          .map(group => group.id);
        const singleGroupData = await fetchSingleRadiologyGroup(accessTokens, filteredGroupIds);
        setSingleRadiologyGroupList(singleGroupData);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (worklistAutoRefreshTime) {
      fetchData();
      const interval = setInterval(() => {
        fetchData();
      }, Number(worklistAutoRefreshTime));

      return () => clearInterval(interval);
    }
  }, [worklistAutoRefreshTime]);

  useEffect(() => {
    if (selectedWorklistFilter || filterValues?.assign || filterValues?.priority || countryid.id > 0 || filterValues.modality || filterValues.institutionname || filterValues.patientName || filterValues.sex || filterValues.uid || filterValues.mrn || filterValues.description || filterValues.accession || filterValues.studyDate.startDate || filterValues.studyDate.endDate) {

      const countryinstiution = singleRadiologyGroupList.filter(group => (group?.attributes?.groupCountryid || [0]).map(Number)[0] === countryid.id);

      const countryinstiutionname = countryinstiution.map(instiution => instiution.name)
      fetchMultiLevelFilterData(countryinstiutionname, false);
    }
  }, [selectedWorklistFilter, filterValues?.assign, filterValues?.priority, countryid, filterValues.modality, filterValues.institutionname, filterValues.patientName, filterValues.sex, filterValues.uid, filterValues.mrn, filterValues.description, filterValues.accession, filterValues.studyDate.startDate, filterValues.studyDate.endDate, pageNumber, resultsPerPage, username, token])

  let reportSetting;


  // useEffect(() => {
  //   let filteredStudies = []; // Initialize an empty array for the filtered results.
  //   const assignNameConavat =
  //     patientReportsDetails &&
  //     patientReportsDetails?.map(item => {
  //       return { ...item, assign: item.assign?.map(JSON.parse) };
  //     });

  //   // const assignNamesAndStudyUIDs =
  //   //   assignNameConavat &&
  //   //   assignNameConavat?.map(data => ({
  //   //     assign_name: data?.assign?.map(assignObj => assignObj.assign_name).flat(),
  //   //     studyinstanceuid: data.study_UIDs,
  //   //   }));

  //   const assignNamesAndStudyUIDs =
  //     assignNameConavat &&
  //     assignNameConavat.map(data => ({
  //       assign_name: data?.assign?.map(assignObj => assignObj?.assign_name).flat() || [],
  //       studyinstanceuid: data?.study_UIDs || [],
  //     }));

  //   // patient name
  //   if (filteredStudies.length > 0) {
  //     filteredStudies = filteredStudies && filteredStudies?.filter(study => {
  //       const patientNameLowerCase = study.patientNames.toLowerCase();
  //       const filterValueLowerCase = filterValues.general.toLowerCase();

  //       return patientNameLowerCase.includes(filterValueLowerCase);
  //     });

  //   } else {
  //     filteredStudies = filterStudies && filterStudies.filter(study => {
  //       const patientNameLowerCase = study.patientNames.toLowerCase();
  //       const filterValueLowerCase = filterValues.general.toLowerCase();

  //       return patientNameLowerCase.includes(filterValueLowerCase);
  //     });
  //   }

  //   // // Apply studiesPriorityFilter
  //   // if (studiesPriorityFilter.length > 0) {
  //   //   if (filteredStudies.length > 0) {
  //   //     filteredStudies = filterValues.priority.reduce((acc, priority) => {
  //   //       return acc.concat(getFilteredPriority(priority.toLowerCase(), filteredStudies));
  //   //     }, []);
  //   //   } else {
  //   //     filteredStudies = filterValues.priority.reduce((acc, priority) => {
  //   //       return acc.concat(getFilteredPriority(priority.toLowerCase(), filterStudies));
  //   //     }, []);
  //   //   }
  //   // }

  //   // Apply institutionFilter
  //   if (institutionFilter.length > 0) {
  //     if (filteredStudies.length > 0) {
  //       // If `filteredStudies` already has filtered results
  //       filteredStudies = filteredStudies && filteredStudies?.filter(study =>
  //         institutionFilter.some(item => item.institutionname === study.institutionname)
  //       );
  //     } else {
  //       // If `filteredStudies` is empty, filter the entire dataset (allStudies)
  //       filteredStudies = filterStudies && filterStudies?.filter(study =>
  //         institutionFilter.some(item => item.institutionname === study.institutionname)
  //       );
  //     }
  //   }

  //   // Apply studyAssignFilter
  //   if (studyAssignFilter.length > 0) {
  //     const filterStudiesLogic = (study) =>
  //       assignNamesAndStudyUIDs.some(signedStudy => {
  //         // Ensure signedStudy.assign_name exists and is an array
  //         if (Array.isArray(signedStudy.assign_name) && signedStudy.studyinstanceuid === study?.studyInstanceUid) {
  //           // Check if any name includes the filter value
  //           return signedStudy.assign_name.some(name =>
  //             typeof name === 'string' && name.includes(filterValues.assign || filterValues.general.toLowerCase())
  //           );
  //         }
  //         return false;
  //       });

  //     if (filteredStudies.length > 0) {
  //       filteredStudies = filteredStudies && filteredStudies.filter(filterStudiesLogic);
  //     } else {
  //       filteredStudies = filterStudies && filterStudies.filter(filterStudiesLogic);
  //     }
  //   }

  //   // // Apply studyStutusFilter
  //   // if (studyStutusFilter.length > 0) {
  //   //   if (filteredStudies.length > 0) {

  //   //     filteredStudies = filterValues.reportstatus.reduce((acc, status) => {
  //   //       return acc.concat(getFilteredStudies(status.toLowerCase(), filteredStudies));
  //   //     }, []);
  //   //   } else {

  //   //     filteredStudies = filterValues.reportstatus.reduce((acc, status) => {
  //   //       return acc.concat(getFilteredStudies(status.toLowerCase(), filterStudies));
  //   //     }, []);
  //   //   }
  //   // }

  //   // Apply countryStateFilter based on countryid
  //   if (countryid.id > 0) {
  //     const countryState = filterStudies && filterStudies?.filter(item => {
  //       return singleRadiologyGroupList.some(group => {
  //         const groupCountryId = (group?.attributes?.groupCountryid || [0]).map(Number)[0];

  //         return groupCountryId === countryid.id && group?.name === item.institutionname;
  //       });
  //     });


  //     if (countryState.length > 0) {
  //       if (filteredStudies.length > 0) {
  //         filteredStudies = filteredStudies && filteredStudies?.filter(study =>
  //           countryState.some(item => item.studyInstanceUid === study.studyInstanceUid)
  //         );
  //       } else {
  //         filteredStudies = countryState;
  //       }
  //     } else {
  //       filteredStudies = []
  //     }
  //   }

  //   if (
  //     studiesPriorityFilter.length === 0 &&
  //     institutionFilter.length === 0 &&
  //     studyAssignFilter.length === 0 &&
  //     studyStutusFilter.length === 0 &&
  //     countryid.id === undefined &&
  //     !filterValues?.general &&
  //     !filterValues?.assign
  //   ) {
  //     filteredStudies = filterStudies;
  //   }

  //   // Update the state with the final filtered results
  //   setStudyFilter(filteredStudies);
  // }, [studiesPriorityFilter, institutionFilter, studyAssignFilter, studyStutusFilter, countryid, filterStudies]);

  const querying = useMemo(() => {
    return isLoadingData || expandedRows.length > 0;
  }, [isLoadingData, expandedRows]);

  let filterData;

  const numOfStudies = studiesTotal;
  const setFilterValues = val => {
    if (filterValues.pageNumber === val.pageNumber) {
      val.pageNumber = 1;
    }
    _setFilterValues(val);
    updateSessionQueryFilterValues(val);
    setExpandedRows([]);
  };

  const onPageNumberChange = newPageNumber => {
    const oldPageNumber = filterValues.pageNumber;
    const rollingPageNumberMod = Math.floor(1000 / filterValues.resultsPerPage);
    const rollingPageNumber = oldPageNumber % rollingPageNumberMod;
    const isNextPage = newPageNumber > oldPageNumber;
    // const hasNextPage = Math.max(rollingPageNumber, 1) * resultsPerPage < numOfStudies;
    const hasNextPage = allStudies?.length === filterValues.resultsPerPage;

    if (isNextPage && !hasNextPage) {
      return;
    }

    setFilterValues({ ...filterValues, pageNumber: newPageNumber });
  };

  const onResultsPerPageChange = newResultsPerPage => {
    setFilterValues({
      ...filterValues,
      pageNumber: 1,
      resultsPerPage: Number(newResultsPerPage),
    });
  };

  const TATSetting =
    radiologyGroupSetting &&
    radiologyGroupSetting?.find(item => item.name === 'RadiologyGroupTATSetting');

  const fieldData = TATSetting ? JSON.parse(TATSetting?.field[0]) : null;

  const tatHours = parseInt(fieldData?.tatHours, 10);
  const tatMinutes = parseInt(fieldData?.tatMinutes, 10);
  const thresholdInSeconds = tatHours * 3600 + tatMinutes * 60;

  const initialTimeInSeconds =
    parseInt(localStorage.getItem('timeInSeconds')) || thresholdInSeconds;

  const [timeInSeconds, setTimeInSeconds] = useState(initialTimeInSeconds);
  const [timerActive, setTimerActive] = useState(true);

  const formatTime = time => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    return `${hours?.toString().padStart(2, '0')}:${minutes?.toString().padStart(2, '0')}:${seconds?.toString().padStart(2, '0')}`;
  };
  const [resDatas, setResDatas] = useState();

  useEffect(() => {
    let interval;

    if (timerActive && timeInSeconds > 0) {
      interval = setInterval(() => {
        setTimeInSeconds(prevTime => Math.max(prevTime - 1, 0));
      }, 1000);
    } else if (timeInSeconds === 0) {
      clearInterval(interval);
      setTimerActive(false);
    }

    return () => clearInterval(interval);
  }, [timerActive, timeInSeconds]);

  useEffect(() => {
    // const mergedData = studies.map(data => {
    //   const modalityData =
    //     patientReportsDetails?.length > 0 &&
    //     patientReportsDetails?.map(item => {
    //       return { ...item, assign: item.assign?.map(JSON.parse) };
    //     });

    //   const assignNamesAndModality =
    //     modalityData &&
    //     modalityData?.map(data => ({
    //       assign_time: data?.assign
    //         ?.map(
    //           assignObj => assignObj?.assign_name === data?.created_by && assignObj?.assign_time
    //         )
    //         .flat(),
    //       study_UIDs: data.study_UIDs,
    //       report_submit_time: data.report_submit_time,
    //     }));

    //   const reportData =
    //     assignNamesAndModality &&
    //     assignNamesAndModality?.find(item => item.study_UIDs === data?.studyInstanceUid);

    //   const study =
    //     allStudyList?.length > 0 &&
    //     allStudyList?.find(
    //       studyData => studyData.MainDicomTags?.StudyInstanceUID === data?.studyInstanceUid
    //     );
    //   const institutionName = study ? study.MainDicomTags.InstitutionName : '';
    //   const lastUpdate = study ? study.LastUpdate : '';
    //   const firstUpdate = study ? study.MainDicomTags.StudyDate : '';
    //   const firstUpdateTime = study ? study.MainDicomTags.StudyTime : '';
    //   const studyDescription = study ? study.MainDicomTags.StudyDescription : '';
    //   const physicianName = study ? study.MainDicomTags.ReferringPhysicianName : '';
    //   const patientDob = study ? study.PatientMainDicomTags?.PatientBirthDate : '';
    //   const acquisitionDate = study ? study.AcquisitionDate : '';
    //   const acquisitionTime = study ? study.AcquisitionTime : '';
    //   const patientNames = study ? study?.patientName : '';

    //   const studyID = study ? study?.ID : '';

    //   const patientID = study ? study.PatientMainDicomTags?.PatientID : '';

    //   const aeT = study ? study.MainDicomTags?.AET : '';

    //   const currentTime: any = moment();
    //   const lastUpdateTime: any = moment(lastUpdate);
    //   const timeDifferenceInSeconds = currentTime.diff(lastUpdateTime, 'seconds');

    //   // const dob: any = moment(patientDob).format('MMM-DD-YYYY');
    //   const dob = patientDob ? moment(patientDob).format('MMM-DD-YYYY') : '';

    //   const patientAge =
    //     data.age || study?.PatientMainDicomTags?.PatientName.match(/\d/g)?.join('');

    //   const assign_time: any =
    //     reportData?.assign_time &&
    //     Array.isArray(reportData.assign_time) &&
    //     reportData.assign_time[0] &&
    //     new Date(reportData.assign_time[0]);
    //   const report_submit_time: any = new Date(reportData && reportData?.report_submit_time);

    //   // Calculate the difference in milliseconds
    //   const timeDifference = report_submit_time - assign_time;

    //   const differenceInSeconds = Math.floor(timeDifference / 1000);
    //   const differenceInMinutes = Math.floor(differenceInSeconds / 60);

    //   const hours = Math.floor(differenceInMinutes / 60);
    //   const minutes = differenceInMinutes % 60;

    //   return {
    //     ...data,
    //     institutionname: institutionName,
    //     lastUpdate: timeDifferenceInSeconds < thresholdInSeconds ? 'Not Expired' : 'Expired',
    //     studyDescription,
    //     physicianName,
    //     firstUpdateImage: `${firstUpdate} ${firstUpdateTime}`,
    //     lastUpdateImage: lastUpdate,
    //     timeDifferenceInSeconds,
    //     patientBirthDate: dob,
    //     patientNames,
    //     patientAge,
    //     studyID,
    //     acquisitionDateTime: `${acquisitionDate} ${acquisitionTime}`,
    //     studyTat: `${hours} h ${minutes < 10 ? '0' : ''}${minutes} m`,
    //     patientId: patientID,
    //     aet: aeT,
    //   };
    // });

    // let findAssign;
    // const findData =
    //   patientReportsDetails &&
    //   patientReportsDetails?.filter(item => {
    //     const assignArray = item.assign?.map(assign => JSON.parse(assign));
    //     return assignArray?.some(
    //       assign => assign.assign_name === user?.profile?.preferred_username
    //     );
    //   });

    const findData =
      patientReportsDetails &&
      patientReportsDetails?.filter(item => {
        // Ensure assign exists and is an array
        if (!item.assign || !Array.isArray(item.assign)) {
          return false;
        }

        // Parse each 'assign' entry and filter out invalid ones
        const assignArray = item.assign
          .map(assign => {
            try {
              return JSON.parse(assign);
            } catch (error) {
              // Handle invalid JSON entries by returning null
              console.error('Invalid JSON in assign:', assign, error);
              return null;
            }
          })
          .filter(assign => assign !== null); // Remove null entries

        // Check if any assign_name matches the user's preferred username
        return assignArray.some(
          assign => assign.assign_name === user?.profile?.preferred_username
        );
      });


    // if (findData?.length > 0) {
    //   findAssign = findData.flatMap(e => e.assign?.map(item => JSON.parse(item)));
    // }
    const studyUIDs = findData && findData?.map(item => item.study_UIDs);

    const filteredObjects = first && first?.filter(item => studyUIDs.includes(item.studyInstanceUid));

    if (
      token?.realm_access?.roles.includes('super-admin') ||
      token?.realm_access?.roles.includes('deputy-admin') ||
      user?.profile?.roleType === 'Physician' ||
      user?.profile?.roleType === 'TelerappManager' ||
      user?.profile?.roleType === 'DeputyAdmin'
    ) {
      setAllStudies(first);
    } else if (
      user?.profile?.roleType === 'Radiologist' ||
      // user?.profile?.roleType === 'Physician' ||
      user?.profile?.roleType === 'QaUsers'
    ) {
      setAllStudies(filteredObjects);
    } else {
      setAllStudies(first);
    }
  }, [studies, allStudyList, thresholdInSeconds, patientReportsDetails, user?.profile?.roleType, first]);

  useEffect(() => {
    const interval = setInterval(() => {
      setAllStudies(prevStudies => {
        return prevStudies.map(data => {
          if (data?.timeDifferenceInSeconds < thresholdInSeconds) {
            const t = data?.studyInstanceUid;
            const oldTime = localStorage.getItem(t);

            if (oldTime) {
              const updatedTimeDifferenceInSeconds = Math.max(Number(oldTime) - 1, 0);
              const t = data?.studyInstanceUid;
              localStorage.setItem(t, updatedTimeDifferenceInSeconds?.toString());

              return { ...data, timeDifferenceInSeconds: updatedTimeDifferenceInSeconds };
            } else {
              const updatedTimeDifferenceInSeconds = Math.max(data?.timeDifferenceInSeconds - 1, 0);
              const t = data?.studyInstanceUid;
              localStorage.setItem(t, timeInSeconds?.toString());

              return { ...data, timeDifferenceInSeconds: updatedTimeDifferenceInSeconds };
            }
          }
          return data;
        });
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [thresholdInSeconds, timeInSeconds, resDatas]);

  const handleTATCounter = studyInstanceUid => {
    const findTime = allStudies
      .filter(data => data?.studyInstanceUid === studyInstanceUid)
      .map(data => {
        if (data?.timeDifferenceInSeconds < thresholdInSeconds) {
          const t = data?.studyInstanceUid;
          const oldTime = localStorage.getItem(t);
          const newTime = oldTime ? parseInt(oldTime) : thresholdInSeconds;

          localStorage.setItem(t, newTime?.toString());
          return formatTime(newTime);
        }
        return null;
      })
      .filter(time => time !== null);

    return findTime;
  };

  // Set body style
  // useEffect(() => {
  //   document.body.classList.add('bg-black');
  //   return () => {
  //     document.body.classList.remove('bg-black');
  //   };
  // }, []);

  // Sync URL query parameters with filters
  useEffect(() => {
    if (!debouncedFilterValues) {
      return;
    }

    const queryString = {};
    Object.keys(defaultFilterValues).forEach(key => {
      const defaultValue = defaultFilterValues[key];
      const currValue = debouncedFilterValues[key];

      // TODO: nesting/recursion?
      if (key === 'studyDate' || key === 'acquisitiondate') {
        if (currValue.startDate && defaultValue.startDate !== currValue.startDate) {
          queryString.startDate = currValue.startDate;
        }
        if (currValue.endDate && defaultValue.endDate !== currValue.endDate) {
          queryString.endDate = currValue.endDate;
        }
      } else if (key === 'sex' && currValue.length) {
        queryString.sex = currValue?.join(',');
      } else if (key === 'assign') {
        queryString.assign = currValue;
      } else if (key === 'priority' && currValue?.length) {
        queryString.priority = currValue?.join(',');
      } else if (key === 'general') {
        queryString.general = currValue;
      } else if (key === 'modality' && currValue?.length) {
        queryString.modality = currValue?.join(',');
      } else if (currValue !== defaultValue) {
        queryString[key] = currValue;
      }
    });

    const search = qs.stringify(queryString, {
      skipNull: true,
      skipEmptyString: true,
    });

    navigate({
      pathname: '/',
      search: search ? `?${search}` : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilterValues]);

  // Query for series information
  useEffect(() => {
    const fetchSeries = async studyInstanceUid => {
      try {
        const series = await dataSource.query.series.search(studyInstanceUid);
        seriesInStudiesMap.set(studyInstanceUid, sortBySeriesDate(series));
        setStudiesWithSeriesData([...studiesWithSeriesData, studyInstanceUid]);
      } catch (ex) {
        // TODO: UI Notification Service
        console.warn(ex);
      }
    };

    // TODO: WHY WOULD YOU USE AN INDEX OF 1?!
    // Note: expanded rows index begins at 1
    for (let z = 0; z < expandedRows?.length; z++) {
      const expandedRowIndex = expandedRows[z] - 1;
      const studyInstanceUid = allStudies[expandedRowIndex]?.studyInstanceUid;

      if (studiesWithSeriesData.includes(studyInstanceUid)) {
        continue;
      }

      fetchSeries(studyInstanceUid);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedRows, studies]);

  const isFiltering = (filterValues, defaultFilterValues) => {
    return !isEqual(filterValues, defaultFilterValues);
  };

  const [selectedReportStatusMap, setSelectedReportStatusMap] = useState({});

  const handleSelectedReportStatusChange = async ({ reportStatus, studyInstanceUid, institutionName, studyID }) => {
    if (reportStatus === 'Delete') {
      const patientData = patientReportsDetails.find(item => item.study_UIDs === studyInstanceUid);

      const actionlog = 'DeleteLogs'

      Swal.fire({
        text: 'Are you sure to delete this user!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete it!',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      }).then(async result => {
        if (result.isConfirmed) {
          await deletePatientReport(patientData.id, patientData, setPatientReportsDetails, username, actionlog, institutionName)
            .then(async res => {
              Swal.fire({
                title: 'Deleted',
                text: 'User has been Delete Successfully',
                icon: 'success',
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false,
              });
            })
            .catch(error => {
              console.error('Error deleting user:', error);
              Swal.fire({
                title: 'Error!',
                text: 'Failed to delete user. Please try again later.',
                icon: 'error',
                confirmButtonText: 'OK',
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire({
            text: 'User Delete Cancel.',
            icon: 'info',
            confirmButtonText: 'OK',
          });
        }
      });
    }

    if (reportStatus === 'Addendum') {
      const patientData = patientReportsDetails.find(item => item.study_UIDs === studyInstanceUid);

      const actionlog = 'AddendumLogs'

      const resData = {
        ...patientData,
        document_status: 'Addendum',
        radiologyGroup: user?.profile?.radiologyGroup,
      };

      if (!patientData) {
        const newData = {
          document_status: 'Addendum',
          study_UIDs: studyInstanceUid,
          study_IDS: studyID,
          radiologyGroup: user?.profile?.radiologyGroup,
        };
        await createPatientReports(newData, setPatientReportsDetails, username, actionlog, institutionName);
      } else {
        await updatePatientReports(patientData.id, resData, setPatientReportsDetails, username, actionlog, institutionName);
      }
    }

    if (reportStatus === 'Approved') {
      const patientData = patientReportsDetails.find(item => item.study_UIDs === studyInstanceUid);

      const actionlog = 'ApproveLogs'

      const resData = {
        ...patientData,
        document_status: 'Approved',
        radiologyGroup: user?.profile?.radiologyGroup,
      };

      if (!patientData) {
        const newData = {
          document_status: 'Approved',
          study_UIDs: studyInstanceUid,
          study_IDS: studyID,
          radiologyGroup: user?.profile?.radiologyGroup,
        };
        await createPatientReports(newData, setPatientReportsDetails, username, actionlog, institutionName);
      } else {
        await updatePatientReports(patientData.id, resData, setPatientReportsDetails, username, actionlog, institutionName);
      }
    }
    const patientData = patientReportsDetails.find(item => item.study_UIDs === studyInstanceUid);

    setSelectedReportStatusMap(prevMap => ({
      ...prevMap,
      [studyInstanceUid]: reportStatus === 'Delete' ? patientData.document_status : reportStatus,
    }));
  };

  const handleDocument = (studyInstanceUid, institutionName, studyID) => {
    show({
      content: DocumentStatusModal,
      title: t('ReportStatus:Report Status'),
      contentProps: { hide, studyInstanceUid, institutionName, studyID, handleSelectedReportStatusChange },
    });
  };

  const handleDocumentViewer = async (studyInstanceUid, reportdetails, patientName) => {
    // console.log(reportdetails)
    const patientData = patientReportsDetails.find(item => item.study_UIDs === studyInstanceUid);

    const studyList = allStudies.find(
      data => data?.studyInstanceUid === studyInstanceUid
    );

    reportSetting = reportSettingDetails.find(item => item.group_name === studyList?.institutionname)

    // console.log(reportSetting)

    const reportSubmit_time =
      patientData?.report_submit_time && new Date(patientData?.report_submit_time);
    let formattedTime;
    if (reportSubmit_time) {
      formattedTime = `
    ${reportSubmit_time?.toLocaleDateString('en-US', { month: 'long' })}
    ${reportSubmit_time?.getDate()},
    ${reportSubmit_time?.getFullYear()}
    ${reportSubmit_time?.getHours()}:${('0' + reportSubmit_time?.getMinutes())?.slice(-2)}:${('0' + reportSubmit_time?.getSeconds())?.slice(-2)} GMT${reportSubmit_time?.getTimezoneOffset() > 0 ? '-' : '+'}${('0' + Math.abs(reportSubmit_time?.getTimezoneOffset() / 60))?.slice(-2)}:${('0' + Math.abs(reportSubmit_time?.getTimezoneOffset() % 60))?.slice(-2)}`;
    }
    const findAssignUserName = [patientData?.firstAssignUser];
    const assignUserDataFind = usersList?.find(item => {
      return findAssignUserName?.includes(item.username);
    });
    // Doctore details footer
    const firstName = assignUserDataFind ? `${assignUserDataFind?.firstName} ${assignUserDataFind?.lastName}` : '';
    const qualification =
      assignUserDataFind?.attributes.qualification !== undefined
        ? assignUserDataFind?.attributes.qualification
        : '';
    const registrationNo =
      assignUserDataFind &&
        assignUserDataFind?.attributes &&
        assignUserDataFind?.attributes.registrationNo
        ? assignUserDataFind.attributes.registrationNo
        : '';
    const formattedTimes = formattedTime === undefined ? '' : formattedTime;
    const disclaimerDetails =
      reportSetting && reportSetting.disclaimer_details ? reportSetting.disclaimer_details : '';
    const output = `
    ${firstName}<br/>
    ${qualification}<br/>
    ${registrationNo ? `Reg.No. :- ${registrationNo}` : ''}<br/>
    ${formattedTimes}<br/>
    ${disclaimerDetails ? `Disclaimer :- ${disclaimerDetails}` : ''}
`;
    const reportDataStyle = `
        margin-top: ${reportSetting?.top}px;
        margin-left: ${reportSetting?.left}px;
        margin-right: ${reportSetting?.right}px;
        margin-bottom: ${reportSetting?.bottom}px;
       font-family: ${reportSetting?.font_style};
       font-size: ${reportSetting?.font_size}px !important;
       line-height: ${reportSetting?.line_spacing};
   `;
    const pageStyle = `
    @media print {
      thead {display: table-header-group;}
      tfoot {display: table-footer-group;}
      button {display: none;}
      body {margin: 0;}
  }`;
    const signatureStyle = `
            width: 200px;
            height: 100px;
        `;

    const updatedReportDetails0 = reportdetails.replace('"border-collapse: collapse;"', '"border-collapse: collapse; width: 100%;"');
    const updatedReportDetails = updatedReportDetails0.replace('border="1px;"', 'border="1"');
    const modifiedEditor = `
      <div style="${pageStyle}">
              <table>
              <thead>
                <tr>
                  <td>
                    <!--place holder for the fixed-position header-->
                    <div></div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>
                      <div style="${reportDataStyle}">
                        ${updatedReportDetails}
                         <div style="margin-top: 10px;line-height:1 !important;">
                          ${reportSetting?.signature && assignUserDataFind?.attributes?.uploadSignature && assignUserDataFind ? `<img src="${assignUserDataFind?.attributes?.uploadSignature}" alt="signature" style="${signatureStyle}" />` : ''} <br/>
                          ${output}
                        </div >
                        </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <div></div>
                  </td>
                </tr>
              </tfoot>
              </table>
      </div>
    `;
    if (patientData && patientData.document_status === 'Approved') {
      if (reportdetails) {
        // console.log(modifiedEditor)
        // console.log(assignUserDataFind)

        await generateReportWord(modifiedEditor, patientName);
      } else {
        toast.error('Patient Report Not Found');
      }
    } else {
      toast.error('Document is not approved for download');
    }
  };


  //   const handlePDFViewer = async (studyInstanceUid, reportdetails, patientName) => {
  //     const patientData = patientReportsDetails.find(item => item.study_UIDs === studyInstanceUid);

  //     const reportSubmit_time =
  //       patientData?.report_submit_time && new Date(patientData?.report_submit_time);

  //     let formattedTime;
  //     if (reportSubmit_time) {
  //       formattedTime = `
  //     ${reportSubmit_time?.toLocaleDateString('en-US', { month: 'long' })}
  //     ${reportSubmit_time?.getDate()},
  //     ${reportSubmit_time?.getFullYear()}
  //     ${reportSubmit_time?.getHours()}:${('0' + reportSubmit_time?.getMinutes())?.slice(-2)}:${('0' + reportSubmit_time?.getSeconds())?.slice(-2)} GMT${reportSubmit_time?.getTimezoneOffset() > 0 ? '-' : '+'}${('0' + Math.abs(reportSubmit_time?.getTimezoneOffset() / 60))?.slice(-2)}:${('0' + Math.abs(reportSubmit_time?.getTimezoneOffset() % 60))?.slice(-2)}`;
  //     }

  //     const findAssignUserName = [patientData?.firstSubmitUser];
  //     const assignUserDataFind = usersList?.find(item => {
  //       return findAssignUserName?.includes(item.username);
  //     });

  //     // Doctore details footer
  //     const firstName = assignUserDataFind ? `${assignUserDataFind?.username}` : '';
  //     const qualification =
  //       assignUserDataFind?.attributes.qualification !== undefined
  //         ? assignUserDataFind?.attributes.qualification
  //         : '';
  //     const registrationNo =
  //       assignUserDataFind &&
  //         assignUserDataFind?.attributes &&
  //         assignUserDataFind?.attributes.registrationNo
  //         ? assignUserDataFind.attributes.registrationNo
  //         : '';
  //     const formattedTimes = formattedTime === undefined ? '' : formattedTime;
  //     const disclaimerDetails =
  //       reportSetting && reportSetting.disclaimer_details ? reportSetting.disclaimer_details : '';

  //     const output = `
  //     ${firstName}<br/>
  //     ${qualification}<br/>
  //     ${registrationNo ? `Reg.No. :- ${registrationNo}` : ''}<br/>
  //     ${formattedTimes}<br/>
  //     ${disclaimerDetails ? `Disclaimer :- ${disclaimerDetails}` : ''}
  // `;
  //     const headerStyle = `
  //     width: 98%;
  //     z-index: 1;
  //     padding-right: 10px;
  //     height: ${reportSetting?.height}px;
  //     `;

  //     const pageHeader = `
  //     position: fixed;
  //     top: 0mm;
  //     width: 100%;


  //   `;
  //     const pageFooter = `
  //   position: fixed;
  //   bottom: 0;
  //   width: 100%;

  // `;

  //     const watermarkStyle = `
  // position: fixed;
  // width: 100%;
  // height: auto;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // opacity: 0.6;
  // margin-top: 50px;
  // margin-bottom: 50px;
  // z-index: -1;
  // `;

  //     const watermarkTextStyle = `
  //             font-size: 80px;
  //             color: #999;
  //         `;

  //     const reportDataStyle = `
  //         margin-top: ${reportSetting?.top}px;
  //         margin-left: ${reportSetting?.left}px;
  //         margin-right: ${reportSetting?.right}px;
  //         margin-bottom: ${reportSetting?.bottom}px;
  //        font-family: ${reportSetting?.font_style};
  //        font-size: ${reportSetting?.font_size}px !important;
  //        line-height: ${reportSetting?.line_spacing};
  //    `;

  //     const pageStyle = `

  //     @media print {
  //       thead {display: table-header-group;}
  //       tfoot {display: table-footer-group;}

  //       button {display: none;}

  //       body {margin: 0;}
  //   }`;

  //     // const modifiedEditor = `
  //     //   <div style="${pageStyle}">
  //     //     <div class="page-header" style="${pageHeader}">
  //     //     ${reportSetting?.include_header && reportSetting?.header_image
  //     //     ? `
  //     //           <img src="${reportSetting?.header_image}"  alt="Header" style="${headerStyle}" />
  //     //           `
  //     //     : ''
  //     //   }
  //     //       <div style="${reportDataStyle}">${reportdetails}</div>
  //     //     </div>

  //     //     <div class="page-footer" style="${pageFooter}">
  //     //       ${reportSetting?.include_footer && reportSetting?.footer_image
  //     //     ? `<img src="${reportSetting?.footer_image}" alt="Footer" style="${headerStyle}" />`
  //     //     : ''
  //     //   }
  //     //     </div>

  //     //      ${reportSetting?.include_watermark && reportSetting?.watermark_image
  //     //     ? `
  //     //           <div style="${watermarkStyle}" class="watermark">
  //     //             <span style="${watermarkTextStyle}" class="watermark-text"><img src="${reportSetting?.watermark_image}" alt="watermark" width="100%" height="auto" /> </span>
  //     //           </div>
  //     //           `
  //     //     : ''
  //     //   }
  //     //   </div>
  //     // `;

  //     let pageHeaderSpace;
  //     if (reportSetting?.patient_details_in_header) {
  //       pageHeaderSpace = `
  //       height: ${reportSetting?.height}px;
  //     `;
  //     } else {
  //       pageHeaderSpace = `
  //       height: ${reportSetting?.height}px;
  //     `;
  //     }

  //     const pageFooterSpace = `
  //   height: ${reportSetting?.height}px;
  //   `;
  //     const signatureStyle = `
  //             width: 200px;
  //             height: 100px;
  //         `;
  //     const modifiedEditor = `
  //       <div style="${pageStyle}">
  //         <div class="page-header" style="${pageHeader}">
  //         ${reportSetting?.include_header && reportSetting?.header_image
  //         ? `
  //               <img src="${reportSetting?.header_image}"  alt="Header" style="${headerStyle}" />
  //               `
  //         : ''
  //       }
  //         </div>

  //         <div class="page-footer" style="${pageFooter}">
  //           ${reportSetting?.include_footer && reportSetting?.footer_image
  //         ? `
  // <img src="${reportSetting?.footer_image}" alt="Footer" style="${headerStyle}" />`
  //         : ''
  //       }
  //         </div>

  //               <table>

  //               <thead>
  //                 <tr>
  //                   <td>
  //                     <!--place holder for the fixed-position header-->
  //                     <div style="${pageHeaderSpace}" class="page-header-space"></div>
  //                   </td>
  //                 </tr>
  //               </thead>

  //               <tbody>
  //                 <tr>
  //                   <td>
  //                     <div>
  //                       <div style="${reportDataStyle}">
  //                         ${reportdetails}
  //                                                 <div style="margin-top: 10px;line-height:1 !important;">
  //                           ${reportSetting?.signature && assignUserDataFind?.attributes?.uploadSignature && assignUserDataFind ? `<img src="${assignUserDataFind?.attributes?.uploadSignature[0]}" alt="signature" style="${signatureStyle}" />` : ''} <br/>
  //                           ${output}
  //                         </div >
  //                         </div>

  //                     </div>
  //                   </td>
  //                 </tr>
  //               </tbody>

  //               <tfoot>
  //                 <tr>
  //                   <td>

  //                     <div class="page-footer-space" style="${pageFooterSpace}"></div>
  //                   </td>
  //                 </tr>
  //               </tfoot>

  //               </table>
  //               ${reportSetting?.include_watermark && reportSetting?.watermark_image
  //         ? `
  //               <div style="${watermarkStyle}" class="watermark">
  //                 <span style="${watermarkTextStyle}" class="watermark-text"><img src="${reportSetting?.watermark_image}" alt="watermark" width="100%" height="auto" /> </span>
  //               </div>
  //               `
  //         : ''
  //       }
  //       </div>
  //     `;

  //     const notDownload = true;
  //     // const setIsLoading = false;

  //     if (patientData && patientData.document_status === 'Approved') {
  //       if (reportdetails) {
  //         await generateReportPdf(modifiedEditor, setIsLoading, patientName, notDownload);
  //       } else {
  //         toast.error('Patient Report Not Found');
  //       }
  //     } else {
  //       toast.error('Document is not approved for download');
  //     }
  //   };

  // attachment

  const handleDownloadPdf = async (studyInstanceUid, reportdetails, patientName) => {
    const patientData = patientReportsDetails.find(item => item.study_UIDs === studyInstanceUid)

    const studyList = allStudies.find(
      data => data?.studyInstanceUid === studyInstanceUid
    );

    reportSetting = reportSettingDetails.find(item => item.group_name === studyList?.institutionname)

    // console.log(reportSetting)

    // const findAssignUserName = [patientData?.firstAssignUser];
    // const assignUserDataFind = usersList?.find(item => {
    //   return findAssignUserName?.includes(item.username);
    // });

    const reportSubmit_time =
      patientData?.report_submit_time && new Date(patientData?.report_submit_time);

    let formattedTime;
    if (reportSubmit_time) {
      formattedTime = `
    ${reportSubmit_time?.toLocaleDateString('en-US', { month: 'long' })}
    ${reportSubmit_time?.getDate()},
    ${reportSubmit_time?.getFullYear()}
    ${reportSubmit_time?.getHours()}:${('0' + reportSubmit_time?.getMinutes())?.slice(-2)}:${('0' + reportSubmit_time?.getSeconds())?.slice(-2)} GMT${reportSubmit_time?.getTimezoneOffset() > 0 ? '-' : '+'}${('0' + Math.abs(reportSubmit_time?.getTimezoneOffset() / 60))?.slice(-2)}:${('0' + Math.abs(reportSubmit_time?.getTimezoneOffset() % 60))?.slice(-2)}`;
    }

    const findAssignUserName = [patientData?.firstSubmitUser];
    const assignUserDataFind = usersList?.find(item => {
      return findAssignUserName?.includes(item.username);
    });

    // Doctore details footer
    const firstName = assignUserDataFind ? `${assignUserDataFind?.firstName} ${assignUserDataFind?.lastName}` : '';
    const qualification =
      assignUserDataFind?.attributes.qualification !== undefined
        ? assignUserDataFind?.attributes.qualification
        : '';
    const registrationNo =
      assignUserDataFind &&
        assignUserDataFind?.attributes &&
        assignUserDataFind?.attributes.registrationNo
        ? assignUserDataFind.attributes.registrationNo
        : '';
    const formattedTimes = formattedTime === undefined ? '' : formattedTime;
    const disclaimerDetails =
      reportSetting && reportSetting.disclaimer_details ? reportSetting.disclaimer_details : '';

    const output = `
    ${firstName}<br/>
    ${qualification}<br/>
    ${registrationNo ? `Reg.No. :- ${registrationNo}` : ''}<br/>
    ${formattedTimes}<br/>
    ${disclaimerDetails ? `Disclaimer :- ${disclaimerDetails}` : ''}
`;

    const headerStyle = `
    width: 98%;
    z-index: 1;
    padding-right: 10px;
    height: ${reportSetting?.height}px;
    `;

    const pageHeader = `
    position: fixed;
    top: 0mm;
    width: 100%;


  `;
    const pageFooter = `
  position: fixed;
  bottom: 0;
  width: 100%;

`;

    const watermarkStyle = `
position: fixed;
width: 100%;
height: auto;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
opacity: 0.6;
margin-top: 50px;
margin-bottom: 50px;
z-index: -1;
`;

    const watermarkTextStyle = `
            font-size: 80px;
            color: #999;
        `;

    const reportDataStyle = `
        margin-top: ${reportSetting?.top}px;
        margin-left: ${reportSetting?.left}px;
        margin-right: ${reportSetting?.right}px;
        margin-bottom: ${reportSetting?.bottom}px;
       font-family: ${reportSetting?.font_style};
       font-size: ${reportSetting?.font_size}px !important;
       line-height: ${reportSetting?.line_spacing};
   `;

    const pageStyle = `

    @media print {
      thead {display: table-header-group;}
      tfoot {display: table-footer-group;}

      button {display: none;}

      body {margin: 0;}
  }`;

    const signatureStyle = `
            width: 200px;
            height: 100px;
        `;

    // const modifiedEditor = `
    //   <div style="${pageStyle}">
    //     <div class="page-header" style="${pageHeader}">
    //     ${
    //       reportSetting?.include_header && reportSetting?.header_image
    //         ? `
    //           <img src="${reportSetting?.header_image}"  alt="Header" style="${headerStyle}" />
    //           `
    //         : ''
    //     }
    //       <div style="${reportDataStyle}">${reportdetails}</div>
    //     </div>

    //     <div class="page-footer" style="${pageFooter}">
    //       ${
    //         reportSetting?.include_footer && reportSetting?.footer_image
    //           ? `<img src="${reportSetting?.footer_image}" alt="Footer" style="${headerStyle}" />`
    //           : ''
    //       }
    //     </div>

    //      ${
    //        reportSetting?.include_watermark && reportSetting?.watermark_image
    //          ? `
    //           <div style="${watermarkStyle}" class="watermark">
    //             <span style="${watermarkTextStyle}" class="watermark-text"><img src="${reportSetting?.watermark_image}" alt="watermark" width="100%" height="auto" /> </span>
    //           </div>
    //           `
    //          : ''
    //      }
    //   </div>
    // `;
    let pageHeaderSpace;
    if (reportSetting?.patient_details_in_header) {
      pageHeaderSpace = `
      height: ${reportSetting?.height}px;
    `;
    } else {
      pageHeaderSpace = `
      height: ${reportSetting?.height}px;
    `;
    }

    const pageFooterSpace = `
  height: ${reportSetting?.height}px;
  `;
    const modifiedEditor = `
      <div style="${pageStyle}">
        <div class="page-header" style="${pageHeader}">
        ${reportSetting?.include_header && reportSetting?.header_image
        ? `
              <img src="${reportSetting?.header_image}"  alt="Header" style="${headerStyle}" />
              `
        : ''
      }
        </div>

        <div class="page-footer" style="${pageFooter}">
          ${reportSetting?.include_footer && reportSetting?.footer_image
        ? `
<img src="${reportSetting?.footer_image}" alt="Footer" style="${headerStyle}" />`
        : ''
      }
        </div>

              <table>

              <thead>
                <tr>
                  <td>
                    <!--place holder for the fixed-position header-->
                    <div style="${pageHeaderSpace}" class="page-header-space"></div>
                  </td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <div>
                      <div style="${reportDataStyle}">
                        ${reportdetails}
                         <div style="margin-top: 10px;line-height:1 !important;">
                          ${reportSetting?.signature && assignUserDataFind?.attributes?.uploadSignature && assignUserDataFind ? `<img src="${assignUserDataFind?.attributes?.uploadSignature}" alt="signature" style="${signatureStyle}" />` : ''} <br/>
                          ${output}
                        </div >
                        </div>

                    </div>
                  </td>
                </tr>
              </tbody>

              <tfoot>
                <tr>
                  <td>

                    <div class="page-footer-space" style="${pageFooterSpace}"></div>
                  </td>
                </tr>
              </tfoot>

              </table>
              ${reportSetting?.include_watermark && reportSetting?.watermark_image
        ? `
              <div style="${watermarkStyle}" class="watermark">
                <span style="${watermarkTextStyle}" class="watermark-text"><img src="${reportSetting?.watermark_image}" alt="watermark" width="100%" height="auto" /> </span>
              </div>
              `
        : ''
      }
      </div>
    `;

    const notDownload = false;
    // const setIsLoading = false;
    if (patientData && patientData.document_status === 'Approved') {
      if (reportdetails) {
        // console.log(modifiedEditor)
        await generateReportPdf(modifiedEditor, setIsLoading, patientName, notDownload);
      } else {
        toast.error('Patient Report Not Found');
      }
    } else {
      toast.error('Document is not approved for download');
    }
  };

  const handleAttachmentChange = async (studyInstanceUid, attachmentData) => {
    const data = documentUploadDetails?.find(item => item.study_UIDs === studyInstanceUid);

    if (!data) {
      await createDocument(studyInstanceUid, attachmentData, setDocumentUploadDetails);
    } else {
      await updateDocument(data.id, attachmentData, setDocumentUploadDetails);
    }
  };

  const handleAttachmentRemove = async (attachment, instance, studyInstanceUid) => {
    const updateDocumnet = attachment?.filter(item => item.attachment !== instance);
    const data = documentUploadDetails?.find(item => item.study_UIDs === studyInstanceUid);

    const pattern = /\d+-([^/]+)$/;

    // const pattern = /\/(\d+-([\w-]+\.pdf))$/;

    const removeDocumentName = instance.match(pattern);

    const resData = {
      ...data,
      updateData: updateDocumnet && updateDocumnet?.length > 0 ? updateDocumnet : null,
      removeDocument: removeDocumentName[0]?.replaceAll('/', ''),
    };

    await deleteDocumentUrl(data.id, resData, setDocumentUploadDetails);
  };

  const handleAttachment = (studyInstanceUid, patientName) => {
    show({
      content: AddAttachmentModel,
      title: t('ReportStatus:Attachment'),
      contentProps: {
        hide,
        studyInstanceUid,
        handleAttachmentChange,
        handleAttachmentRemove,
        documentUploadDetails,
        patientName,
        modelOpen: show
      },
    });
  };

  // assign user
  const [selectedUserDataMap, setSelectedUserDataMap] = useState({});

  const handleSelectedUserDataChange = async ({ userName, currentTime, studyInstanceUid, modality, patientId, accession, institutionName, studyID }) => {
    const data = patientReportsDetails.find(item => item.study_UIDs === studyInstanceUid);

    const actionlog = 'AssignLogs'

    if (!data) {
      const newData = {
        assign: [
          { assign_name: userName, assign_time: currentTime, assign_by: user?.profile?.name },
        ],
        study_UIDs: studyInstanceUid,
        study_IDS: studyID,
        radiologyGroup: user?.profile?.radiologyGroup,
        document_status: "assign",
        patient_modality: modality,
        firstAssignUser: userName,
        patient_id: patientId,
        patient_accession: accession
      };
      await createPatientReports(newData, setPatientReportsDetails, username, actionlog, institutionName);
    } else {
      const updatedAssign = [
        ...(data.assign || []),
        { assign_name: userName, assign_time: currentTime, assign_by: user?.profile?.name },
      ];
      const resData = {
        ...data,
        assign: updatedAssign,
        radiologyGroup: user?.profile?.radiologyGroup
      };
      await updatePatientReports(data.id, resData, setPatientReportsDetails, username, actionlog, institutionName);
    }

    setSelectedUserDataMap(prevUser => ({
      ...prevUser,
      [studyInstanceUid]: userName,
    }));
  };

  const handleSelectedUserRemove = async ({ assignNameConavat, studyInstanceUid, instance, patientId, accession, institutionName, studyID }) => {
    const updateUser = assignNameConavat.flatMap(item =>
      item.filter(e => e.assign_name !== instance.username)
    );

    const data = patientReportsDetails.find(item => item.study_UIDs === studyInstanceUid);

    const actionlog = 'UnassignLogs'

    const resData = {
      ...data,
      assign: updateUser && updateUser?.length > 0 ? updateUser : null,
      patient_id: patientId,
      patient_accession: accession
    };

    await updatePatientReports(data.id, resData, setPatientReportsDetails, username, actionlog, institutionName);

    setSelectedUserDataMap(prevUser => {
      const updatedUserMap = { ...prevUser };

      delete updatedUserMap[studyInstanceUid];

      return updatedUserMap;
    });
  };

  const handleAssign = (studyInstanceUid, modality, patientId, accession, patientName, uuid, description, institutionName, studyID) => {
    show({
      content: AddRadiologistModel,
      title: t('ReportStatus:Assign Radiologist '),
      contentProps: {
        user,
        token,
        hide,
        studyInstanceUid,
        studyID,
        modality,
        usersList,
        handleSelectedUserDataChange,
        handleSelectedUserRemove,
        patientReportsDetails,
        patientId,
        accession,
        patientName,
        uuid,
        description,
        institutionName
      },
    });
  };

  // Clinical History
  const handleClinicalHistoryChange = async (studyInstanceUid, clinicalData, patientId, accession, institutionName) => {
    const data = patientReportsDetails.find(item => item.study_UIDs === studyInstanceUid);

    const actionlog = 'HistoryLogs'

    if (!data) {
      const newData = {
        clinical_history: clinicalData,
        study_UIDs: studyInstanceUid,
        radiologyGroup: user?.profile?.radiologyGroup,
        patient_id: patientId,
        patient_accession: accession
      };
      await createPatientReports(newData, setPatientReportsDetails, username, actionlog, institutionName);
    } else {
      const resData = {
        ...data,
        clinical_history: clinicalData,
        radiologyGroup: user?.profile?.radiologyGroup,
      };
      await updatePatientReports(data.id, resData, setPatientReportsDetails, username, actionlog, institutionName);
    }
  };

  const handleClinicalHistory = (studyInstanceUid, patientId, accession, patientName) => {
    show({
      content: AddClinicalHistoryModel,
      title: t('ReportStatus:Clinical History'),
      contentProps: {
        hide,
        studyInstanceUid,
        handleClinicalHistoryChange,
        patientReportsDetails,
        patientId,
        accession,
        patientName
      },
    });
  };

  // Report Status
  const [reportStatusMap, setReportStatusMap] = useState({});
  const handleSelectReportsStatusChanges = async ({ reportStatus, studyInstanceUid, patientId, accession, institutionName, studyID }) => {
    if (reportStatus === 'Approved') {
      const patientData = patientReportsDetails.find(item => item.study_UIDs === studyInstanceUid);

      const actionlog = 'StatusLogs'

      const resData = {
        ...patientData,
        report_status: 'Approved',
        radiologyGroup: user?.profile?.radiologyGroup,
      };

      if (!patientData) {
        const newData = {
          report_status: 'Approved',
          study_UIDs: studyInstanceUid,
          study_IDS: studyID,
          radiologyGroup: user?.profile?.radiologyGroup,
          patient_id: patientId,
          patient_accession: accession
        };
        await createPatientReports(newData, setPatientReportsDetails, username, actionlog, institutionName);
      } else {
        await updatePatientReports(patientData.id, resData, setPatientReportsDetails, username, actionlog, institutionName);
      }
    }

    setReportStatusMap(prevMap => ({
      ...prevMap,
      [studyInstanceUid]: reportStatus,
    }));
  };

  const handleReportsStatusChanges = (studyInstanceUid, patientId, accession, institutionName, studyID) => {
    show({
      content: ReportStatusModal,
      title: t('ReportStatus:Report Status '),
      contentProps: {
        hide,
        studyInstanceUid,
        usersList,
        handleSelectReportsStatusChanges,
        patientId,
        accession,
        studyID,
        institutionName
      },
    });
  };

  const handleSharePatientData = async (studyInstanceUid, formData, patientId, accession, institutionName) => {
    const data = patientReportsDetails?.find(item => item.study_UIDs === studyInstanceUid);

    const actionlog = 'ShareLogs';

    if (!data) {
      const newData = {
        study_UIDs: studyInstanceUid,
        phone_number: formData?.patient_phoneNuber,
        patient_email: formData?.patient_email,
        countryCode: formData?.countryCode,
        radiologyGroup: user?.profile?.radiologyGroup,
        patient_id: patientId,
        patient_accession: accession
      };
      await createPatientReports(newData, setPatientReportsDetails, username, actionlog, institutionName);
    } else {
      const resData = {
        ...data,
        phone_number: formData?.patient_phoneNuber,
        patient_email: formData?.patient_email,
        countryCode: formData?.countryCode,
        radiologyGroup: user?.profile?.radiologyGroup,
      };
      await updatePatientReports(data.id, resData, setPatientReportsDetails, username, actionlog, institutionName);
    }
  };

  // share
  const [loadingStates, setLoadingStates] = useState({});

  const handleShare = (studyInstanceUid, patientId, accession, institutionName) => {
    const setIsLoading = (studyInstanceUid, isLoading) => {
      setLoadingStates(prevStates => ({
        ...prevStates,
        [studyInstanceUid]: isLoading,
      }));
    };

    show({
      content: ShareModel,
      title: t('ReportStatus:Share Report and Dicom images'),
      contentProps: {
        user,
        usersList,
        reportSettingDetails,
        hide,
        studyInstanceUid,
        handleSharePatientData,
        patientReportsDetails,
        sendReportData,
        sendReportDataToWhastapp,
        patientId,
        allStudies,
        accession,
        username,
        token,
        institutionName,
        sendImageToSMS,
        sendImageToEmail,
        setIsLoading: isLoading => setIsLoading(studyInstanceUid, isLoading),
      },
    });
  };


  // Delete study of worklist

  const handleStudiesDelete = () => {
    Swal.fire({
      text: 'Are you sure to delete this studies!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then(async result => {
      if (result.isConfirmed) {
        await deleteStudies(selectedRows, setAllStudyList)
          .then(async res => {
            setResDatas(res);
            // setAllStudyList(prevStudies =>
            //   prevStudies.filter(study => !selectedRows.includes(study.studyInstanceUid))
            // );
            const countryinstiution = singleRadiologyGroupList.filter(group => (group?.attributes?.groupCountryid || [0]).map(Number)[0] === countryid.id);

            const countryinstiutionname = countryinstiution.map(instiution => instiution.name)
            fetchMultiLevelFilterData(countryinstiutionname, true);
            // setIsDeleteData(selectedRows);
            // setSelectedRows([]);
            Swal.fire({
              title: 'Deleted',
              text: 'Studies has been Delete Successfully',
              icon: 'success',
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          })
          .catch(error => {
            console.error('Error deleting user:', error);
            Swal.fire({
              title: 'Error!',
              text: 'Failed to delete studies. Please try again later.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          text: 'Studies Delete Cancel.',
          icon: 'info',
          confirmButtonText: 'OK',
        });
      }
    });
  };
  // warning message (not access message)
  const handlerWarning = async (permission) => {
    Swal.fire({
      title: 'Authorization Required',
      text: `You don't have permission to ${permission}.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Proceed',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      background: '#f2f2f2',

    }).then(async result => {
      if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: 'Action Cancelled',
          text: 'Attachment has not been added.',
          icon: 'info',
          confirmButtonText: 'Understood',
          background: '#e0f7fa',
        });
      }
    });
  };



  const handlePriorityChange = async (selectedOption, studyInstanceUid, patientId, accession, institutionName, studyID) => {
    setSelectedPriority(prevPriorities => ({
      ...prevPriorities,
      [studyInstanceUid]: selectedOption.value,
      // [studyInstanceUid]: selectedOption.map(option => option.value),
    }));

    const demo = patientReportsDetails.find(item => item.study_UIDs === studyInstanceUid);

    const actionlog = 'PriorityLogs'

    const data = {
      ...demo,
      study_priority: selectedOption.value,
      radiologyGroup: user?.profile?.radiologyGroup,
    };

    if (!demo) {
      const newData = {
        study_priority: selectedOption.value,
        study_UIDs: studyInstanceUid,
        study_IDS: studyID,
        radiologyGroup: user?.profile?.radiologyGroup,
        patient_id: patientId,
        patient_accession: accession
      };
      await createPatientReports(newData, setPatientReportsDetails, username, actionlog, institutionName);
      const countryinstiution = singleRadiologyGroupList.filter(group => (group?.attributes?.groupCountryid || [0]).map(Number)[0] === countryid.id);

      const countryinstiutionname = countryinstiution.map(instiution => instiution.name)
      fetchMultiLevelFilterData(countryinstiutionname, true);
    } else {
      await updatePatientReports(demo.id, data, setPatientReportsDetails, username, actionlog, institutionName);
      const countryinstiution = singleRadiologyGroupList.filter(group => (group?.attributes?.groupCountryid || [0]).map(Number)[0] === countryid.id);

      const countryinstiutionname = countryinstiution.map(instiution => instiution.name)
      fetchMultiLevelFilterData(countryinstiutionname, true);
    }
  };

  const priorityOption = [
    { label: 'STAT', value: 'STAT' },
    { label: 'ASAP', value: 'ASAP' },
    { label: 'Routine', value: 'Routine' },
  ];

  const studyInstanceUidToPatientNameMap = {};
  const testing = {};
  patientReportsDetails &&
    patientReportsDetails?.forEach(report => {
      studyInstanceUidToPatientNameMap[report.study_UIDs] = report.reportdetails;
      testing[report.study_UIDs] = report.study_priority;
    });

  let tatCounterTime = {};

  // filterStudies?.forEach(item => {
  //   tatCounterTime[item?.studyInstanceUid] = handleTATCounter(item?.studyInstanceUid);
  // });

  allStudies?.forEach(item => {
    tatCounterTime[item?.studyInstanceUid] = handleTATCounter(item?.studyInstanceUid);
  });

  // const handleFilterStudies = useCallback(
  //   filterName => {
  //     const combinedStudies = isCriticalData.concat(otherData)?.map(item => {
  //       // Your mapping logic here, for example, just returning the item
  //       return item;
  //     });

  //     if (filterName === 'all') {
  //       setFilterStudies(combinedStudies);
  //       return;
  //     }

  //     const statusMap = {
  //       signed: 'Approved',
  //       addendum: 'Addendum',
  //     };

  //     const documentStatus = statusMap[filterName];

  //     if (documentStatus) {
  //       const filteredStudies = combinedStudies?.filter(study =>
  //         patientReportsDetails && patientReportsDetails?.some(
  //           signedStudy =>
  //             signedStudy.document_status === documentStatus &&
  //             signedStudy.study_UIDs === study?.studyInstanceUid
  //         )
  //       );
  //       setFilterStudies(filteredStudies);
  //     } else if (filterName === 'pending') {
  //       const filteredStudies = combinedStudies?.filter(
  //         study =>
  //           patientReportsDetails && !patientReportsDetails?.some(
  //             signedStudy =>
  //               (signedStudy.document_status === 'Approved' ||
  //                 signedStudy.document_status === 'Addendum') &&
  //               signedStudy.study_UIDs === study?.studyInstanceUid
  //           )
  //       );
  //       return setFilterStudies(filteredStudies);
  //     } else {
  //       setFilterStudies([]);
  //     }
  //   },
  //   [isCriticalData, otherData, patientReportsDetails]
  // );

  // Handle the change for individual row checkboxes
  const handleCheckboxChange = id => {
    setSelectedRows(
      prev =>
        prev.includes(id)
          ? prev?.filter(item => item !== id) // Remove if already selected
          : [...prev, id] // Add if not selected
    );
  };


  //   (Array.isArray(filterValues?.priority) && filterValues.priority.length > 0) ||
  //     (Array.isArray(filterValues?.reportstatus) && filterValues?.reportstatus.length > 0) || (selectedWorklistFilter) ||
  //     (filterValues?.assign && typeof filterValues.assign === 'string' && filterValues.assign.trim().length > 0) ||
  //     countryid
  //     ? Array.isArray(first) ? first : []
  //     : Array.isArray(filterData) ? filterData : [];

  // const [displayedData, setDisplayedData] = useState([]);

  // useEffect(() => {
  //   console.log(filterValues?.modality, "filterValues?.modality && typeof filterValues.modality === 'string'");

  //   if (
  //     selectedWorklistFilter &&
  //     filterValues?.institutionname.length > 0 || selectedWorklistFilter &&
  //     (filterValues?.patientName && filterValues.patientName.length > 0) ||
  //     (filterValues?.sex && filterValues?.sex.length > 0) ||
  //     (filterValues?.uid && filterValues.uid.length > 0) ||
  //     (filterValues?.studyDate && filterValues.studyDate.length > 0) ||
  //     (filterValues?.accession && filterValues.accession.length > 0) ||
  //     (filterValues?.description && filterValues.description.length > 0) ||
  //     (filterValues?.mrn && filterValues.mrn.length > 0) ||
  //     (filterValues?.modality && filterValues.modality.length > 0)
  //   ) {

  //     setDisplayedData(Array.isArray(filterData) ? filterData : []);
  //   } else if (
  //     selectedWorklistFilter ||
  //     (Array.isArray(filterValues?.priority) && filterValues.priority.length > 0) ||
  //     (Array.isArray(filterValues?.reportstatus) && filterValues?.reportstatus.length > 0) ||
  //     (filterValues?.assign && typeof filterValues.assign === 'string' && filterValues.assign.trim().length > 0) ||
  //     countryid
  //   ) {
  //     setDisplayedData(Array.isArray(first) ? first : []);
  //   }
  //   else {
  //     setDisplayedData(Array.isArray(filterData) ? filterData : []);
  //   }
  // }, [
  //   selectedWorklistFilter,
  //   filterValues?.institutionname,
  //   filterValues?.priority,
  //   filterValues.assign,
  //   filterValues?.reportstatus,
  //   filterValues?.patientName,
  //   filterValues?.sex,
  //   filterValues?.uid,
  //   filterValues?.studyDate,
  //   filterValues?.accession,
  //   filterValues?.description,
  //   filterValues?.mrn,
  //   filterValues?.modality,
  //   countryid,
  //   first,
  //   filterData,
  // ]);

  // const dataToDisplay = first;


  // const tableDataSource = filterData?.map((study, key) => {

  // const combinedStudies = isCriticalData.concat(otherData)

  filterData = allStudies

  const tableDataSource = filterData?.map((study, key) => {
    const rowKey = key + 1;
    const isExpanded = expandedRows.some(k => k === rowKey);
    // const {
    // studyInstanceUid,
    // accession,
    // modality,
    // instances,
    // mrn,
    // patientName,
    // date,
    // time,
    // institutionname,
    // lastUpdate,
    // studyDescription,
    // firstUpdateImage,
    // lastUpdateImage,
    // acquisitionDateTime,
    // patientAge,
    // patientNames,
    // } = study;

    const firstImageReceived = moment(study?.firstUpdateImage, 'YYYYMMDDTHHmmss').format(
      'MMM-DD-YYYY hh:mm:ss A'
    );
    const lastImageReceived = moment(study?.lastUpdateImage, 'YYYYMMDDTHHmmss').format(
      'MMM-DD-YYYY hh:mm:ss A'
    );
    const acquisitionDateformat = moment(study?.acquisitionDateTime, 'YYYYMMDDTHHmmss').format(
      'MMM-DD-YYYY hh:mm:ss A'
    );

    const reportdetails = studyInstanceUidToPatientNameMap[study?.studyInstanceUid];
    const lastTATCounter = tatCounterTime[study?.studyInstanceUid];
    const studyDate =
      study?.date &&
      moment(study?.date, ['YYYYMMDD', 'YYYY.MM.DD'], true).isValid() &&
      moment(study?.date, ['YYYYMMDD', 'YYYY.MM.DD']).format(
        t('Common:localDateFormat', 'MMM-DD-YYYY')
      );
    const studyTime =
      study?.time &&
      moment(study?.time, ['HH', 'HHmm', 'HHmmss', 'HHmmss.SSS']).isValid() &&
      moment(study?.time, ['HH', 'HHmm', 'HHmmss', 'HHmmss.SSS']).format(
        t('Common:localTimeFormat', 'hh:mm A')
      );
    const findData =
      patientReportsDetails &&
      patientReportsDetails?.filter(item => item.study_UIDs === study?.studyInstanceUid);
    const findDocument = documentUploadDetails?.find(item => item.study_UIDs === study?.studyInstanceUid);

    let findAssign;
    if (findData?.length > 0) {
      findAssign = findData?.flatMap(e => e.assign?.map(item => JSON.parse(item)));
    }

    const visibleKeys = filtersMeta && filtersMeta?.filter((filter) => worklistData[filter?.name])
      .map((filter) => filter?.name);

    let sex;
    if (study?.patientSex?.toLowerCase() === 'm') {
      sex = 'Male';
    } else {
      sex = 'Female';
    }

    const textToCopy = findAssign
      ?.map(item => (item?.assign_name ? item?.assign_name : ''))
      .join(', ');
    const rowData = visibleKeys?.map(key => {
      switch (key) {
        case 'checkBox':
          return {
            key: 'checkBox',
            content: (
              // <input
              //   type="checkbox"
              //   checked={selectedRows.includes(study.studyInstanceUid)}
              //   onChange={() => handleCheckboxChange(study.studyInstanceUid)}
              // />
              <span className="custom-checkbox ">
                <input
                  type="checkbox"
                  checked={selectedRows.includes(study.studyInstanceUid)}
                  onChange={() => handleCheckboxChange(study.studyInstanceUid)}
                  className=" cursor-pointer"
                />
              </span>
            ),
            gridCol: 1,
          };
        case 'patientName':
          return {
            key: 'patientName',
            // content: study?.patientNames
            //   ? (
            //     <TooltipClipboard>
            //       {/* <span className={`${findData[0]?.critical ? ' bg-red-700 ': ''}`}> */}
            //       <span>
            //         {study?.patientNames
            //         }
            //       </span>
            //     </TooltipClipboard>

            //   ) : (
            //     <span className="text-gray-700">(Empty)</span>
            //   ),
            content: <TooltipClipboard>{study?.patientNames}</TooltipClipboard>,
            gridCol: 4,
          };
        case 'tat':
          return {
            key: 'tat',
            content: (
              <>
                <TooltipClipboard>{`${study?.lastUpdate === 'Expired' ? `${study?.lastUpdate}` : lastTATCounter}`}</TooltipClipboard>
              </>
            ),
            gridCol: 2,
          };
        case 'studyTat':
          return {
            key: 'studyTat',
            content:
              !study?.studyTat || study?.studyTat.includes('NaN') ? '(Empty)' : study?.studyTat,
            gridCol: 2,
          };
        case 'patientId':
          return {
            key: 'patientId',
            content: !study?.patientId || study?.patientId === '' || study?.patientId.includes('NaN') ? '(Empty)' : study?.patientId,
            gridCol: 2,
          };
        case 'age':
          return {
            key: 'age',
            content: <TooltipClipboard>{`${study?.patientAge} YRS`}</TooltipClipboard>,
            gridCol: 2,
          };
        case 'sex':
          return {
            key: 'sex',
            content: <TooltipClipboard>{sex}</TooltipClipboard>,
            gridCol: 2,
          };
        case 'dob':
          return {
            key: 'dob',
            content: <TooltipClipboard>{study?.patientBirthDate ? study?.patientBirthDate : '(Empty)'}</TooltipClipboard>,
            gridCol: 2,
          };
        case 'uid':
          return {
            key: 'uid',
            content: <TooltipClipboard>{study?.studyInstanceUid}</TooltipClipboard>,
            gridCol: 2,
          };
        case 'acquisitiondate':
          return {
            key: 'acquisitiondate',
            content: (
              <>
                <TooltipClipboard>
                  {acquisitionDateformat ? (
                    <span className="mr-4">{acquisitionDateformat}</span>
                  ) : (
                    '(Empty)'
                  )}
                </TooltipClipboard>
              </>
            ),
            gridCol: 2,
          };
        case 'mrn':
          return {
            key: 'mrn',
            content: <TooltipClipboard>{study?.mrn}</TooltipClipboard>,
            gridCol: 3,
          };
        case 'studyDate':
          const studyDate =
            moment(study?.date, ['YYYYMMDD', 'YYYY.MM.DD'], true).isValid() &&
            moment(study?.date, ['YYYYMMDD', 'YYYY.MM.DD']).format('MMM-DD-YYYY');
          return {
            key: 'studyDate',
            content: (
              <>
                {studyDate && <span className="mr-4">{studyDate}</span>}
                {studyTime && <span>{studyTime}</span>}
              </>
            ),
            title: `${studyDate || ''} ${studyTime || ''}`,
            gridCol: 5,
          };
        case 'description':
          return {
            key: 'description',
            content: (
              <TooltipClipboard>
                {study?.studyDescription ? study?.studyDescription : '(Empty)'}
              </TooltipClipboard>
            ),
            gridCol: 4,
          };
        case 'physicianName':
          return {
            key: 'physicianName',
            content: (
              <TooltipClipboard>
                {study?.physicianName ? study?.physicianName : '(Empty)'}
              </TooltipClipboard>
            ),
            gridCol: 4,
          };
        case 'modality':
          return {
            key: 'modality',
            content: study?.modality,
            title: study?.modality,
            gridCol: 3,
          };
        case 'accession':
          return {
            key: 'accession',
            content: (
              <TooltipClipboard>
                {study?.accession ? study?.accession : '(Empty)'}
              </TooltipClipboard>
            ),
            gridCol: 3,
          };
        case 'institutionname':
          return {
            key: 'institutionname',
            content: (
              <TooltipClipboard>
                {study?.institutionname ? study?.institutionname : '(Empty)'}
              </TooltipClipboard>
            ),
            gridCol: 2,
          };
        case 'aet':
          return {
            key: 'aet',
            content: !study?.aet || study?.aet === '' || study?.aet.includes('NaN') ? '(Empty)' : study?.aet,
            gridCol: 2,
          };
        case 'priority':
          return {
            key: 'priority',
            content: (
              <Select
                id={`display-set-selector-${study?.studyInstanceUid}`}
                isClearable={false}
                onChange={selectedOption =>
                  handlePriorityChange(selectedOption, study?.studyInstanceUid, study?.patientId, study?.accession, study?.institutionname, study?.studyID)
                }
                menuPlacement="auto"
                options={priorityOption}
                isDisabled={findData[0]?.document_status === 'Approved' ? true : false}
                value={[
                  selectedPriority[study?.studyInstanceUid] ||
                  testing[study?.studyInstanceUid] ||
                  'Routine',
                ]}
                className="w-32 text-white"
              />
            ),
            title: (study?.instances || 0)?.toString(),
            gridCol: 2,
          };
        case 'attachment':
          return {
            key: 'attachment',
            content: (
              <div className=" flex gap-2">
                <div
                  className="flex items-center justify-center"
                  onClick={isAttachment ? () => handleAttachment(study?.studyInstanceUid, study?.patientName) : () => handlerWarning('attachment')}
                >
                  {findDocument ? (
                    <HiMiniDocumentPlus
                      className={`${findDocument ? 'border-0 text-primary-dark dark:text-primary-light transition-all hover:opacity-70' : ''} text-2xl`}
                      title="Documents"
                    />
                  ) : (
                    <GrDocumentUpdate className=" text-2xl transition-all hover:opacity-70" title="Attach Documents" />
                  )}
                </div>

                <div onClick={() => handleClinicalHistory(study?.studyInstanceUid, study?.patientId, study?.accession, study?.patientName)}>
                  {findData[0]?.clinical_history ? (
                    <BsFileMedicalFill
                      className={`${findData[0]?.clinical_history ? 'border-0 text-primary-dark dark:text-primary-light transition-all hover:opacity-70' : ''} text-2xl`}
                      title="Clinical History"
                    />
                  ) : (
                    <FaNotesMedical className=" text-2xl transition-all hover:opacity-70" title="Add Clinical History" />
                  )}
                </div>
              </div>
            ),
            gridCol: 2,
          };
        // case 'clinicalhistory':
        //   return {
        //     key: 'clinicalhistory',
        //     content: (
        //       <div className=" flex gap-2">
        //         <div onClick={() => handleClinicalHistory(study?.studyInstanceUid)}>
        //           {findData[0]?.clinical_history ? (
        //             <BsFileMedicalFill
        //               className={`${findData[0]?.clinical_history ? 'border-0 text-blue-400 transition-all hover:text-blue-300' : ''} text-2xl`}
        //             />
        //           ) : (
        //             <FaNotesMedical className=" text-2xl transition-all hover:text-blue-300" />
        //           )}
        //         </div>
        //       </div>
        //     ),
        //     gridCol: 2,
        //   };
        case 'assign':
          return {
            key: 'assign',
            content: (
              <div className=" flex gap-2">
                <div>
                  {selectedUserDataMap[study?.studyInstanceUid] ||
                    findData.find(item => (item.assign ? item.assign : '')) ? (
                    <div
                      className={`${hasPhysician || !hasAssignPermission ? 'cursor-not-allowed' : 'cursor-pointer'}`}

                      onClick={() => !hasPhysician && hasAssignPermission && handleAssign(study?.studyInstanceUid, study?.modality, study?.patientId, study?.accession, study?.patientName, study?.studyID,
                        study?.description, study?.institutionname, study?.studyID)}
                    >
                      <BsPersonFillAdd fontSize={25} className='text-primary-dark dark:text-primary-light hover:opacity-70' />
                    </div>
                  ) : (
                    <div
                      className={`${hasPhysician || !hasAssignPermission ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                      onClick={() => !hasPhysician && hasAssignPermission && handleAssign(study?.studyInstanceUid, study?.modality, study?.patientId, study?.accession, study?.patientName, study?.studyID, study?.description, study?.institutionname, study?.studyID)}
                    >
                      <BsPersonAdd fontSize={25} className='hover:opacity-70' />
                    </div>
                  )}
                </div>
                <TooltipClipboard>
                  {textToCopy}
                  {/* <span
                      className={` ${selectedUserDataMap[study?.studyInstanceUid] ? 'mr-2 py-1 px-2.5 text-white' : ''}`}
                    >
                      {
                        <div>
                          {findAssign
                            ?.map(item => (item?.assign_name ? item?.assign_name : ''))
                            .join(', ')}
                        </div>
                      }
                    </span> */}
                </TooltipClipboard>
              </div>
            ),
            gridCol: 2,
          };
        case 'reportstatus':
          return {
            key: 'reportstatus',
            content: (
              <div className=" flex gap-2">
                {findData?.length > 0 ? (
                  findData?.map(item =>
                    item.isDrafted === true ? (
                      <div className=" flex items-center gap-2">
                        <div className="rounded-full bg-primary-dark dark:bg-primary-light py-1 px-2.5 dark:text-black text-white">
                          {' '}
                          {reportStatusMap[study?.studyInstanceUid]
                            ? reportStatusMap[study?.studyInstanceUid]
                            : item.document_status === 'Approved'
                              ? item.document_status
                              : item.report_status !== null
                                ? item.report_status
                                : 'Draft'}
                        </div>
                        {token?.realm_access?.roles?.map(item =>
                          item === 'qa-user' || item === 'manager' ? (
                            <div
                              className=" hidden cursor-pointer"
                              onClick={() => handleReportsStatusChanges(study?.studyInstanceUid, study?.patientId, study?.accession, study?.institutionname, study?.studyID)}
                            >
                              <LuPencilLine size={20} />
                            </div>
                          ) : (
                            ''
                          )
                        )}
                      </div>
                    ) : item.isDrafted === false ? (
                      <div className=" flex items-center gap-2">
                        <div className="rounded-full bg-primary-dark dark:bg-primary-light py-1 px-2.5 dark:text-black text-white">
                          {' '}
                          {reportStatusMap[study?.studyInstanceUid]
                            ? reportStatusMap[study?.studyInstanceUid]
                            : item.document_status === 'Approved'
                              ? item.document_status
                              : item.report_status !== null
                                ? item.report_status
                                : 'Final'}
                        </div>
                        {token?.realm_access?.roles?.map(item =>
                          item === 'qa-user' || item === 'manager' ? (
                            <div
                              className=" hidden cursor-pointer"
                              onClick={() => handleReportsStatusChanges(study?.studyInstanceUid, study?.patientId, study?.accession, study?.studyID)}
                            >
                              <LuPencilLine size={20} />
                            </div>
                          ) : (
                            ''
                          )
                        )}
                      </div>
                    ) : (
                      <div className=" rounded-full py-1 px-2.5">New</div>
                    )
                  )
                ) : (
                  <div className=" rounded-full py-1 px-2.5">New</div>
                )}
              </div>
            ),
            gridCol: 2,
          };
        case 'document':
          return {
            key: 'document',
            content: (
              <div>
                {findData?.length > 0 ? (
                  <div className="flex items-center gap-2">
                    <span
                      className={` ${selectedReportStatusMap[study?.studyInstanceUid] ? 'rounded-full bg-primary-dark dark:bg-primary-light py-1 px-2.5 dark:text-black text-white' : ''}`}
                    >
                      {!selectedReportStatusMap[study?.studyInstanceUid]
                        ? patientReportsDetails?.filter(item => item.study_UIDs === study?.studyInstanceUid)
                          .map(item => (
                            <span
                              className={` ${item.document_status !== null ? ' inline-block rounded-full bg-primary-dark dark:bg-primary-light py-1 px-2.5 dark:text-black text-white' : ''}`}
                            >
                              {' '}
                              {item.document_status}
                            </span>
                          ))
                        : selectedReportStatusMap[study?.studyInstanceUid]}
                    </span>
                    <div className="flex items-center gap-1">
                      {hasManagerPermission && reportdetails &&
                        patientReportsDetails?.filter(item => item.study_UIDs === study?.studyInstanceUid)
                          .map(i => (
                            <div
                              key={i.id}
                              className=" cursor-pointer"
                              onClick={() => handleDocument(study?.studyInstanceUid, study?.institutionname, study?.studyID)}
                            >
                              {' '}
                              {hasManager || hasRadiologist ?
                                (!i.isDrafted ? <LuPencilLine size={20} /> : null) : (<LuPencilLine size={20} />)
                              }
                            </div>
                          ))}
                      {/* {hasManagerPermission ? (
                          <div
                            className=" cursor-pointer"
                            onClick={() => handleDocument(study?.studyInstanceUid)}
                          >
                            {' '}
                            {reportdetails && <LuPencilLine size={20} />}
                          </div>
                        ) : (
                          ''
                        )} */}
                      <div
                        className=" cursor-pointer"
                        onClick={() =>
                          handleDocumentViewer(
                            study?.studyInstanceUid,
                            reportdetails,
                            study?.patientName
                          )
                        }
                      >
                        {' '}
                        {reportdetails && <PiMicrosoftWordLogoBold fontSize={25} className='hover:opacity-70' />}{' '}
                      </div>
                      {/* <div
                          className=" cursor-pointer"
                          onClick={() =>
                            handlePDFViewer(study?.studyInstanceUid, reportdetails, study?.patientName)
                          }
                        >
                          {reportdetails &&  <TbFileTypePdf fontSize={25} /> }{' '}
                          {reportdetails &&  token.roleType === 'Physician'? <FaFileDownload fontSize={22}/> : <TbFileTypePdf fontSize={25} /> }
                          {reportdetails &&  token.roleType === 'Radiologist'? <FaFileDownload fontSize={22}/> : <TbFileTypePdf fontSize={25} onClick={()=>{
                            handleDownloadPdf(study?.studyInstanceUid, reportdetails, study?.patientName)
                          }}/> }
                        </div> */}
                      {hasDownloadPdfPermission ? (
                        <div
                          className=" cursor-pointer"
                          onClick={() =>
                            handleDownloadPdf(
                              study?.studyInstanceUid,
                              reportdetails,
                              study?.patientName
                            )
                          }
                        >
                          {reportdetails && <FaFileDownload fontSize={22} className='hover:opacity-70' />}{' '}
                        </div>
                      ) : (
                        <div
                          className=" cursor-pointer"
                          onClick={() =>
                            handleDownloadPdf(
                              study?.studyInstanceUid,
                              reportdetails,
                              study?.patientName
                            )
                          }
                        >
                          {reportdetails && <TbFileTypePdf fontSize={25} className='hover:opacity-70' />}{' '}
                        </div>
                      )}
                      {!reportdetails && (
                        <div
                          className=" rounded-full py-1 px-2.5"
                          style={{ marginLeft: '-20px' }}
                        >
                          Pending
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className=" rounded-full py-1 px-2.5">Pending</div>
                )}
              </div>
            ),
            gridCol: 2,
          };
        case 'instances':
          return {
            key: 'instances',
            content: (
              <>
                <Icon
                  name="group-layers"
                  className={classnames('mr-2 inline-flex w-4', {
                    'text-primary-active': isExpanded,
                    'text-secondary-light': !isExpanded,
                  })}
                />
                {study?.instances}
              </>
            ),
            title: study?.instances?.toString(),
            gridCol: 4,
          };
        case 'firstupdate':
          return {
            key: 'firstupdate',
            content: <TooltipClipboard>{firstImageReceived}</TooltipClipboard>,
            gridCol: 2,
          };

        case 'lastupdate':
          return {
            key: 'lastupdate',
            content: <TooltipClipboard>{lastImageReceived}</TooltipClipboard>,
            gridCol: 2,
          };

        case 'lastupdate':
          return {
            key: 'lastupdate',
            content: <TooltipClipboard>{lastImageReceived}</TooltipClipboard>,
            gridCol: 2,
          };
        case 'share':
          return {
            key: 'share',
            content: (
              <div className=" flex gap-2">
                <div
                  onClick={() => handleShare(study?.studyInstanceUid, study?.patientId, study?.accession, study?.institutionname)}
                  className={` transition-all hover:opacity-70 ${loadingStates[study?.studyInstanceUid] ? 'rounded-md bg-primary-dark dark:bg-primary-light  p-2 text-sm dark:text-black text-white' : 'text-xl'}`}
                >
                  {' '}
                  {loadingStates[study?.studyInstanceUid] ? 'Sending...' : <FaShareSquare />}
                </div>
              </div>
            ),
            gridCol: 2,
          };
        default:
          return null;
      }
    })?.filter(item => item !== null);

    return {
      dataCY: `studyRow-${study?.studyInstanceUid}`,
      row: rowData,
      findData: findData,
      study: study,
      // Todo: This is actually running for all rows, even if they are
      // not clicked on.
      expandedContent: (
        <StudyListExpandedRow
          seriesTableColumns={{
            description: t('StudyList:Description'),
            seriesNumber: t('StudyList:Series'),
            modality: t('StudyList:Modality'),
            instances: t('StudyList:Instances'),
          }}
          seriesTableDataSource={
            seriesInStudiesMap.has(study?.studyInstanceUid)
              ? seriesInStudiesMap.get(study?.studyInstanceUid)?.map(s => {
                return {
                  description: s.description || '(empty)',
                  seriesNumber: s.seriesNumber ?? '',
                  modality: s.modality || '',
                  instances: s.numSeriesInstances || '',
                };
              })
              : []
          }
        >
          <div className="flex flex-row gap-2">
            {filteredModes && filteredModes?.map((mode, i) => {
              const modalitiesToCheck = study?.modality?.replaceAll('/', '\\');
              if (mode.routeName === 'dynamic-volume') {
                // Skip rendering this mode
                return null;
              }
              const isValidMode = mode?.isValidMode({
                modality: modalitiesToCheck,
                study,
              });
              // TODO: Modes need a default/target route? We mostly support a single one for now.
              // We should also be using the route path, but currently are not
              // mode.routeName
              // mode.routes[x].path
              // Don't specify default data source, and it should just be picked up... (this may not currently be the case)
              // How do we know which params to pass? Today, it's just StudyInstanceUIDs and configUrl if exists

              const query = new URLSearchParams();

              if (filterValues.configUrl) {
                query.append('configUrl', filterValues.configUrl);
              }
              query.append('StudyInstanceUIDs', study?.studyInstanceUid);
              query.append('UUID', study?.studyID);

              return (
                mode.displayName && (
                  <Link
                    className={isValidMode ? '' : 'cursor-not-allowed'}
                    key={i}
                    to={`${dataPath ? '../../' : ''}${mode.routeName}${dataPath || ''
                      }?${query?.toString()}`}
                    onClick={event => {
                      // In case any event bubbles up for an invalid mode, prevent the navigation.
                      // For example, the event bubbles up when the icon embedded in the disabled button is clicked.
                      if (!isValidMode) {
                        event.preventDefault();
                      }
                    }}
                  // to={`${mode.routeName}/dicomweb?StudyInstanceUIDs=${studyInstanceUid}`}
                  >
                    {/* TODO revisit the completely rounded style of buttons used for launching a mode from the worklist later - for now use LegacyButton*/}
                    <LegacyButton
                      rounded="full"
                      variant={isValidMode ? 'contained' : 'disabled'}
                      disabled={!isValidMode}
                      endIcon={<Icon name="launch-arrow" />} // launch-arrow | launch-info
                      onClick={() => { }}
                      data-cy={`mode-${mode.routeName}-${study?.studyInstanceUid}`}
                    >
                      {mode.displayName}
                    </LegacyButton>
                  </Link>
                )
              );
            })}
          </div>
        </StudyListExpandedRow>
      ),
      onClickRow: () =>
        setExpandedRows(s => (isExpanded ? s?.filter(n => rowKey !== n) : [...s, rowKey])),
      isExpanded,
    };
  });

  const rollingPageNumberMod = Math.ceil(tableDataSource?.length / resultsPerPage);
  const rollingPageNumber = (pageNumber - 1) % rollingPageNumberMod;
  const offset = resultsPerPage * rollingPageNumber;
  const offsetAndTake = Math.min(offset + resultsPerPage, tableDataSource?.length);

  useEffect(() => {
    const getToken = async () => {
      try {
        const data = {
          token: user.access_token,
        };
        const response = await userToken(data);
        setToken(response);
      } catch (error) {
        console.log(error);
      }
    };
    getToken();
  }, []);

  // const hasStudies = tableDataSource?.length === 0 ? false : numOfStudies > 0;
  const hasStudies = allStudies.length > 0;

  useEffect(() => {
    const filteredGroupIds = radiologyGroupList?.filter(group => !group.error)?.map(group => group.id);

    setGroupIds(filteredGroupIds);
  }, [radiologyGroupList]);


  const { component: dicomUploadComponent } =
    customizationService.get('dicomUploadComponent') ?? {};
  const uploadProps =
    dicomUploadComponent && dataSource.getConfig()?.dicomUploadEnabled
      ? {
        title: 'Upload files',
        closeButton: true,
        shouldCloseOnEsc: false,
        shouldCloseOnOverlayClick: false,
        content: dicomUploadComponent.bind(null, {
          dataSource,
          onComplete: () => {
            hide();
            onRefresh();
          },
          onStarted: () => {
            show({
              ...uploadProps,
              // when upload starts, hide the default close button as closing the dialogue must be handled by the upload dialogue itself
              closeButton: false,
            });
          },
        }),
      }
      : undefined;

  const { component: dataSourceConfigurationComponent } =
    customizationService.get('ohif.dataSourceConfigurationComponent') ?? {};

  return (
    <div className="flex h-screen flex-col dark:bg-secondary-dark bg-primary-light ">
      <Header
        isSticky
        isReturnEnabled={false}
        WhiteLabeling={appConfig.whiteLabeling}
        loggedInUserDetails={user}
        loggedInUserTokenDetails={token}
        sortedStudies={sortedStudies}
        servicesManager={servicesManager}
        hotkeysManager={hotkeysManager}
      />
      <StudyListFilter
        numOfStudies={numOfStudies}
        filtersMeta={filteredFiltersMeta}
        filterValues={{ ...filterValues, ...defaultSortValues }}
        onChange={setFilterValues}
        clearFilters={() => setFilterValues(defaultFilterValues)}
        isFiltering={isFiltering(filterValues, defaultFilterValues)}
        onUploadClick={uploadProps ? () => show(uploadProps) : undefined}
        getDataSourceConfigurationComponent={
          dataSourceConfigurationComponent ? () => dataSourceConfigurationComponent() : undefined
        }
        selectedWorklistFilter=
        {selectedWorklistFilter}
        setSelectedWorklistFilter=
        {setSelectedWorklistFilter}
        // handleFilterStudies={handleFilterStudies}
        handleFilterStudies={() => { }}
        displaySetList={displaySetList}
        otherData={otherData}
        isCriticalData={isCriticalData}
        patientReportsDetails={patientReportsDetails}
        handleStudiesDelete={handleStudiesDelete}
        selectedRows={selectedRows}
        user={user}
        token={token}
        countryid={countryid}
        setCountryid={setCountryid}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="telerapp-scrollbar flex grow flex-col overflow-y-auto dark:bg-primary-dark bg-primary-light custom-height">
        <div className={`worklist-table mt-3 ${filterData?.length > 10 ? "overflow-x-auto" : ""}`}>
          <WorklistLabel
            inputMeta={worklistData}
            filtersMeta={filtersMeta}
            values={{ ...filterValues, ...defaultSortValues }}
            onValuesChange={setFilterValues}
            displaySetList={displaySetList}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            filterData={filterData}
          />
          {/* {hasStudies && !loader ? (
            <div className="scroll_width_header_body  flex grow flex-col">
              <StudyListTable
                inputMeta={worklistData}
                tableDataSource={tableDataSource?.slice(offset, offsetAndTake)}
                querying={querying}
                filteredModes={filteredModes}
                dataPath={dataPath}
                filterValues={filterValues}
              />
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center pt-48">
              {appConfig.showLoadingIndicator && isLoadingData && loader ? (
                <LoadingIndicatorProgress className={'h-full w-full bg-black'} />
              ) : (
                <EmptyStudies numOfStudies={numOfStudies} />
              )}
            </div>
          )} */}

          {
            hasStudies ? (
              <>
                {loader ? (
                  <div className="scroll_width_header_body flex grow flex-col justify-center items-center !w-full h-[615px]" >
                    <span className="loader"></span>
                  </div>
                ) : (
                  <div className="scroll_width_header_body flex grow flex-col" >
                    <StudyListTable
                      inputMeta={worklistData}
                      tableDataSource={tableDataSource?.slice(offset, offsetAndTake)}
                      querying={querying}
                      filteredModes={filteredModes}
                      dataPath={dataPath}
                      filterValues={filterValues}
                    />
                  </div>
                )}
              </>
            ) : (
              <div className="flex flex-col items-center justify-center pt-48">
                {appConfig.showLoadingIndicator && loader ? (
                  <LoadingIndicatorProgress className={'h-full w-full bg-black'} />
                ) : (
                  <EmptyStudies numOfStudies={numOfStudies} />
                )}
              </div>
            )
          }

        </div>
      </div>
      <div className="grow">
        {/* <StudyListPagination
          onChangePage={onPageNumberChange}
          onChangePerPage={onResultsPerPageChange}
          currentPage={pageNumber}
          perPage={resultsPerPage}
          numOfStudies={filterData?.length}
          servicesManager={servicesManager}
          token={token}
        /> */}
        <StudyListPagination
          onChangePage={onPageNumberChange}
          onChangePerPage={onResultsPerPageChange}
          currentPage={pageNumber}
          perPage={resultsPerPage}
          // numOfFilteredStudies={filterData?.length}
          numOfFilteredStudies={second}
          // numOfStudies={allStudyList?.length}
          numOfStudies={second}
          servicesManager={servicesManager}
          token={token}
        />
      </div>
    </div>
  );
}

WorkList.propTypes = {
  data: PropTypes.array.isRequired,
  dataSource: PropTypes.shape({
    query: PropTypes.object.isRequired,
    getConfig: PropTypes.func,
  }).isRequired,
  isLoadingData: PropTypes.bool.isRequired,
  servicesManager: PropTypes.instanceOf(ServicesManager),
};

const defaultFilterValues = {
  patientName: '',
  mrn: '',
  sex: '',
  uid: '',
  studyDate: {
    startDate: null,
    endDate: null,
  },
  acquisitiondate: {
    startDate: null,
    endDate: null,
  },
  description: '',
  modality: [],
  priority: [],
  accession: '',
  assign: '',
  institutionname: '',
  patientId: '',
  aet: '',
  sortBy: '',
  sortDirection: 'none',
  pageNumber: 1,
  resultsPerPage: 15,
  datasources: '',
  configUrl: null,
  general: '',
};

function _tryParseInt(str, defaultValue) {
  let retValue = defaultValue;
  if (str && str?.length > 0) {
    if (!isNaN(str)) {
      retValue = parseInt(str);
    }
  }
  return retValue;
}

function _getQueryFilterValues(params) {
  const queryFilterValues = {
    patientName: params.get('patientname'),
    mrn: params.get('mrn'),
    sex: params.get('sex'),
    uid: params.get('uid'),
    studyDate: {
      startDate: params.get('startdate') || null,
      endDate: params.get('enddate') || null,
    },
    description: params.get('description'),
    modality: params.get('modality') ? params?.get('modality')?.split(',') : [],
    priority: params.get('priority'),
    accession: params.get('accession'),
    institutionname: params.get('institutionname'),
    patientId: params.get('patientId'),
    aet: params.get('aet'),
    assign: params.get('assign'),
    sortBy: params.get('sortby'),
    sortDirection: params.get('sortdirection'),
    pageNumber: _tryParseInt(params.get('pagenumber'), undefined),
    resultsPerPage: _tryParseInt(params.get('resultsperpage'), undefined),
    datasources: params.get('datasources'),
    configUrl: params.get('configurl'),
    general: params.get('general'),
  };

  // Delete null/undefined keys
  Object.keys(queryFilterValues).forEach(
    key => queryFilterValues[key] == null && delete queryFilterValues[key]
  );

  return queryFilterValues;
}

function _sortStringDates(s1, s2, sortModifier) {
  // TODO: Delimiters are non-standard. Should we support them?
  const s1Date = moment(s1.date, ['YYYYMMDD', 'YYYY.MM.DD'], true);
  const s2Date = moment(s2.date, ['YYYYMMDD', 'YYYY.MM.DD'], true);

  if (s1Date.isValid() && s2Date.isValid()) {
    return (s1Date.toISOString() > s2Date.toISOString() ? 1 : -1) * sortModifier;
  } else if (s1Date.isValid()) {
    return sortModifier;
  } else if (s2Date.isValid()) {
    return -1 * sortModifier;
  }
}

export default WorkList;
