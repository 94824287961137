import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Select from '../Select';
import Typography from '../Typography';
import Button from '../Button';

import { Formik, Form, Field } from 'formik';
import Label from '../Label';
import { useSnackbar } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import { ToastContainer, toast } from 'react-toastify';

const RadiologyViewerSetting = ({
  onCancel,
  createRadiologyGroupSetting,
  updateRadiologyGroupSetting,
  viewerSetting,
  fetchRadiologyGroupSetting,
  setRadiologyGroupSetting,
  radiologyGroupList,
}) => {
  const { show } = useSnackbar();
  const { t } = useTranslation('UserPreferencesModal');
  const fieldData = viewerSetting ? JSON.parse(viewerSetting.field[0]) : null;

  const initialValues = {
    groupName: fieldData?.radiologyGroupNameViewer || '',
    viewer: fieldData?.viewer || '',
  };

  const [selectedViewer, setSelectedViewer] = useState(fieldData?.viewer || null);
  const [selectedGroupName, setSelectedGroupName] = useState(
    fieldData?.radiologyGroupNameViewer || null
  );

  const onSubmitHandler = async (values, { setSubmitting }) => {
    try {
      const data = {
        field: [
          JSON.stringify({
            radiologyGroupNameViewer: selectedGroupName,
            viewer: selectedViewer,
          }),
        ],
      };
      const response = await updateRadiologyGroupSetting(viewerSetting.id, data);
      if (response.status) {
        fetchRadiologyGroupSetting()
          .then(data => setRadiologyGroupSetting(data))
          .catch(error => console.error('Error fetching radiology groups setting :', error));
        toast.success('Successfully Saved Radiology Group Setting');
        // show({
        //   title: 'Success!',
        //   message: 'Successfully Update Radiology Group Setting',
        //   type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
        //   position: 'topRight',
        // });
        onCancel();
      }
    } catch (error) {
      const data = {
        name: 'RadiologyGroupViewerSetting',
        field: [
          JSON.stringify({
            radiologyGroupNameViewer: selectedGroupName,
            viewer: selectedViewer,
          }),
        ],
      };
      const response = await createRadiologyGroupSetting(data);
      if (response.status) {
        fetchRadiologyGroupSetting()
          .then(data => setRadiologyGroupSetting(data))
          .catch(error => console.error('Error fetching radiology groups setting :', error));
        toast.success('Successfully Saved Radiology Group Setting');
        // show({
        //   title: 'Success!',
        //   message: 'Successfully Saved Radiology Group Setting ',
        //   type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
        //   position: 'topRight',
        // });
        onCancel();
      }
    }
    setSubmitting(false);
    // toast.warning('Please Refresh and Try Again');
  };

  const optionGroup = radiologyGroupList.map(group => ({
    label: group.name,
    value: group.name,
  }));

  const optionViewer = [
    { label: 'Viewer', value: 'Viewer' },
    { label: 'Segmentation', value: 'Segmentation' },
    { label: 'Total Metabolic Tumor Volume', value: 'Total Metabolic Tumor Volume' },
    { label: 'Microscopy', value: 'Microscopy' },
  ];

  const handleSelectionChange = selected => {
    setSelectedViewer(selected.value);
  };
  const handleSelectionaChange = selected => {
    setSelectedGroupName(selected.value);
  };

  const Section = ({ title, children }) => (
    <>
      <div className="mb-2 border-b-2 border-b-secondary-dark dark:border-b-primary-main">
        <Typography
          variant="inherit"
          color="primaryLight"
          className="flex pb-2 text-[16px] font-semibold !leading-[1.2] dark:text-white text-black"
        >
          {title}
        </Typography>
      </div>
      <div className=" mb-8">{children}</div>
    </>
  );

  return (
    <>
      {/* Radiology Group TAT */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
      >
        {({ isSubmitting }) => (
          <Form>
            <Section title={t('Radiology Group Viewer Setting')}>
              <div className="flex items-end">
                <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
                  <div className="md:w-72 w-full">
                    <Label className="my-2 block dark:text-white text-black">Radiology Group Name</Label>
                    <Field
                      className=""
                      name="groupName"
                      placeholder={'Select Radiology Group Name'}
                      onChange={handleSelectionaChange}
                      as={Select}
                      options={optionGroup}
                      value={[selectedGroupName]}
                    />
                  </div>
                  <div className="md:w-72 w-full">
                    <Label className="my-2 block dark:text-white text-black">Viewer</Label>
                    <Field
                      as={Select}
                      placeholder={'Select Viewer'}
                      options={optionViewer}
                      className=""
                      name="viewer"
                      value={[selectedViewer]}
                      onChange={handleSelectionChange}
                    />
                  </div>
                </div>
                <Button disabled={isSubmitting} className='ml-4'>Save</Button>
              </div>
            </Section>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RadiologyViewerSetting;
