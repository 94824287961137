export const fetchLogs = async (date, fileName) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/logs/${date}/${fileName}`);
    const data = await response.json();
    return data;
};

export const fetchDateRangeLogs = async (startDate, endDate, fileName) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/logs/${startDate}/${endDate}/${fileName}`);
    const data = await response.json();
    return data;
};


// requestHandler.js

// Fetch all events without pagination
// export const fetchEvents = async (accessTokens) => {
//     try {
//         const response = await fetch(`${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/events`, {
//             headers: {
//                 Authorization: `Bearer ${accessTokens}`
//             }
//         });
//         if (!response.ok) {
//             throw new Error(`Error fetching events: ${response.statusText}`);
//         }
//         return await response.json();
//     } catch (error) {
//         console.error('Error in fetchEvents:', error);
//         throw error;
//     }
// };

// Function to fetch user details from Keycloak
const fetchUserDetails = async (accessToken) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/users`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        if (!response.ok) {
            throw new Error(`Error fetching user details: ${response.statusText}`);
        }

        const userDetails = await response.json();

        // Ensure userDetails is an array and contains at least one user
        if (!Array.isArray(userDetails) || userDetails.length === 0) {
            throw new Error('No user details found');
        }

        // Create a map of usernames to their respective radiologyGroup
        const userRadiologyGroups = userDetails.reduce((acc, user) => {
            const radiologyGroup = user?.attributes?.radiologyGroup || [];
            if (user?.username) {
                acc[user.username] = radiologyGroup;
            }
            return acc;
        }, {});

        return userRadiologyGroups;
    } catch (error) {
        console.error('Error fetching user details:', error);
        throw error;
    }
};

// Updated fetchEventsPagination function to include fetchAndSetEvents functionality
export const fetchEventsPagination = async (accessToken, dateFrom = new Date().toISOString().split('T')[0]) => {
    try {
        // Fetch the event data first
        const eventResponse = await fetch(
            `${process.env.REACT_APP_KEYCLOAK_API_ENDPOINT}/events?dateFrom=${dateFrom}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        if (!eventResponse.ok) {
            throw new Error(`Error fetching paginated events: ${eventResponse.statusText}`);
        }

        const events = await eventResponse.json();

        // Ensure the response is an array
        if (!Array.isArray(events)) {
            console.error('Unexpected response format:', events);
            return [];
        }

        // Fetch the user details to get radiologyGroup
        const userRadiologyGroups = await fetchUserDetails(accessToken);

        // Filter out events with type 'CODE_TO_TOKEN' and IP address '0:0:0:0:0:0:0:1'
        const filteredEvents = events
            .filter(event => event.type !== 'CODE_TO_TOKEN' && event.ipAddress !== '0:0:0:0:0:0:0:1')
            .map(event => {
                const username = event.details?.username;
                let radiologyGroup = userRadiologyGroups[username] || [];
                if (!radiologyGroup.length) {
                    // If no radiologyGroup found, include some default user data
                    const userData = {
                        username: username,
                        message: "No radiologyGroup available",
                    };
                    radiologyGroup = [userData]; // Attach default user data
                }

                return {
                    type: event.type === 'LOGOUT_ERROR' ? 'LOGOUT' : event.type, // Rename LOGOUT_ERROR to LOGOUT
                    username: username,
                    ipAddress: event.ipAddress,
                    timestamp: event.time,
                    radiologyGroup, // Attach the radiologyGroup (or default user data) to the event data
                };
            });

        // Optional: Filter events based on radiologyGroup if necessary
        const filteredByGroup = filteredEvents.filter(event =>
            event.radiologyGroup.length > 0 // Check if the event has a radiology group assigned
        );

        return filteredByGroup;

    } catch (error) {
        console.error('Error in fetchEventsPagination:', error);
        throw error;
    }
};
