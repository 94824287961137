import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Select from '../Select';
import Typography from '../Typography';
import Button from '../Button';

import { Formik, Form, Field } from 'formik';
import Label from '../Label';
import { useSnackbar } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import { ToastContainer, toast } from 'react-toastify';


const RadiologyPatientInformationSetting = ({ onCancel, createRadiologyGroupSetting, updateRadiologyGroupSetting, patientInformationSetting, fetchRadiologyGroupSetting, setRadiologyGroupSetting, radiologyGroupList }) => {
    const { show } = useSnackbar();
    const { t } = useTranslation('UserPreferencesModal');
    const fieldData = patientInformationSetting ? JSON.parse(patientInformationSetting.field[0]) : null;

    const initialValues = {
        groupName: fieldData?.radiologyGroupNamePatientInformation || '',
        information: fieldData?.information || '',
    };

    const [selectedGroupPatientInformation, setSelectedGroupPatientInformation] = useState(fieldData?.information || null);
    const [selectedGroupName, setSelectedGroupName] = useState(fieldData?.radiologyGroupNamePatientInformation || null);

    const onSubmitHandler = async (values, { setSubmitting }) => {
        try {
            if (patientInformationSetting) {


                const data = {
                    field: [
                        JSON.stringify({
                            radiologyGroupNamePatientInformation: selectedGroupName,
                            information: selectedGroupPatientInformation,
                        })
                    ],
                };
                const response = await updateRadiologyGroupSetting(patientInformationSetting.id, data);
                if (response.status) {
                    fetchRadiologyGroupSetting()
                        .then(data => setRadiologyGroupSetting(data))
                        .catch(error => console.error('Error fetching radiology groups setting :', error));
                    toast.success('Successfully Saved Radiology Group Settings');
                    // show({
                    //     title: "Success!",
                    //     message: "Successfully Update Radiology Group Setting",
                    //     type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
                    //     position: "topRight"
                    // });
                    onCancel()
                }
            } else {
                const data = {
                    name: 'RadiologyGroupPatientInformationSetting',
                    field: [
                        JSON.stringify({
                            radiologyGroupNamePatientInformation: selectedGroupName,
                            information: selectedGroupPatientInformation,
                        })
                    ],
                };
                const response = await createRadiologyGroupSetting(data);
                if (response.status) {
                    fetchRadiologyGroupSetting()
                        .then(data => setRadiologyGroupSetting(data))
                        .catch(error => console.error('Error fetching radiology groups setting :', error));
                    toast.success('Successfully Saved Radiology Group Settings');
                    // show({
                    //     title: "Success!",
                    //     message: "Successfully Saved Radiology Group Setting ",
                    //     type: SnackbarTypes.SUCCESS, // Assuming you have a SUCCESS type defined in your SnackbarTypes
                    //     position: "topRight"
                    // });
                    onCancel()
                }
            }
            setSubmitting(false);
        } catch (error) {
            console.log(error.message);
            toast.warning('Please Refresh and Try Again');

        }
    };

    const optionGroup = radiologyGroupList.map(group => ({
        label: group.name,
        value: group.name
    }));

    const optionPatientInformation = [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
    ];

    const handleSelectionPatientInformationChange = (selected) => {
        setSelectedGroupPatientInformation(selected.value);
    };
    const handleSelectionGroupNameChange = (selected) => {
        setSelectedGroupName(selected.value);
    };


    const Section = ({ title, children }) => (
        <>
            <div className="mb-2 border-b-2 border-b-secondary-dark dark:border-b-primary-main">
                <Typography
                    variant="inherit"
                    color="primaryLight"
                    className="flex pb-2 text-[16px] font-semibold !leading-[1.2] dark:text-white text-black"
                >
                    {title}
                </Typography>
            </div>
            <div className=" mb-8">{children}</div>
        </>
    );

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmitHandler}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <Section title={t(' Radiology Group Patient Information Setting')}>
                            <div className='flex items-end'>
                                <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
                                    <div className='md:w-72 w-full'>
                                        <Label className="my-2 block dark:text-white text-black">Radiology Group Name</Label>
                                        <Field
                                            className=""
                                            name="groupName"
                                            placeholder={'Select Radiology Group Name'}
                                            onChange={handleSelectionGroupNameChange}
                                            as={Select}
                                            options={optionGroup}
                                            value={[selectedGroupName]}
                                        />
                                    </div>
                                    <div className='md:w-72 w-full'>
                                        <Label className="my-2 block dark:text-white text-black">Information</Label>
                                        <Field
                                            as={Select}
                                            placeholder={'Select...'}
                                            options={optionPatientInformation}
                                            className=""
                                            name="information"
                                            value={[selectedGroupPatientInformation]}
                                            onChange={handleSelectionPatientInformationChange}
                                        />
                                    </div>
                                </div>
                                <Button disabled={isSubmitting} className='ml-4'>
                                    Save
                                </Button>
                            </div>
                        </Section>
                    </Form>
                )}
            </Formik>

        </>
    );
};

export default RadiologyPatientInformationSetting;
