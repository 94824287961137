import React, { useEffect, useState } from 'react';
import { Dashboard, Header } from '@ohif/ui';
import { useReportEditor, useSnackbar, useUser } from '../../contextProviders';
import SnackbarTypes from '../Snackbar/SnackbarTypes';
import CreateReportTemplate from './CreateReportTemplate';
import ReportTemplateList from './ReportTemplateList';
import Swal from 'sweetalert2';
import CustomTemplates from './CustomTemplates';
import { useNavigate } from 'react-router-dom';
import { useAppConfig } from '@state';

const ReportTemplate = ({ servicesManager, extensionManager, hotkeysManager, data: studies }) => {
  const {
    createDefaultTemplates,
    fetchDefaultTemplates,
    setDefaultTemplates,
    deleteDefaultTemplates,
    updateDefaultTemplates,
    currentPage,
    resultsPerPage, searchTerm,
    fetchDefaultReportTemplates,
    setDefaultReportTemplates
  } = useReportEditor();

  const { userAuthenticationService } = servicesManager.services;

  const user = userAuthenticationService.getUser();

  const [error, setError] = useState(null);
  const [dataTemplate, setDataTemplate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [checkedAttributesItems, setCheckedAttributesItems] = useState({});
  const [editorData, setEditorData] = useState('');
  const [isOpenCustomTemplate, setIsOpenCustomTemplate] = useState(false);
  const [selectCustomTemplate, setSelectCustomTemplate] = useState(true);
  const [templateName, setTemplateName] = useState('');
  const [imageCapture, setImageCapture] = useState(true)
  const { show } = useSnackbar();

  const [modality, setModality] = useState('');
  const [bodyparts, setBodyparts] = useState('');
  const [templategroup, setTemplategroup] = useState('');

  const navigate = useNavigate();
  const [appConfig] = useAppConfig();

  const {
    token,
    userToken,
    setToken,
  } = useUser();

  useEffect(() => {
    const getToken = async () => {
      try {
        const data = {
          token: user.access_token,
        };
        const response = await userToken(data);
        setToken(response);
      } catch (error) {
        console.log(error);
      }
    };
    getToken();
  }, []);

  const onClickReturnButton = () => {
    const { pathname } = location;
    const dataSourceIdx = pathname.indexOf('/', 1);
    const query = new URLSearchParams(window.location.search);
    const configUrl = query.get('configUrl');

    const dataSourceName = pathname.substring(dataSourceIdx + 1);
    const existingDataSource = extensionManager.getDataSources(dataSourceName);

    const searchQuery = new URLSearchParams();
    if (dataSourceIdx !== -1 && existingDataSource) {
      searchQuery.append('datasources', pathname.substring(dataSourceIdx + 1));
    }

    if (configUrl) {
      searchQuery.append('configUrl', configUrl);
    }

    navigate({
      pathname: '/',
      search: decodeURIComponent(searchQuery.toString()),
    });
  };

  const data = [
    { label: 'Patient Name', name: 'patient_name', isChecked: true },
    { label: 'Patient ID', name: 'patient_id', isChecked: true },
    { label: 'Patient Sex', name: 'patient_gender', isChecked: true },
    { label: 'DOB', name: 'patient_dob', isChecked: false },
    { label: 'Patient Age', name: 'patient_age', isChecked: true },
    { label: 'Modality', name: 'patient_modality', isChecked: true },
    { label: 'Accession Number', name: 'accession_number', isChecked: true },
    { label: 'Priority', name: 'priority', isChecked: false },
    // { label: 'Study Description', name: 'study_description',isChecked : false },
    { label: 'Study Date', name: 'study_date', isChecked: true },
    { label: 'Study Time', name: 'study_time', isChecked: false },
    { label: 'Study TAT', name: 'study_tat', isChecked: false },
    { label: 'Ref Physician', name: 'ref_physician', isChecked: true },
    // { label: 'Ref Doctor', name: 'ref_doctor',isChecked : true },
    { label: 'Comments', name: 'comments', isChecked: false },
    { label: 'Study', name: 'study', isChecked: true },
    { label: 'UID', name: 'uid', isChecked: false },
    { label: 'Institution Name', name: 'institution_name', isChecked: true },
    { label: 'Location', name: 'location', isChecked: false },
    { label: 'Technologist', name: 'technologist', isChecked: false },
    { label: 'Interpreting Company', name: 'interpreting_company', isChecked: false },
    { label: 'Room', name: 'room', isChecked: false },
  ];

  const trueValues = Object.entries(checkedAttributesItems)
    .filter(([key, val]) => val === true)
    .map(([key]) => key);

  const columnCount = 2;

  const rowCount = Math.ceil(trueValues.length / columnCount);

  function capitalizeFirstLetter(string) {
    return string.replace(/\b\w/g, match => match.toUpperCase());
  }

  const exactLabelMap = {
    'patient_dob': 'DOB',
    'patient_id': 'Patient ID',
    'uid': 'UID',
    'ref_physician': 'Ref. Physician',
    'study_tat': 'Study TAT',
    'accession_number': 'Accession No.',
    'patient_gender': 'SEX',
    'patient_modality': 'Modality',
    'patient_age': 'Age'
  };

  function formatLabel(key) {
    return exactLabelMap[key] || capitalizeFirstLetter(key.replace(/_/g, ' '));
  }

  let tableRows = '';
  for (let i = 0; i < rowCount; i++) {
    const startIdx = i * columnCount;
    const endIdx = startIdx + columnCount;
    const rowData = trueValues.slice(startIdx, endIdx);

    const rowCells = rowData
      .map(
        key =>
          `<td style="width: 17.7931%;"><strong>${formatLabel(key)}:</strong></td><td style="width: 33.5161%;"> {{${key}}}</td>`
      )
      .join('');
    tableRows += `<tr>${rowCells}</tr>`;
  }

  const templates = `
    <table style="border-collapse: collapse; width: 100%;" border="1">
          <tbody>
            ${tableRows}
          </tbody>
        </table>
        <p style="text-align: center;text-transform: capitalize;"><strong style="text-transform: capitalize;"><u>${templateName}</u></strong></p>
        <p><b>CLINICAL HISTORY: </b> {{clinical_history}}</p>
        `;

  const handleSubmit = async () => {
    const templatesDetail = {
      name: templateName,
      templates: templates + editorData,
      created_by: user.profile.sub,
      updated_by: user.profile.sub,
      templateAttributes: [checkedAttributesItems],
      customTemplate: editorData,
      isCustomizedTemplate: selectCustomTemplate,
      isCapture: imageCapture,
      modality: modality,
      bodyparts: bodyparts,
      templategroup: templategroup,
    };
    if (dataTemplate) {
      const response = await updateDefaultTemplates(dataTemplate.id, templatesDetail);
      if (response.status === 200) {
        fetchDefaultTemplates(currentPage, resultsPerPage, searchTerm)
          .then(data => setDefaultTemplates(data))
          .catch(error => console.error('Error fetching default templates:', error));
        fetchDefaultReportTemplates()
          .then(data => setDefaultReportTemplates(data))
          .catch(error => console.error('Error fetching default report templates:', error));
        handleClick(false);
        setIsOpenCustomTemplate(false);
        setImageCapture(false)
        show({
          title: 'Success!',
          message: 'Successfully Report Template Update',
          type: SnackbarTypes.SUCCESS,
          position: 'topRight',
        });
      } else {
        const errorMessage = await response.json();
        setError(errorMessage.errors[0].msg);
      }
    } else {
      const response = await createDefaultTemplates(templatesDetail);
      if (response.status === 201) {
        fetchDefaultTemplates(currentPage, resultsPerPage, searchTerm)
          .then(data => setDefaultTemplates(data))
          .catch(error => console.error('Error fetching default templates:', error));
        fetchDefaultReportTemplates()
          .then(data => setDefaultReportTemplates(data))
          .catch(error => console.error('Error fetching default report templates:', error));
        handleClick(false);
        setIsOpenCustomTemplate(false);
        show({
          title: 'Success!',
          message: 'Successfully Report Template Created',
          type: SnackbarTypes.SUCCESS,
          position: 'topRight',
        });
      } else {
        const errorMessage = await response.json();
        setError(errorMessage.errors[0].msg);
      }
    }
  };

  const handleDeleteTemplate = async id => {
    Swal.fire({
      text: 'Are you sure to delete this report template!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then(async result => {
      if (result.isConfirmed) {
        await deleteDefaultTemplates(id, user.access_token)
          .then(async res => {
            Swal.fire({
              title: 'Deleted',
              text: 'Report Template has been Delete Successfully',
              icon: 'success',
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
            });

            if (res.status === 200) {
              await fetchDefaultTemplates(user.access_token)
                .then(data => setDefaultTemplates(data))
                .catch(error => console.error('Error fetching users:', error));

              await fetchDefaultReportTemplates()
                .then(data => setDefaultReportTemplates(data))
                .catch(error => console.error('Error fetching default report templates:', error));
            }
          })
          .catch(error => {
            console.error('Error deleting user:', error);
            Swal.fire({
              title: 'Error!',
              text: 'Failed to delete report template. Please try again later.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          text: 'Report Template Delete Cancel.',
          icon: 'info',
          confirmButtonText: 'OK',
        });
      }
    });
  };

  const handleClick = open => {
    setIsOpen(open);
    setDataTemplate(null);
    setTemplateName('');
    setEditorData('');
    setError('');
  };

  return (
    <section className="relative w-full text-white">
      <Header
        servicesManager={servicesManager}
        hotkeysManager={hotkeysManager}
        sortedStudies={studies}
        isReturnEnabled={!!appConfig.showStudyList}
        onClickReturnButton={onClickReturnButton}
        WhiteLabeling={appConfig.whiteLabeling}
        loggedInUserDetails={user}
        loggedInUserTokenDetails={token}
        // headerOpen={true}
      />
      {/* <div className="absolute top-16 left-60 p-3 w-10/12"> */}
      <div className='max-sm:px-3'>
        {isOpen && !isOpenCustomTemplate && (
          <CreateReportTemplate
            handleClick={handleClick}
            data={data}
            handleSubmit={handleSubmit}
            error={error}
            dataTemplate={dataTemplate}
            checkedAttributesItems={checkedAttributesItems}
            setCheckedAttributesItems={setCheckedAttributesItems}
            templateName={templateName}
            setTemplateName={setTemplateName}
            templates={templates}
            setEditorData={setEditorData}
            selectCustomTemplate={selectCustomTemplate}
            setSelectCustomTemplate={setSelectCustomTemplate}
            imageCapture={imageCapture}
            setImageCapture={setImageCapture}
            modality={modality}
            setModality={setModality}
            bodyparts={bodyparts}
            setBodyparts={setBodyparts}
            templategroup={templategroup}
            setTemplategroup={setTemplategroup}
          />
        )}
        {!isOpen && (
          <ReportTemplateList
            handleClick={handleClick}
            handleDeleteTemplate={handleDeleteTemplate}
            setDataTemplate={setDataTemplate}
            setImageCapture={setImageCapture}
            servicesManager={servicesManager}
          />
        )}
        {isOpenCustomTemplate && (
          <CustomTemplates
            templates={templates}
            setEditorData={setEditorData}
            dataTemplate={dataTemplate?.customTemplate}
          />
        )}
      </div>
    </section>
  );
};

export default ReportTemplate;
